import {
  Table,
  Button,
  // Space,
  // Select,
  Typography,
  Modal,
  Checkbox,
} from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { payOrder } from "../../../Redux/Actions/TableSettingsActions";

const CustomerPayModal = ({ details, customerList }) => {
  // console.log(details);
  const [modalVisible, setModalVisible] = useState(false);
  const [totalBill, setTotalBill] = useState(details.amount);
  const [
    isCheck,
    //setCheck
  ] = useState(true);
  const [orders, setOrders] = useState([details]);
  const [payArray, setPayArray] = useState([]);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    // customerList.map((order, i) => {
    //   if (details._id === order._id) {
    //     setPayArray([...payArray, i]);
    //   }
    // });
  }, []);
  // console.log(payArray);

  const { Title, Text } = Typography;
  const modalChange = (modalVisible) => {
    customerList.map((order, i) => {
      if (details._id === order._id) {
        setPayArray([...payArray, i]);
      }
    });
    setModalVisible(modalVisible);
  };
  const columns = [
    {
      title: "Customer",
      dataIndex: "name",
    },
    {
      title: "Bill Amount",
      dataIndex: "bill",
    },
    {
      title: "Pay for",
      dataIndex: "pay",
    },
  ];
  function onPayChange(e) {
    // console.log(e.target.value); //id
    // console.log(e.target.checked); //boolean
    if (e.target.checked) {
      var amount = customerList.filter((customer, i) => {
        if (customer._id === e.target.value) {
          setOrders([...orders, customer]);
          setPayArray([...payArray, i]);
          console.log(payArray);
          return customer.amount;
        }
      });
      setTotalBill(totalBill + amount[0].amount);
    } else {
      var amount = customerList.filter((customer, i) => {
        if (customer._id === e.target.value) {
          const delOrder = orders.filter((o, oi) => {
            return o._id !== customer._id;
          });
          setOrders(delOrder);
          const delIndex = payArray.filter((index) => {
            return i !== index;
          });
          setPayArray(delIndex);
          return customer.amount;
        }
      });
      setTotalBill(totalBill - amount[0].amount);
    }
  }

  const handlePayOrder = () => {
    if (type !== "") {
      const payload = {
        payer: {
          _id: details._id,
          name: details.userName ? details.userName : details.user.name,
        },
        orders: customerList,
        payArray,
        type,
      };
      dispatch(payOrder(payload, details.table));
      console.log(payload);
      setModalVisible(false);
    } else {
      swal("Please Select Payment Method", "", "info");
    }
  };
  return (
    <div>
      <Button type="primary" onClick={() => modalChange(true)}>
        Pay
      </Button>
      <Modal
        centered
        visible={modalVisible}
        onOk={handlePayOrder}
        onCancel={() => {
          setModalVisible(false);
          setPayArray([]);
          setType("");
        }}
        width={800}
      >
        <div>
          <Title level={4}>
            Payer Name:{" "}
            <Text type="danger">
              {details.user ? details.user.name : details.userName}
            </Text>
          </Title>
          <div className="pay-modal">
            <div>
              <Title level={5}>
                Your Bill:<Text type="danger">{details.amount}</Text>
              </Title>
            </div>
            <div>
              <Title level={5}>
                Total Bill:<Text type="danger">{totalBill}</Text>
              </Title>
            </div>
            <div>
              <Button
                onClick={() => {
                  setType("card");
                }}
              >
                Pay Card
              </Button>
              <Button
                onClick={() => {
                  setType("cash");
                }}
              >
                Pay Cash
              </Button>
            </div>
          </div>
          <div>
            <Title level={4}>Table Details</Title>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={customerList.map((customer) => {
            return {
              key: customer._id,
              name: customer.userName ? customer.userName : customer.user.name,
              bill: customer.amount,
              pay: (
                <Checkbox
                  disabled={customer._id === details._id ? true : false}
                  defaultChecked={
                    customer._id === details._id ? true : !isCheck
                  }
                  // checked={!isCheck}
                  value={customer._id}
                  onChange={onPayChange}
                ></Checkbox>
              ),
            };
          })}
        />
      </Modal>
    </div>
  );
};

export default CustomerPayModal;
