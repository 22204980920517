//import { CodeSharp } from "@material-ui/icons";
import axios from "axios";
//import swal from "sweetalert";
import { url } from "../../BaseUrl";
import * as CustomerTypes from "../Types/CustomerTypes";
const adminId = JSON.parse(localStorage.getItem("userId"));

export const getCustomers = (/*payload*/) => async (dispatch) => {
  console.log(adminId);
  try {
    const res = await axios.post(`${url}/admin/my-customers`, {
      adminId,
    });
    if (res) {
      dispatch({
        type: CustomerTypes.GET_CUSTOMERS,
        payload: res.data.users,
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};

export const getCustomerDetails = (customerId) => async (dispatch) => {
  console.log(customerId);
  try {
    const res = await axios.post(`${url}/admin/get-customer-details`, {
      adminId,
      customerId,
    });
    // console.log(res.data);
    if (res) {
      console.log(res.data);
      dispatch({
        type: CustomerTypes.GET_CUSTOMER_DETAILS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};

export const searchCustomer = (payload) => async (dispatch) => {
  if (payload === "") {
    dispatch(getCustomers());
  } else {
    dispatch({
      type: CustomerTypes.SEARCH_CUSTOMER,
      payload: payload,
    });
  }
};

export const filterCustomer = () => async (dispatch) => {
  dispatch({
    type: CustomerTypes.FILTER_CUSTOMER,
  });
};
export const defaultCustomer = () => async (dispatch) => {
  dispatch({
    type: CustomerTypes.DEFAULT_CUSTOMER,
  });
};
