import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import ScrollToTop from "react-router-scroll-top";

// dashboard pages
//import Dashboard from "./pages/Home";
import AccountSettings from "./pages/AccountSettings";
import TableSettings from "./pages/TableSettings";
import Menu from "./pages/Menu";
import Accounting from "./pages/Accounting";
import Statistics from "./pages/Statistics";
import Customers from "./pages/Customers";
import Workers from "./pages/Workers";
import Promotions from "./pages/Promotions";
//import Tables from "./pages/Tables";
import LiveOrders from "./pages/LiveOrders";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import CustomerDetails from "./pages/CustomerDetails";
import VerifiyLogin from "./pages/VerifiyLogin";
import SubMenuPrivateRoute from "./SubMenuPrivateRoute";
import PrivateRoute from "./PrivateRoute";
import VerifyCode from "./pages/VeirfyCode";
const Routes = () => {
  return (
    <Router>
      {/* <ScrollToTop> */}
      <Switch>
        <PrivateRoute path="/" exact component={VerifiyLogin} />
        <SubMenuPrivateRoute
          path="/account-setting"
          exact
          component={AccountSettings}
        />
        <SubMenuPrivateRoute
          path="/table-setting"
          exact
          component={TableSettings}
          // render={() => {
          //   return <TableSettings isTableOnly={false} />;
          // }}
        />
        <SubMenuPrivateRoute path="/menu" exact component={Menu} />
        <SubMenuPrivateRoute path="/accounting" exact component={Accounting} />
        <SubMenuPrivateRoute path="/statistics" exact component={Statistics} />
        <SubMenuPrivateRoute path="/customers" exact component={Customers} />
        <SubMenuPrivateRoute path="/workers" exact component={Workers} />
        <SubMenuPrivateRoute path="/promotions" exact component={Promotions} />
        <PrivateRoute
          path="/tables"
          exact
          // render={() => {
          //   return <TableSettings isTableOnly={true} />;
          // }}
          component={TableSettings}
        />
        <PrivateRoute path="/liveorders" exact component={LiveOrders} />
        <PrivateRoute
          path="/liveorder-table/:id"
          exact
          component={TableSettings}
        />
        <Route path="/login" exact component={Login} />
        <Route path="/forgot-password" exact component={ForgetPassword} />
        <Route path="/verify-code" exact component={VerifyCode} />
        <SubMenuPrivateRoute
          path="/customer-details/:id"
          exact
          component={CustomerDetails}
        />

        <Route path="/verify-login" exact component={VerifiyLogin} />
      </Switch>
      {/* </ScrollToTop> */}
    </Router>
  );
};

export default Routes;
