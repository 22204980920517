import * as WorkersTypes from "../Types/WorkersTypes";

const initialState = {
  workersCategories: [],
};

const workersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case WorkersTypes.GET_WORKERS_CATEGORIES:
      return {
        ...state,
        workersCategories: payload,
      };

    default:
      return state;
  }
};

export default workersReducer;
