//import { Typography } from "antd";

import CodeVerification from "../components/veirifcation/CodeVerification";
import NewPassword from "../components/veirifcation/NewPassword";

const VerifyCode = () => {
  //const { Title } = Typography;

  return (
    <div className="login">
      <div className="login-container">
        <CodeVerification />
        <NewPassword />
      </div>
    </div>
  );
};

export default VerifyCode;
