import axios from "axios";
import { url, headers } from "../../BaseUrl";
import * as AccountSettingsTypes from "../Types/AccountSettingsTypes";
import swal from "sweetalert";
const adminId = JSON.parse(localStorage.getItem("userId"));

export const getUserInfo = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${url}/admin/get-admin-id/${id ? id : adminId}`
    );
    if (res) {
      dispatch({
        type: AccountSettingsTypes.GET_ADMIN,
        payload: res.data.admin,
      });
      // return res.data.admin.printerModule;
      return {
        printerModule: res.data.admin.printerModule,
        waiterCall: res.data.admin.waiterCall,
        adminOrderStatus: res.data.admin.orderStatus,
        admin: res.data.admin,
      };
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCountries = () => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/city/get-countries`);
    if (res) {
      dispatch({
        type: AccountSettingsTypes.GET_COUNTRIES,
        payload: res.data.countries,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
export const getCities = (country) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/city/get-cities`, {
      country,
    });
    if (res) {
      dispatch({
        type: AccountSettingsTypes.GET_CITIES,
        payload: res.data.cities,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
export const updatePassword =
  (confirmPassword, newPassword, oldPassword) => async (dispatch) => {
    console.log(oldPassword, newPassword, confirmPassword);
    try {
       await axios.post(`${url}/admin/change-password`, {
        password: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
        id: adminId,
      });
      dispatch(getUserInfo());
      swal({
        title: "Password Updated",
        icon: "success",
      });
    } catch (err) {
      console.log(err);
      swal({
        text: err?.response?.data?.message || "Server Error",
        icon: "error",
      });
    }
  };
export const uploadLogo = (formData) => async (dispatch) => {
 // const adminId = JSON.parse(localStorage.getItem("userId"));

  try {
     await axios
      .post(
        `https://us-central1-qrun-server.cloudfunctions.net/uploadBanner`,
        formData,
        {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
        }
      )
      .then((res) => {
        axios({
          url: `${url}/admin/update-logo`,
          method: "POST",
          data: {
            id: adminId,
            url: res.data.imageUrl,
          },
          headers: headers,
        })
          .then((res) => {
            swal({
              icon: "success",
              title: "Success",
              text: "Logo Uploaded.",
            });
          })
          .catch((err) => {
            swal({
              icon: "error",
              title: "Error",
              text: err?.response?.data?.message,
            });
          });
      });
  } catch (err) {
    console.log(err);
  }
};
export const updateVendorInfo = (payload) => async (dispatch) => {
  try {
    await axios.post(`${url}/admin/update-details`, payload, {
      headers: headers,
    });
    swal({
      icon: "success",
      title: "success",
      text: "Information Updated Successfully!",
    });
    dispatch(getUserInfo());
  } catch (err) {
    swal({
      icon: "error",
      title: "Error",
      text: "Error updating details",
    });
  }
};
export const updatePrinter = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    await axios.post(
      `${url}/admin/update-printer-module`,
      payload,
      {
        headers: headers,
      }
    );

    swal({
      icon: "success",
      title: "success",
      text: "Printer Module Updated",
    }).then((res) => {
      window.location.reload();
    });
  } catch (err) {
    swal({
      icon: "error",
      title: "Error",
      text: "Error updating printer Module",
    });
    console.log(err);
  }
};

export const updateUserRegistrationStatus = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/admin/set-admin-order-status`,
      payload,
      {
        headers: headers,
      }
    );
    if (res) {
      console.log(res.data);
      swal({
        icon: "success",
        title: res.data.message,
        text: "User Registration Status Updated Successfully!",
      });
    }
  } catch (err) {
    swal({
      icon: "error",
      title: "Error",
      text: "Error updating details",
    });
  }
};
