import * as StatisticsTypes from "../Types/StatisticsTypes";

const initialState = {
  myProducts: null,
  productById: null,
  workers: null,
};
var productCopy;
var workerCopy;
const statisticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case StatisticsTypes.GET_MY_PRODUCTS:
      productCopy = payload;
      return {
        ...state,
        myProducts: payload,
      };
    case StatisticsTypes.SEARCH_MY_PRODUCTS:
      const searchProduct = payload.toLowerCase();
      const newProduct = productCopy.filter((obj) => {
        return obj.name.toLowerCase().includes(searchProduct);
      });
      return {
        ...state,
        myProducts: newProduct,
      };

    case StatisticsTypes.GET_PRODUCT_ID:
      return {
        ...state,
        productById: payload,
      };
    case StatisticsTypes.GET_WORKERS:
      workerCopy = payload;
      return {
        ...state,
        workers: payload,
      };
    case StatisticsTypes.SEARCH_WORKER:
      const searchWorker = payload.toLowerCase();
      const newWorker = workerCopy.filter((obj) => {
        return obj.name.toLowerCase().includes(searchWorker);
      });
      return {
        ...state,
        workers: newWorker,
      };

    default:
      return state;
  }
};

export default statisticsReducer;
