import { Typography, Table } from "antd";
import moment from "moment";

const ProfileComments = ({ comments }) => {
  const commentsColumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "comments",
      dataIndex: "comment",
    },
  ];

  const { Title } = Typography;
  return (
    <div>
      <Title level={2}>Profile Comments</Title>
      <Table
        columns={commentsColumns}
        // loading={comments && comments.comments.length > 0 ? false : true}
        dataSource={
          comments &&
          comments.length > 0 &&
          comments.map((comment) => ({
            key: comment._id,
            date: moment(comment.time).format("Do MMMM YYYY"),
            comment: comment.comment,
          }))
        }
        scroll={{ x: 600 }}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
        }}
      />
    </div>
  );
};

export default ProfileComments;
