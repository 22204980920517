//import DashboardLayoutDesktop from "./LayoutDesktop";
import CollapseLayout from "./CollapseLayout";
import DashboardLayoutMobile from "./DashboardLayoutMobile";

const DashboardLayout = ({ active, children }) => {
  return (
    <>
      <CollapseLayout active={active}>{children}</CollapseLayout>
      <DashboardLayoutMobile active={active}>{children}</DashboardLayoutMobile>
    </>
  );
};

export default DashboardLayout;
