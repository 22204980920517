import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const SubMenuPrivateRoute = ({ component: Component, ...rest }) => {
  //   var verified = JSON.parse(localStorage.getItem("verified"));
  const verified = useSelector(
    (state) => state.AccountSettingsReducer.verified
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        verified ? <Component {...props} /> : <Redirect to="/verify-login" />
      }
    />
  );
};

export default SubMenuPrivateRoute;
