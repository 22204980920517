import {
  Typography,
  Tabs,
  Table,
  Button,
  Row,
  Col,
  Input,
  Select,
  Card,
  Rate,
} from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutDesktop from "../layout/DashboardLayout";
//import { UserOutlined } from "@ant-design/icons";

import {
  getMyProducts,
  getWorkers,
  searchProducts,
  searchWorkers,
  showProductStats,
} from "../Redux/Actions/StatisticsActions";

const Statistics = () => {
  const myProducts = useSelector((state) => state.StatisticsReducer.myProducts);
  // const productStats = useSelector(
  //   (state) => state.StatisticsReducer.productById
  // );

  const [stats, setStats] = useState();
  const workers = useSelector((state) => state.StatisticsReducer.workers);
  // console.log(workers);

  // console.log(productStats);
  const productColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: {
        compare: (a, b) => a.category - b.category,
        multiple: 3,
      },
    },
    {
      title: "Ratings",
      dataIndex: "ratings",
      sorter: {
        compare: (a, b) => a.ratings - b.ratings,
        multiple: 2,
      },
    },
    {
      title: "Comments",
      dataIndex: "comments",
      sorter: {
        compare: (a, b) => a.comments - b.comments,
        multiple: 1,
      },
    },
  ];
  let averageRating = 0;

  {
    stats &&
      stats.rating.map((rate) => {
        averageRating += rate.rate;
        // averageRating += 4;
      });
  }

  const workerColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: {
        compare: (a, b) => a.category - b.category,
        multiple: 3,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 2,
      },
    },
  ];
  const { Option } = Select;
  const { TabPane } = Tabs;
  const { Title, Text } = Typography;
  const { Meta } = Card;

  const [comments, showComments] = useState(false);
  const dispatch = useDispatch();
  const onProductSearch = (e) => {
    dispatch(searchProducts(e.target.value));
  };

  const toggleComments = () => {
    showComments(!comments);
  };
  console.log(comments);
  const onWorkerSearch = (e) => {
    dispatch(searchWorkers(e.target.value));
  };
  function onProductTableChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  function onWorkerTableChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  const handleProductChange = async (productId) => {
    const productStats = await dispatch(showProductStats(productId));
    setStats(productStats);
    console.log(stats);
  };

  useEffect(() => {
    dispatch(getMyProducts());
    dispatch(getWorkers());
    // setStats(productStats && productStats);
  }, []);

  console.log(myProducts);

  return (
    <div>
      <LayoutDesktop active="statistics">
        <Title level={2}>Statistics</Title>
        <div className="statistics">
          <Tabs
            defaultActiveKey="1"
            size="large"
            className="stats-tabs"
            tabBarGutter={50}
          >
            <TabPane tab="PRODUCTS" key="1">
              <Row>
                <Col xs={24} md={24} lg={13}>
                  <div className="stats-product-table">
                    <Title level={4}>Products</Title>
                    <Input
                      placeholder="search by name"
                      className="search-bar"
                      allowClear
                      onChange={(e) => onProductSearch(e)}
                    />
                    <Table
                      columns={productColumns}
                      dataSource={
                        myProducts &&
                        myProducts.map((product, i) => {
                          return {
                            key: product._id,
                            name: product?.name,
                            category: product.category?.name,
                            ratings: product.rating.length,
                            comments: product.comments.length,
                          };
                        })
                      }
                      onChange={onProductTableChange}
                      scroll={{ x: 600 }}
                      loading={myProducts === null ? true : false}
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30"],
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24} md={24} lg={11}>
                  <div className="product-stats">
                    <Row>
                      <Col xs={24}>
                        <div style={{ display: "flex" }}>
                          <Title level={5}>Choose Product:</Title>
                          <Select
                            onChange={handleProductChange}
                            style={{ width: "30%", marginLeft: "30px" }}
                          >
                            {" "}
                            {myProducts &&
                              myProducts.length &&
                              myProducts.map((product, i) => {
                                return (
                                  <Option value={product._id} key={product._id}>
                                    {product.name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </Col>
                      <Col xs={24}>
                        <Title level={5}>
                          Comments:
                          {stats && stats.comments.length}
                        </Title>
                      </Col>
                      <Col xs={24}>
                        <Title level={5}>
                          Stars:{" "}
                          {/* {productStats && productStats.length > 0 ? (
                            productStats.rating.map((stars) => {
                              return (
                                <Rate disabled defaultValue={stars.rate} />
                              );
                            })
                          ) : (
                            <Rate disabled defaultValue={2} />
                          )} */}
                          <Rate disabled value={averageRating} />
                        </Title>
                      </Col>
                      <Col xs={24}>
                        <Title level={5}>
                          Total Times Rated: {stats ? stats.rating.length : ""}
                        </Title>
                      </Col>
                    </Row>
                    {stats && stats.comments && stats.comments.length > 0 ? (
                      <>
                        <Button
                          type="primary"
                          className="comments-btn"
                          onClick={toggleComments}
                        >
                          {!comments ? "view comments" : "hide comments"}
                        </Button>
                        <br />
                        <br />
                        <Title level={4}>Comments:</Title>
                      </>
                    ) : null}
                    {comments
                      ? stats &&
                        stats.comments.length > 0 &&
                        stats.comments.map((comment, i) => {
                          let date = new Date(comment.time);
                          date = date.toString();
                          date = date.split("GMT");
                          date = date[0];

                          return (
                            <Card
                              key={i}
                              style={{ width: "100%", marginTop: 16 }}
                            >
                              <Meta
                                // avatar={
                                //   <Avatar
                                //     src="https://firebasestorage.googleapis.com/v0/b/qrun-server.appspot.com/o/1608784928613Decotechs%20Hiring%20(1).png?alt=media&token=43c3d437-c802-4118-9b9f-270954842ef5"
                                //     size={64}
                                //   />
                                // }
                                title={comment.user.name}
                                description={date}
                              />
                              <Text>{comment.comment}</Text>
                            </Card>
                          );
                        })
                      : null}
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="WORKER" key="2">
              <div>
                <Input
                  placeholder="search by name"
                  className="search-bar"
                  allowClear
                  onChange={(e) => onWorkerSearch(e)}
                />
                <Table
                  columns={workerColumns}
                  dataSource={
                    workers &&
                    workers.length > 0 &&
                    workers.map((worker) => {
                      return {
                        key: worker._id,
                        name: worker.name,
                        category: worker.type.name,
                        status: worker.status ? "active" : "inactive",
                      };
                    })
                  }
                  onChange={onWorkerTableChange}
                  scroll={{ x: 600 }}
                  pagination={{
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </div>
            </TabPane>
            <TabPane tab="RESTAURANT OVERALL" key="3">
              <div className="overall-rating">
                <Row>
                  <Col xs={24} md={8}>
                    <Title level={5}>Total Number Of Comments: 0</Title>
                  </Col>
                  <Col xs={24} md={8}>
                    <Title level={5}>Total No Of Ratings: 0</Title>
                  </Col>
                  <Col xs={24} md={8}>
                    <Title level={5}>Average Rating: 0</Title>
                  </Col>
                </Row>
              </div>
              <div className="ratings-comments">
                <Row>
                  <Col xs={12}>
                    <Title level={4}>Ratings</Title>
                  </Col>
                  <Col xs={12}>
                    <Title level={4}>Comments</Title>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </LayoutDesktop>
    </div>
  );
};

export default Statistics;
