import {
  Col,
  Input,
  Row,
  Button,
  Typography,
  Select,
  Tag,
  Tabs,
  Table,
  Image,
  Upload,
} from "antd";
import LayoutDesktop from "../layout/DashboardLayout";
import { DropzoneArea } from "material-ui-dropzone";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import L from "../language";
import {
  addCategory,
  addExtraProduct,
  addProduct,
  deleteExtraProduct,
  deleteProduct,
  getAllCategories,
  getAllExtraProducts,
  getAllProducts,
  searchProduct,
  updateCategory,
  updateCategoryPosition,
  updateExtraProduct,
  updateExtraProductPosition,
  updateProduct,
  updateProductPosition,
  uploadSingleProductImage,
} from "../Redux/Actions/MenuActions";
import swal from "sweetalert";
import { useEffect } from "react";
import MenuModal from "../components/Modals/Menu/MenuModal";
//import ExtraProductModal from "../components/Modals/Menu/ExtraProductModal";

const { TabPane } = Tabs;

const Menu = () => {
  //const [isModalVisible, setModalVisible] = useState(false);
  const products = useSelector((state) => state.MenuReducer.products);
  const extraProducts = useSelector((state) => state.MenuReducer.extraProducts);

  const categories = useSelector((state) => state.MenuReducer.categories);
  // const modalChange = (modalVisible) => {
  //   setModalVisible(modalVisible);
  // };

  console.log(extraProducts);

  const { Option } = Select;
  const { Title } = Typography;
  const { TextArea } = Input;
  const adminId = JSON.parse(localStorage.getItem("userId"));
  const dispatch = useDispatch();
  //const [file, setFile] = useState([]);
  //const [searchValue, setSearchValue] = useState("");

  //const [productCheck, setProductCheck] = useState(true);
  const [checkBoxValue, setCheckBoxValue] = useState("add");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [ingredientsValue, setIngredientsValue] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [categoryUploadImage, setCategoryUploadImage] = useState(null);
  const [categoryImage, setCategoryImage] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [productImage, setProductImage] = useState([]);
  const [calories, setCalories] = useState("");
  const [waitTime, setWaitTime] = useState("");
  const [price, setPrice] = useState("");
  const [cost, setCost] = useState("");
  const [allergies, setAllergies] = useState([]);
  const [extraProductSelect, setExtraProductSelect] = useState([]); //only names of extra products to show on select
  const [extraProductObj, setExtraProductObj] = useState([]); //contains object of a ext product
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [edit, setEdit] = useState(false);
  const [singleProduct, setSingleProduct] = useState({});
  const [imageIndex, setImageIndex] = useState([]);
  const [imagesReceived, setImagesReceived] = useState([]);
  const [stock, setStock] = useState();
  const [orderPosition, setOrderPosition] = useState(null);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  //const [tableLoading, setTableLoading] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState(false);
  const [preview, setPreview] = useState(true);
  const [categoryId, setCategoryId] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [categoryUpdateLoading, setCategoryUpdateLoading] = useState(false);
  const [defaultList, setDeafultList] = useState([]);
  const [productDefaultList, setProductDeafultList] = useState([]);
  // const [] = useState(false)

  const categoryColumns = [
    {
      title: "Set Order",
      dataIndex: "order",
    },
    {
      title: "Image",
      dataIndex: "image",
    },

    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
    },
  ];
  const columns =
    checkBoxValue === "add"
      ? [
          {
            title: "Position",
            dataIndex: "position",
            width: 180,
          },
          {
            title: "Name",
            dataIndex: "name",
            width: 180,
          },
          {
            title: "Calories",
            dataIndex: "calories",
            sorter: {
              compare: (a, b) => a.calories - b.calories,
              multiple: 3,
            },
          },
          {
            title: "Price",
            dataIndex: "price",
            sorter: {
              compare: (a, b) => a.price - b.price,
              multiple: 2,
            },
          },
          {
            title: "Wait Time",
            dataIndex: "waitTime",
            sorter: {
              compare: (a, b) => a.waitTime - b.waitTime,
              multiple: 1,
            },
          },
          // {
          //   title: "Stock",
          //   dataIndex: "stock",
          //   sorter: {
          //     compare: (a, b) => a.stock - b.stock,
          //     multiple: 1,
          //   },
          // },
          {
            title: "Action",
            dataIndex: "action",
          },
        ]
      : [
          {
            title: "Position",
            dataIndex: "position",
            width: 180,
          },
          {
            title: "Name",
            dataIndex: "name",
            width: 180,
          },
          // {
          //   title: "Cost",
          //   dataIndex: "calories",
          //   sorter: {
          //     compare: (a, b) => a.calories - b.calories,
          //     multiple: 3,
          //   },
          // },
          {
            title: "Price",
            dataIndex: "price",
            sorter: {
              compare: (a, b) => a.price - b.price,
              multiple: 2,
            },
          },
          // {
          //   title: "Stock",
          //   dataIndex: "stock",
          //   sorter: {
          //     compare: (a, b) => a.stock - b.stock,
          //     multiple: 1,
          //   },
          // },
          {
            title: "Action",
            dataIndex: "action",
          },
        ];

  //   Dropzone
  const handleDropzoneChange = (files) => {
    setPreview(true);
    setCategoryUploadImage(files[0]);
  };
  //   filter table
  const searchTable = (e) => {
    if (checkBoxValue === "add")
      dispatch(searchProduct(e.target.value, "product"));
    else dispatch(searchProduct(e.target.value, "extraproduct"));
  };
  //   add product add extra product
  // function onChange(e) {
  //   setProductCheck(!productCheck);
  //   setCheckBoxValue(e.target.value);
  //   setProductName("");
  //   setProductImage([]);
  //   setCost("");
  //   setPrice("");
  //   setWaitTime("");
  //   setDescription("");
  //   setCalories("");
  //   setSelectedCategory("");
  //   // setAllergies([]);
  //   setIngredients([]);
  //   setExtraProductSelect([]);
  //   setStock();
  // }
  // add extra product input

  const delIngredients = (i) => {
    const newIngredients = ingredients.filter((val, index) => {
      return ingredients[i] !== ingredients[index];
    });
    setIngredients(newIngredients);
  };

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllExtraProducts());
    dispatch(getAllCategories());
  }, []);
  function onTableChange(pagination, filters, sorter, extra) {
    //
  }
  //
  const handleAddCategory = async () => {
    setCategoryLoading(true);
    if (categoryUploadImage === null || categoryName === "") {
      swal({
        icon: "error",
        title: "Incomplete Information",
        text: "Name and Image of Category must be filled",
      });
      setCategoryLoading(false);
    } else if (categoryUploadImage && categoryName !== "") {
      const existedCategories = [];

      categories &&
        categories.length > 0 &&
        categories.map((category) => {
          if (categoryName.toLowerCase() === category.name.toLowerCase()) {
            existedCategories.push(category.name.toLowerCase());
          }
        });
      if (existedCategories[0] === categoryName.toLowerCase()) {
        swal({
          icon: "warning",
          text: "Category with same name exists",
          // text: "Name and Image of Category must be filled",
        });
        setCategoryLoading(false);
      } else {
        const formData = new FormData();
        formData.append("image", categoryUploadImage);
        await dispatch(addCategory(formData, categoryName));
        setCategoryName("");
        setDeafultList([]);
        setCategoryUploadImage(null);
        setCategoryLoading(false);
        clearFields();
      }
    }
  };
  const handleCategoryChange = (value, key) => {
    setSelectedCategoryName(key.children);
    setSelectedCategory(value);
  };

  const handleProductDropzone = (files) => {
    setPreview(true);
    setProductImage(files);
  };

  const handleAllergies = (allergy) => {
    setAllergies(allergy);
    //
  };
  const handleExtraProduct = (p) => {
    extraProducts &&
      extraProducts.filter((product, ind) => {
        if (product.name === p) {
          setExtraProductObj([...extraProductObj, product]);
        }
      });
    setExtraProductSelect([...extraProductSelect, p]);
  };
  const handleExtraProductDeselect = (p) => {
    const delProduct = extraProductObj.filter((product, i) => {
      return product.name !== p;
    });

    const delProductName = extraProductSelect.filter((product, i) => {
      return product !== p;
    });

    setExtraProductObj(delProduct);
    setExtraProductSelect(delProductName);
  };
  const createProduct = async () => {
    setProductLoading(true);
    if (
      productImage.length > 0 &&
      productName !== "" &&
      selectedCategory !== "" &&
      // cost !== "" &&
      // calories !== "" &&
      // waitTime !== "" &&
      price !== "" &&
      description !== ""
    ) {
      let existedProductNames = [];

      products &&
        products.length > 0 &&
        products.map((product) => {
          if (productName.toLowerCase() === product.name.toLowerCase()) {
            existedProductNames.push(product.name.toLowerCase());
          }
        });

      if (existedProductNames[0] === productName.toLowerCase()) {
        swal("", "Product with same name already exists", "warning");
        setProductLoading(false);
      } else {
        const logosArr = [];
        const logosLength = productImage.length;
        productImage.map(async (images) => {
          const formData = new FormData();
          formData.append("image", images.originFileObj);
          const link = await dispatch(uploadSingleProductImage(formData));
          logosArr.push(link);
          if (logosArr.length === logosLength) {
            let logo = logosArr[0];
            logosArr.splice(0, 1);
            const payload = {
              name: productName,
              description,
              price,
              adminId,
              category: selectedCategory,
              waitTime,
              cost,
              allergic: allergies,
              calories,
              logo: logo,
              logos: logosArr,
              totalStock: Number(stock) === 0 ? null : Number(stock),
              // photos: [logo, ...logosArr],
              extraProducts: extraProductObj,
              ingrediants: ingredients,
            };
            // console.log(payload);
            await dispatch(addProduct(payload));
            setProductLoading(false);
          }
        });
        clearFields();
      }
    } else {
      swal({
        icon: "error",
        title: "Incomplete Information",
      });
      setProductLoading(false);
    }
  };

  const createExtraProduct = async () => {
    setProductLoading(true);

    if (
      productName !== "" &&
      price !== "" &&
      (productImage !== undefined || productImage !== "undefined")
    ) {
      let existedExtraProducts = [];

      extraProducts &&
        extraProducts.length > 0 &&
        extraProducts.map((product) => {
          if (productName.toLowerCase() === product.name.toLowerCase()) {
            existedExtraProducts.push(product.name.toLowerCase());
          }
        });

      if (existedExtraProducts[0] === productName.toLowerCase()) {
        swal("", "Extra Product with same name already exists", "warning");
        setProductLoading(false);
      } else {
        // const formData = new FormData();
        // formData.append("image", productImage[0]);
        // const link = await dispatch(uploadSingleProductImage(formData));
        //
        const payload = {
          name: productName,
          adminId,
          // description,
          price,
          // waitTime,
          cost,
          // allergic: allergies,
          totalStock: Number(stock),

          // calories,
          // selectedImage: link,
          // ingrediants: ingredients,
        };
        await dispatch(addExtraProduct(payload));
        clearFields();
        setProductLoading(false);
      }
    } else {
      swal({
        icon: "error",
        title: "Incomplete Information",
      });
      setProductLoading(false);
    }
  };

  const handleProductEdit = (product) => {
    console.log(product);
    // let extraProductArr = [];
    setSingleProduct(product);
    setProductName(product.name);
    setSelectedCategoryName(product.category.name);
    setSelectedCategory(product.category._id);
    setExtraProductSelect(
      product.extraProducts.map((n) => {
        return n.name;
      })
    );
    setExtraProductObj(product.extraProducts);
    setDescription(product.description);
    setIngredients(product.ingrediants);
    setWaitTime(product.waitTime);
    setCost(product.cost);
    setPrice(product.price);
    setImagesReceived([product.logo, ...product.logos]);
    setCalories(product.calories);
    setAllergies(product.allergic);
    setStock(product.totalStock);
    setEdit(true);
  };

  const handleExtraProductEdit = (product) => {
    // console.log(product);

    setSingleProduct(product);
    setProductName(product.name);
    setDescription(product.description);
    setWaitTime(product.waitTime);
    setCost(product.cost);
    setPrice(product.price);
    setImagesReceived([product.logo]);
    setCalories(product.calories);
    setAllergies(product.allergic);
    setStock(product.totalStock);

    setEdit(true);
  };

  const handleUpdateProduct = async () => {
    setUpdateLoading(true);
    let imgArr = [];
    setImageIndex(imageIndex.sort());
    imagesReceived.map((image, i) => {
      if (imageIndex.indexOf(i) === -1) {
        imgArr.push(image);
      }
    });
    let logosArr = [];
    let logosLength = productImage.length;

    if (
      productName !== "" &&
      description !== "" &&
      selectedCategory !== "" &&
      // waitTime !== "" &&
      price !== "" &&
      // calories !== "" &&
      !(
        productImage.length === 0 && imageIndex.length === imagesReceived.length
      )
    ) {
      if (productImage.length === 0) {
        let pictureArr = imgArr;
        let removed = pictureArr.splice(0, 1);
        const payload = {
          productId: singleProduct._id,
          name: productName,
          description,
          price,
          admin: adminId,
          category: selectedCategory,
          waitTime,
          cost,
          allergic: allergies,
          calories,
          logo: removed[0],
          logos: pictureArr,
          extraProducts: extraProductObj,
          totalStock: Number(stock) === 0 ? null : Number(stock),
          ingrediants: ingredients,
        };
        await dispatch(updateProduct(payload));
        setUpdateLoading(false);
        clearFields();
      } else {
        productImage.map(async (images) => {
          const formData = new FormData();
          formData.append("image", images.originFileObj);
          const link = await dispatch(uploadSingleProductImage(formData));
          logosArr.push(link);

          if (logosArr.length === logosLength) {
            let pictureArr = logosArr;
            let removed = pictureArr.splice(0, 1);
            const payload = {
              productId: singleProduct._id,
              name: productName,
              description,
              price,
              adminId,
              category: selectedCategory,
              waitTime,
              cost,
              allergic: allergies,
              calories,
              logo: removed[0],
              logos: [...pictureArr, ...imgArr],
              extraProducts: extraProductObj,
              totalStock: Number(stock) === 0 ? null : Number(stock),

              ingrediants: ingredients,
            };
            await dispatch(updateProduct(payload));
            setUpdateLoading(false);
            clearFields();
          }
        });
      }
    } else {
      swal({
        icon: "error",
        text: "Required Fields Missing (name,category,price,description)",
      });
      setUpdateLoading(false);
    }
  };
  console.log(products);
  const handleUpdateExtraProduct = async () => {
    if (productName !== "" && price !== "") {
      //
      const payload = {
        id: singleProduct._id,
        name: productName,
        adminId,

        price,

        cost,

        calories,
        totalStock: Number(stock) === 0 ? null : Number(stock),
      };

      dispatch(updateExtraProduct(payload));
      clearFields();
    } else {
      swal({
        icon: "error",
        title: "Incomplete Information",
      });
    }
  };

  const clearFields = () => {
    setEdit(false);
    setProductName("");
    setImagesReceived([]);
    setProductImage([]);
    setCost("");
    setPrice("");
    setWaitTime("");
    setDescription("");
    setCalories("");
    setSelectedCategory("");
    setAllergies([]);
    setIngredients([]);
    setExtraProductSelect([]);
    setSelectedCategoryName("");
    setExtraProductObj([]);
    setStock();
    setPreview(false);
    setCategoryUploadImage(null);
    setCategoryName("");
    setCategoryEdit(false);
  };

  const updateProductOrder = async (productId, type) => {
    // console.log(products.length);
    if (Number(orderPosition) > 0 && Number(orderPosition) <= products.length) {
      if (type === "product") {
        console.log("boom");
        const payload = {
          productId: productId._id,
          order: Number(orderPosition),
          category: productId.category._id,
        };
        console.log(payload);
        await dispatch(updateProductPosition(payload));
        setOrderPosition(null);
      } else if (type === "extraProduct") {
        if (orderPosition) {
          const payload = {
            extraProductId: productId,
            order: Number(orderPosition),
          };
          await dispatch(updateExtraProductPosition(payload));
          setOrderPosition(null);
        }
      } else {
        if (orderPosition) {
          const payload = {
            categoryId: productId,
            order: Number(orderPosition),
          };
          await dispatch(updateCategoryPosition(payload));
          setOrderPosition(null);
        }
      }
    } else {
      swal(
        "",
        `product order must be equal to 1 Or ${products?.length} `,
        "error"
      );
    }
  };

  const editCategory = (category) => {
    console.log(category);
    setCategoryEdit(true);
    setCategoryName(category.name);
    setCategoryImage(category.image);
    setCategoryId(category._id);
  };

  const handleUpdateCategory = async () => {
    console.log(categoryImage);
    console.log(categoryUploadImage);
    if (categoryUploadImage === null) {
      if (categoryName !== "") {
        setCategoryUpdateLoading(true);

        const payload = {
          name: categoryName,
          image: categoryImage,
          categoryId,
        };
        await dispatch(updateCategory(payload));
        setCategoryUpdateLoading(false);

        clearFields();
      } else {
        swal("Fields Validation Error");
      }
    } else {
      if (categoryName !== "") {
        setCategoryUpdateLoading(true);

        const formData = new FormData();
        formData.append("image", categoryUploadImage);
        const link = await dispatch(uploadSingleProductImage(formData));
        console.log(link);

        const payload = {
          name: categoryName,
          image: link,
          categoryId,
        };
        await dispatch(updateCategory(payload));
        setCategoryUpdateLoading(false);
        setDeafultList([]);
        clearFields();
      } else {
        swal("Fields Validation Error");
      }
    }
  };

  const handleChange = (info) => {
    if (info.file.status === "removed") {
      setCategoryUploadImage(null);
      setDeafultList([]);
    } else {
      console.log(info);
      setCategoryUploadImage(info.file.originFileObj);
      setDeafultList(info.fileList);
    }
  };

  const handleProductChange = (info) => {
    console.log(info);
    // setProductDeafultList(info.fileList);
    setProductImage(info.fileList);
    // if (info.file.status === "removed") {
    //   setProductImage([]);
    //   setProductDeafultList([]);
    // } else {
    //   console.log(info);
    //   setProductImage(info.file.originFileObj);
    //   setProductDeafultList(info.fileList);
    // }
  };

  return (
    <LayoutDesktop active="menu">
      <div className="setting-menu">
        <Title level={2}>{L.menu_name}</Title>
        <Row>
          <Col xs={24} xl={9}>
            <div className="menu-1st-section">
              <Title level={4}>
                {categoryEdit ? "Edit Category" : "Add Category"}
              </Title>
              <div className="menu-1st-section-items">
                <Input
                  placeholder="Category"
                  className="category-input"
                  onChange={(e) => setCategoryName(e.target.value)}
                  value={categoryName}
                />

                {/* <DropzoneArea
                  onChange={(files) => handleDropzoneChange(files)}
                  filesLimit={1}
                  // initialFiles={productImage}
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                  showPreviewsInDropzone={preview}
                /> */}

                <Upload.Dragger
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={true}
                  maxCount={1}
                  accept="image/*"
                  onChange={handleChange}
                  fileList={defaultList}
                >
                  {
                    <div>
                      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
                      {<PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Upload Category Cover</div>
                    </div>
                  }
                  {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                </Upload.Dragger>

                {categoryEdit ? (
                  <div>
                    <Title style={{ marginTop: "10px" }} level={4}>
                      Images
                    </Title>

                    <img src={categoryImage} alt="" height="100px" />

                    <Button
                      type="primary"
                      className="upload-btn"
                      onClick={clearFields}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      className="upload-btn"
                      onClick={handleUpdateCategory}
                      loading={categoryUpdateLoading}
                    >
                      Update
                    </Button>
                  </div>
                ) : (
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    className="upload-btn"
                    onClick={handleAddCategory}
                    loading={categoryLoading}
                  >
                    Add category
                  </Button>
                )}

                {/* <div className="checkbox-group">
                  <Checkbox
                    value="add"
                    onChange={onChange}
                    checked={productCheck}
                    disabled={edit}
                  >
                    <Title level={5}>Add Product</Title>
                  </Checkbox>
                  <Checkbox
                    value="add-extra"
                    onChange={onChange}
                    checked={!productCheck}
                    disabled={edit}
                  >
                    <Title level={5}>Add Extra Product</Title>
                  </Checkbox>
                </div> */}

                <Tabs
                  activeKey={checkBoxValue}
                  onChange={(key) => setCheckBoxValue(key)}
                  centered
                  style={{ margin: "30px 0" }}
                >
                  <TabPane tab="Add Product" key="add">
                    <div>
                      <div className="add-product-section">
                        <Title level={4}>Add Product</Title>
                      </div>
                      <div className="product-name">
                        <Title level={5}>Name:</Title>
                        <Input
                          placeholder="Product Name"
                          onChange={(e) => setProductName(e.target.value)}
                          value={productName}
                        />
                      </div>
                      <div className="category">
                        <Title level={5}>Category:</Title>
                        <Select
                          onChange={handleCategoryChange}
                          value={selectedCategoryName}
                          className="select-category"
                          // placeholder="Select Category"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {categories &&
                            categories.length > 0 &&
                            categories.map((category) => {
                              return (
                                <Option value={category._id} key={category._id}>
                                  {category.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>

                      <div className="ingredients">
                        <Title level={5}>Add Ingredients:</Title>
                        <Input
                          placeholder="Add Ingredients"
                          value={ingredientsValue}
                          onChange={(e) => setIngredientsValue(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              setIngredients([
                                ...ingredients,
                                ingredientsValue,
                              ]);
                              setIngredientsValue("");
                            }
                          }}
                        />
                        <Button
                          icon={<PlusOutlined />}
                          type="primary"
                          className="upload-btn"
                          onClick={() => {
                            setIngredients([...ingredients, ingredientsValue]);
                            setIngredientsValue("");
                          }}
                        >
                          Add ingredient
                        </Button>
                        <br />
                        <div>
                          <Title level={5}>Ingredients Included:</Title>
                          {ingredients.map((v, i) => {
                            return (
                              <Tag
                                key={i}
                                color="green"
                                onClick={() => delIngredients(i)}
                              >
                                {v}
                              </Tag>
                            );
                          })}
                        </div>
                        <br />
                        <br />
                        <div>
                          <Title level={5}>Photo: </Title>
                          {/* <DropzoneArea
                            onChange={(files) => handleProductDropzone(files)}
                            // showPreviews={true}
                            acceptedFiles={[
                              "image/jpeg",
                              "image/png",
                              "image/bmp",
                            ]}
                            filesLimit={4}
                            showPreviewsInDropzone={preview}
                          /> */}

                          <Upload.Dragger
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={true}
                            maxCount={
                              productImage?.length > 0
                                ? 5 - productImage.length
                                : 5
                            }
                            accept="image/*"
                            multiple={true}
                            onChange={handleProductChange}
                            fileList={productImage}
                          >
                            {
                              <div>
                                {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
                                {<PlusOutlined />}
                                <div style={{ marginTop: 8 }}>
                                  Upload Product Images
                                </div>
                              </div>
                            }
                            {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                          </Upload.Dragger>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              marginTop: "10px",
                            }}
                          >
                            {imagesReceived && imagesReceived.length > 0 ? (
                              <Title level={4}>
                                Tap To Delete/Include Images
                              </Title>
                            ) : null}
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {imagesReceived &&
                              imagesReceived &&
                              imagesReceived.length > 0
                                ? imagesReceived.map((image, i) => {
                                    let ind = imageIndex.indexOf(i);
                                    return (
                                      <img
                                        key={i}
                                        src={image}
                                        alt={image}
                                        height="120px"
                                        width="120px"
                                        style={
                                          ind !== -1
                                            ? {
                                                opacity: 0.1,
                                                margin: "5px",
                                                objectFit: "cover",
                                              }
                                            : {
                                                margin: "5px",
                                                objectFit: "cover",
                                              }
                                        }
                                        onClick={() => {
                                          if (ind === -1) {
                                            setImageIndex([...imageIndex, i]);
                                          } else {
                                            let arr = imageIndex;
                                            arr.splice(ind, 1);
                                            setImageIndex([...arr]);
                                          }
                                        }}
                                      />
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                          <br />
                          <br />
                          <Title level={5}>Description: </Title>
                          <TextArea
                            rows={6}
                            style={{ resize: "none" }}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                          />
                          <br />
                          <br />
                          <Row gutter={[30, 30]}>
                            <Col xs={24} md={8}>
                              Calories
                              <Input
                                onChange={(e) => setCalories(e.target.value)}
                                value={calories}
                                type="number"
                              />
                            </Col>
                            <Col xs={24} md={8}>
                              Wait time
                              <Input
                                onChange={(e) => setWaitTime(e.target.value)}
                                value={waitTime}
                                type="number"
                              />
                            </Col>
                            <Col xs={24} md={8}>
                              Price
                              <Input
                                onChange={(e) => setPrice(e.target.value)}
                                value={price}
                                type="number"
                              />
                            </Col>
                            <Col xs={24} md={8}>
                              Cost
                              <Input
                                onChange={(e) => setCost(e.target.value)}
                                value={cost}
                                type="number"
                              />
                            </Col>
                            <Col xs={24} md={8}>
                              Stock
                              <Input
                                onChange={(e) => setStock(e.target.value)}
                                value={stock}
                                // type="number"
                              />
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <Title level={5}>Allergic:</Title>
                          <Select
                            mode="tags"
                            placeholder="Please select"
                            value={allergies}
                            allowClear
                            onChange={handleAllergies}
                            style={{ width: "100%" }}
                          >
                            <Option value="Gluten">Gluten</Option>
                            <Option value="Lupin">Lupin</Option>
                            <Option value="sulphur">Sulphur Dioxide</Option>
                            <Option value="milk">Milk</Option>
                          </Select>
                          <br />
                          <br />
                          <Title level={5}>Extra Products:</Title>
                          <Select
                            mode="tags"
                            allowClear
                            placeholder="Please select"
                            value={extraProductSelect}
                            onSelect={handleExtraProduct}
                            onDeselect={handleExtraProductDeselect}
                            style={{ width: "100%" }}
                          >
                            {extraProducts && extraProducts.length > 0
                              ? extraProducts.map((product) => {
                                  return (
                                    <Option value={product.name}>
                                      {product.name}
                                    </Option>
                                  );
                                })
                              : null}
                          </Select>
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Add Extra Product" key="add-extra">
                    <div>
                      <div className="add-extraProduct-section">
                        <Title level={4}>Add Extra Product</Title>
                      </div>
                      <div className="product-name">
                        <Title level={5}>Name:</Title>
                        <Input
                          placeholder="Product Name"
                          onChange={(e) => setProductName(e.target.value)}
                          value={productName}
                        />
                      </div>
                      <div>
                        {/* <Title level={5}>Photo: </Title>
                      <DropzoneArea
                        onChange={(files) => handleProductDropzone(files)}
                        // showPreviews={true}
                        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                        filesLimit={1}
                      />
                      {imagesReceived && imagesReceived.length > 0 ? (
                        <img
                          src={imagesReceived[0]}
                          height="120px"
                          width="120px"
                          style={{ marginTop: "10px", objectFit: "cover" }}
                        />
                      ) : null}
                      <br />
                      <br />
                      <Title level={5}>Description: </Title>
                      <TextArea
                        rows={4}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                      <br />
                      <br /> */}
                        <Row gutter={[30, 30]}>
                          {/* <Col xs={24} md={8}>
                          Calories
                          <Input
                            onChange={(e) => setCalories(e.target.value)}
                            value={calories}
                          />
                        </Col>
                        <Col xs={24} md={8}>
                          Wait time
                          <Input
                            onChange={(e) => setWaitTime(e.target.value)}
                            value={waitTime}
                          />
                        </Col> */}
                          <Col xs={24} md={12}>
                            Price
                            <Input
                              onChange={(e) => setPrice(e.target.value)}
                              value={price}
                              type="number"
                            />
                          </Col>
                          <Col xs={24} md={12}>
                            Cost
                            <Input
                              onChange={(e) => setCost(e.target.value)}
                              value={cost}
                              type="number"
                            />
                          </Col>
                          <Col xs={24} md={12}>
                            Stock
                            <Input
                              onChange={(e) => setStock(e.target.value)}
                              value={stock}
                              // type="number"
                            />
                          </Col>
                        </Row>
                        <br />
                        <br />
                        {/* <Title level={5}>Allergic:</Title>
                      <Select
                        mode="tags"
                        placeholder="Please select"
                        value={allergies}
                        onChange={handleAllergies}
                        style={{ width: "100%" }}
                      >
                        <Option value="Gluten">Gluten</Option>
                        <Option value="Lupin">Lupin</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="sulphur">Sulphur Dioxide</Option>
                        <Option value="milk">Milk</Option>
                      </Select> */}
                        <br />
                        <br />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
                {/* ADD product */}

                {/* {checkBoxValue && checkBoxValue === "add" ? (
                  <div>
                    <div className="add-product-section">
                      <Title level={4}>Add Product</Title>
                    </div>
                    <div className="product-name">
                      <Title level={5}>Name:</Title>
                      <Input
                        placeholder="Product Name"
                        onChange={(e) => setProductName(e.target.value)}
                        value={productName}
                      />
                    </div>
                    <div className="category">
                      <Title level={5}>Category:</Title>
                      <Select
                        onChange={handleCategoryChange}
                        value={selectedCategoryName}
                        className="select-category"
                        // placeholder="Select Category"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {categories &&
                          categories.length > 0 &&
                          categories.map((category) => {
                            return (
                              <Option value={category._id} key={category._id}>
                                {category.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>

                    <div className="ingredients">
                      <Title level={5}>Add Ingredients:</Title>
                      <Input
                        placeholder="Add Ingredients"
                        value={ingredientsValue}
                        onChange={(e) => setIngredientsValue(e.target.value)}
                      />
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        className="upload-btn"
                        onClick={() => {
                          setIngredients([...ingredients, ingredientsValue]);
                          setIngredientsValue("");
                        }}
                      >
                        Add ingredient
                      </Button>
                      <br />
                      <div>
                        <Title level={5}>Ingredients Included:</Title>
                        {ingredients.map((v, i) => {
                          return (
                            <Tag
                              key={i}
                              color="green"
                              onClick={() => delIngredients(i)}
                            >
                              {v}
                            </Tag>
                          );
                        })}
                      </div>
                      <br />
                      <br />
                      <div>
                        <Title level={5}>Photo: </Title>
                        <DropzoneArea
                          onChange={(files) => handleProductDropzone(files)}
                          // showPreviews={true}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                          ]}
                          filesLimit={4}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          {imagesReceived && imagesReceived.length > 0 ? (
                            <Title level={4}>
                              Tap To Delete/Include Images
                            </Title>
                          ) : null}
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {imagesReceived &&
                            imagesReceived &&
                            imagesReceived.length > 0
                              ? imagesReceived.map((image, i) => {
                                  let ind = imageIndex.indexOf(i);
                                  return (
                                    <img
                                      key={i}
                                      src={image}
                                      alt={image}
                                      height="120px"
                                      width="120px"
                                      style={
                                        ind !== -1
                                          ? {
                                              opacity: 0.1,
                                              margin: "5px",
                                              objectFit: "cover",
                                            }
                                          : {
                                              margin: "5px",
                                              objectFit: "cover",
                                            }
                                      }
                                      onClick={() => {
                                        if (ind === -1) {
                                          setImageIndex([...imageIndex, i]);
                                        } else {
                                          let arr = imageIndex;
                                          arr.splice(ind, 1);
                                          setImageIndex([...arr]);
                                        }
                                      }}
                                    />
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                        <br />
                        <br />
                        <Title level={5}>Description: </Title>
                        <TextArea
                          rows={4}
                          onChange={(e) => setDescription(e.target.value)}
                          value={description}
                        />
                        <br />
                        <br />
                        <Row gutter={[30, 30]}>
                          <Col xs={24} md={8}>
                            Calories
                            <Input
                              onChange={(e) => setCalories(e.target.value)}
                              value={calories}
                              type="number"
                            />
                          </Col>
                          <Col xs={24} md={8}>
                            Wait time
                            <Input
                              onChange={(e) => setWaitTime(e.target.value)}
                              value={waitTime}
                              type="number"
                            />
                          </Col>
                          <Col xs={24} md={8}>
                            Price
                            <Input
                              onChange={(e) => setPrice(e.target.value)}
                              value={price}
                              type="number"
                            />
                          </Col>
                          <Col xs={24} md={8}>
                            Cost
                            <Input
                              onChange={(e) => setCost(e.target.value)}
                              value={cost}
                              type="number"
                            />
                          </Col>
                          <Col xs={24} md={8}>
                            Stock
                            <Input
                              onChange={(e) => setStock(e.target.value)}
                              value={stock}
                              type="number"
                            />
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <Title level={5}>Allergic:</Title>
                        <Select
                          mode="tags"
                          placeholder="Please select"
                          value={allergies}
                          allowClear
                          onChange={handleAllergies}
                          style={{ width: "100%" }}
                        >
                          <Option value="Gluten">Gluten</Option>
                          <Option value="Lupin">Lupin</Option>
                          <Option value="sulphur">Sulphur Dioxide</Option>
                          <Option value="milk">Milk</Option>
                        </Select>
                        <br />
                        <br />
                        <Title level={5}>Extra Products:</Title>
                        <Select
                          mode="tags"
                          allowClear
                          placeholder="Please select"
                          value={extraProductSelect}
                          onSelect={handleExtraProduct}
                          onDeselect={handleExtraProductDeselect}
                          style={{ width: "100%" }}
                        >
                          {extraProducts && extraProducts.length > 0
                            ? extraProducts.map((product) => {
                                return (
                                  <Option value={product.name}>
                                    {product.name}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="add-extraProduct-section">
                      <Title level={4}>Add Extra Product</Title>
                    </div>
                    <div className="product-name">
                      <Title level={5}>Name:</Title>
                      <Input
                        placeholder="Product Name"
                        onChange={(e) => setProductName(e.target.value)}
                        value={productName}
                      />
                    </div>
                    <div>
                      {/* <Title level={5}>Photo: </Title>
                      <DropzoneArea
                        onChange={(files) => handleProductDropzone(files)}
                        // showPreviews={true}
                        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                        filesLimit={1}
                      />
                      {imagesReceived && imagesReceived.length > 0 ? (
                        <img
                          src={imagesReceived[0]}
                          height="120px"
                          width="120px"
                          style={{ marginTop: "10px", objectFit: "cover" }}
                        />
                      ) : null}
                      <br />
                      <br />
                      <Title level={5}>Description: </Title>
                      <TextArea
                        rows={4}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                      <br />
                      <br /> 
                      <Row gutter={[30, 30]}>
                        <Col xs={24} md={12}>
                          Price
                          <Input
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                            type="number"
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          Cost
                          <Input
                            onChange={(e) => setCost(e.target.value)}
                            value={cost}
                            type="number"
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          Stock
                          <Input
                            onChange={(e) => setStock(e.target.value)}
                            value={stock}
                            type="number"
                          />
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                )} */}
                {edit ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={
                        checkBoxValue && checkBoxValue === "add"
                          ? handleUpdateProduct
                          : handleUpdateExtraProduct
                      }
                      loading={updateLoading}
                      style={{ marginBottom: "10px" }}
                    >
                      Update
                    </Button>
                    <Button type="primary" onClick={clearFields}>
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="upload-btn"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={
                      checkBoxValue && checkBoxValue === "add"
                        ? createProduct
                        : createExtraProduct
                    }
                    loading={productLoading}
                  >
                    {checkBoxValue && checkBoxValue === "add"
                      ? "add product"
                      : "add extra product"}{" "}
                  </Button>
                )}

                <br />
                <br />
              </div>
            </div>
          </Col>

          <Col xs={24} xl={15}>
            <div className="menu-2nd-section">
              <Title level={4}>Categories</Title>
              <Table
                columns={categoryColumns}
                loading={
                  categories === null ? true : false
                  // tableLoading
                }
                //  onChange={onTableChange}
                scroll={{ x: 600 }}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
                dataSource={categories?.map((category, i) => {
                  return {
                    key: category._id,
                    order: (
                      <div style={{ display: "flex" }}>
                        <Input
                          type="number"
                          style={{ width: 60 }}
                          max={categories?.length}
                          // value={orderPosition}
                          min={1}
                          // onFocus={() => {
                          //   console.log(category._id);
                          // }}
                          onChange={(e) => setOrderPosition(e.target.value)}
                        />
                        <Button
                          onClick={() => {
                            updateProductOrder(category._id, "category");
                            setOrderPosition(null);
                          }}
                        >
                          Set
                        </Button>
                      </div>
                    ),
                    image: (
                      <Image src={category.image} height={50} width={50} />
                    ),
                    name: category.name,
                    edit: (
                      <Button
                        type="primary"
                        onClick={() => editCategory(category)}
                      >
                        Edit
                      </Button>
                    ),
                  };
                })}
              />

              <Tabs
                onChange={(key) => setCheckBoxValue(key)}
                // defaultActiveKey="add"
                activeKey={checkBoxValue}
              >
                <TabPane tab="All Products" key="add">
                  <Title level={4}>Products</Title>
                  <Input
                    placeholder="search by name"
                    className="search-bar"
                    allowClear
                    onChange={(e) => searchTable(e)}
                  />
                  <Table
                    columns={columns}
                    loading={
                      products === null ? true : false
                      // tableLoading
                    }
                    dataSource={
                      products &&
                      products.length > 0 &&
                      products.map((product) => {
                        return {
                          key: product._id,
                          position: (
                            <div style={{ display: "flex" }}>
                              <Input
                                type="number"
                                style={{ width: 60 }}
                                max={products?.length}
                                min={1}
                                onChange={(e) =>
                                  setOrderPosition(e.target.value)
                                }
                              />
                              <Button
                                onClick={() =>
                                  updateProductOrder(product, "product")
                                }
                              >
                                Set
                              </Button>
                            </div>
                          ),
                          name: product.name,
                          calories: product.calories,
                          price: product.price,
                          waitTime: product.waitTime,
                          stock: product.totalStock,
                          action: (
                            <div style={{ display: "flex" }}>
                              <MenuModal product={product} />
                              <Button
                                type="primary"
                                style={{ margin: "0 5px" }}
                                onClick={() => handleProductEdit(product)}
                              >
                                Edit
                              </Button>
                              <Button
                                type="primary"
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover this Product!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      dispatch(deleteProduct(product._id));
                                    }
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          ),
                        };
                      })
                    }
                    onChange={onTableChange}
                    scroll={{ x: 600 }}
                    pagination={{
                      pageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                    }}
                  />
                </TabPane>
                <TabPane tab="All Extra Products" key="add-extra">
                  <Title level={4}>Extra Products</Title>
                  <Input
                    placeholder="search by name"
                    className="search-bar"
                    allowClear
                    onChange={(e) => searchTable(e)}
                  />
                  <Table
                    columns={columns}
                    loading={extraProducts === null ? true : false}
                    dataSource={
                      extraProducts &&
                      extraProducts.length > 0 &&
                      extraProducts.map((product) => {
                        return {
                          key: product._id,
                          position: (
                            <div style={{ display: "flex" }}>
                              <Input
                                type="number"
                                style={{ width: 60 }}
                                max={products?.length}
                                // value={orderPosition}
                                min={1}
                                onChange={(e) =>
                                  setOrderPosition(e.target.value)
                                }
                              />
                              <Button
                                onClick={() =>
                                  updateProductOrder(
                                    product._id,
                                    "extraProduct"
                                  )
                                }
                              >
                                Set
                              </Button>
                            </div>
                          ),
                          name: product.name,
                          calories: product.cost,
                          price: product.price,
                          // waitTime: product.waitTime,
                          stock: product.totalStock,
                          action: (
                            <div style={{ display: "flex" }}>
                              {/* <ExtraProductModal product={product} /> */}
                              <Button
                                type="primary"
                                onClick={() => handleExtraProductEdit(product)}
                                style={{ margin: "0 5px" }}
                              >
                                Edit
                              </Button>{" "}
                              <Button
                                type="primary"
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover this Product!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      dispatch(deleteExtraProduct(product._id));
                                    }
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          ),
                        };
                      })
                    }
                    onChange={onTableChange}
                    scroll={{ x: 900 }}
                    pagination={{
                      pageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                    }}
                  />
                </TabPane>
              </Tabs>
              {/* <Title level={4}>
                {checkBoxValue && checkBoxValue === "add"
                  ? "Products"
                  : "Extra Products"}
              </Title> */}
              {/* <div>
                <Input
                  placeholder="search by name"
                  className="search-bar"
                  allowClear
                  onChange={(e) => searchTable(e)}
                />
                {checkBoxValue && checkBoxValue === "add" ? (
                  <Table
                    columns={columns}
                    loading={
                      products === null ? true : false
                      // tableLoading
                    }
                    dataSource={
                      products &&
                      products.length > 0 &&
                      products.map((product) => {
                        return {
                          key: product._id,
                          position: (
                            <div style={{ display: "flex" }}>
                              <Input
                                type="number"
                                style={{ width: 60 }}
                                max={products?.length}
                                min={1}
                                onChange={(e) =>
                                  setOrderPosition(e.target.value)
                                }
                              />
                              <Button
                                onClick={() =>
                                  updateProductOrder(product._id, "product")
                                }
                              >
                                Set
                              </Button>
                            </div>
                          ),
                          name: product.name,
                          calories: product.calories,
                          price: product.price,
                          waitTime: product.waitTime,
                          stock: product.totalStock,
                          action: (
                            <div style={{ display: "flex" }}>
                              <MenuModal product={product} />
                              <Button
                                type="primary"
                                style={{ margin: "0 5px" }}
                                onClick={() => handleProductEdit(product)}
                              >
                                Edit
                              </Button>
                              <Button
                                type="primary"
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover this Product!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      dispatch(deleteProduct(product._id));
                                    }
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          ),
                        };
                      })
                    }
                    onChange={onTableChange}
                    scroll={{ x: 600 }}
                    pagination={{
                      pageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                    }}
                  />
                ) : (
                  <Table
                    columns={columns}
                    loading={
                      extraProducts && extraProducts.length > 0 ? false : true
                    }
                    dataSource={
                      extraProducts &&
                      extraProducts.length > 0 &&
                      extraProducts.map((product) => {
                        return {
                          key: product._id,
                          position: (
                            <div style={{ display: "flex" }}>
                              <Input
                                type="number"
                                style={{ width: 60 }}
                                max={products?.length}
                                // value={orderPosition}
                                min={1}
                                onChange={(e) =>
                                  setOrderPosition(e.target.value)
                                }
                              />
                              <Button
                                onClick={() =>
                                  updateProductOrder(
                                    product._id,
                                    "extraProduct"
                                  )
                                }
                              >
                                Set
                              </Button>
                            </div>
                          ),
                          name: product.name,
                          calories: product.calories,
                          price: product.price,
                          waitTime: product.waitTime,
                          stock: product.totalStock,
                          action: (
                            <div style={{ display: "flex" }}>
                              {/* <ExtraProductModal product={product} /> */}
              {/* <Button
                                type="primary"
                                onClick={() => handleExtraProductEdit(product)}
                                style={{ margin: "0 5px" }}
                              >
                                Edit
                              </Button>{" "}
                              <Button
                                type="primary"
                                onClick={() => {
                                  swal({
                                    title: "Are you sure?",
                                    text: "Once deleted, you will not be able to recover this Product!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      dispatch(deleteExtraProduct(product._id));
                                    }
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          ),
                        };
                      })
                    }
                    onChange={onTableChange}
                    scroll={{ x: 900 }}
                    pagination={{
                      pageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30"],
                    }}
                  />
                )} 
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </LayoutDesktop>
  );
};

export default Menu;
