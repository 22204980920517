import { Typography, Checkbox, Button, Row, Col, Empty } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Orders from "../components/TableSettings/Orders";
import LayoutDesktop from "../layout/DashboardLayout";
import moment from "moment";
import {
  declineOrder,
  getLiveOrders,
  getPrinterStatus,
  getUserInfo,
  updateStatus,
  updateWaiterStatus,
  printCancelledOrder,
} from "../Redux";
import openSocket from "socket.io-client";
import { useState } from "react";
import { url } from "../BaseUrl";
import LiveOrderModal from "../components/Modals/liveOrderModal";
import swal from "sweetalert";
import React from "react";
import { MDBDataTableV5 } from "mdbreact";
import { Link } from "react-router-dom";
import alertTone from "../assets/alert_tone.mp3";

const LiveOrders = () => {
  const { Title, Text } = Typography;
  const [printerStatus, setPrinterStatus] = useState();
  const [waiterCall, setWaiterCall] = useState([]);
  //const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [waiterLoading, setWaiterLoading] = useState(false);
  const [audio] = useState(new Audio(alertTone));
  //const [serverLoading, setServeLoading] = useState(false);

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.LiverOrderReducer.orders);
  console.log(orders);
  const getWaiterCalls = useSelector(
    (state) => state.AccountSettingsReducer.userInfo
  );
  console.log(getWaiterCalls);

  useEffect(async () => {
    console.log("useEffect");
    let adminId = JSON.parse(localStorage.getItem("userId"));

    const printerStatus = await dispatch(getPrinterStatus());
    setPrinterStatus(printerStatus);
    console.log(printerStatus);

    const socket = openSocket(url);

    socket.on(adminId, () => {
      console.log("chala");
      // !printerStatus ? audio.play() : audio.pause();

      dispatch(getLiveOrders());
      if (!printerStatus) {
        audio.play();
      } else {
        audio.pause();
      }
    });
    const wc = await dispatch(getUserInfo());
    setWaiterCall(wc?.waiterCall);
    dispatch(getLiveOrders());

    socket.on(adminId + "waiter", (data) => {
      console.log(data);
      // !printerStatus ? audio.play() : audio.pause();
      setWaiterCall(data.admin.waiterCall);

      if (!printerStatus) {
        audio.play();
      } else {
        audio.pause();
      }
    });
  }, []);

  const dataRow =
    orders &&
    orders.map((order, index) => {
      let date = new Date(order.createdAt);
      date = date.toString();
      date = date.split("GMT");
      date = date[0];
      if (printerStatus) {
        console.log(order);
        return {
          // customer: order.userName ? order.userName : order.user.name,
          customer: order?.user?.name,
          table: order.table ? (
            <Link to={`/liveorder-table/${order.table._id}`}>
              {order.table.name}
            </Link>
          ) : (
            "deleted table"
          ),
          time: date,
          details: <LiveOrderModal orders={order} />,
          delete: (
            <Button
              type="primary"
              onClick={() => {
                setLoadingId(order._id);
                swal({
                  title: "This Order Is going to be cancelled",
                  text: "Do you want to print it?",
                  icon: "warning",
                  buttons: {
                    Cancel: "Cancel",
                    Only: "Only Delete",
                    Print: "Print & Delete",
                  },
                }).then(async (value) => {
                  if (value === "Only") {
                    // order._id === loadingId && setLoading(true);

                    await dispatch(declineOrder(order._id, "only"));
                    // setLoading(false);
                  } else if (value === "Print") {
                    // order._id === loadingId && setLoading(true);
                    await dispatch(declineOrder(order._id, "print"));
                    await dispatch(printCancelledOrder(order._id));
                    // setLoading(false);
                  }
                });
              }}
              loading={loading}
            >
              delete
            </Button>
          ),
        };
      } else {
        return {
          customer: order.userName ? order.userName : order.user.name,
          table: order.table ? (
            <Link to={`/liveorder-table/${order.table._id}`}>
              {order.table.name}
            </Link>
          ) : (
            "deleted table"
          ),

          time: date,
          taken: (
            <Checkbox
              onClick={() => updateOrderStatus(order._id, true)}
              checked={order.status === "taken2"}
            />
          ),
          delivery: (
            <Checkbox
              onClick={() => updateOrderStatus(order._id)}
              checked={order.status === "serving"}
            />
          ),
          details: <LiveOrderModal orders={order} />,
          delete: (
            <Button
              type="primary"
              onClick={() => {
                swal({
                  text: "This Order Is going to be cancelled,Do you want to print it?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    dispatch(declineOrder(order._id));
                  }
                });
              }}
            >
              delete
            </Button>
          ),
        };
      }
    });

  const dataColumns = [
    {
      label: "Customer Name",
      field: "customer",
      // sort: "asc",
    },
    {
      label: "Order Table",
      field: "table",
      sort: "asc",
    },
    {
      label: "Time",
      field: "time",
      sort: "asc",
    },
  ];
  if (!printerStatus) {
    dataColumns.push(
      {
        label: "Taken",
        field: "taken",
        // sort: "asc",
      },
      {
        label: "Delivery",
        field: "delivery",
        // sort: "asc",
      },

      {
        label: "Details",
        field: "details",
        // sort: "asc",
      },
      {
        label: "Delete",
        field: "delete",
        // sort: "asc",
      }
    );
  } else {
    dataColumns.push(
      {
        label: "Details",
        field: "details",
        // sort: "asc",
      },
      {
        label: "Delete",
        field: "delete",
        // sort: "asc",
      }
    );
  }

  const dataTable = {
    columns: [...dataColumns],

    rows: [...dataRow],
  };

  const updateWaiterCall = async (index) => {
    setWaiterLoading(true);
    let tempArr = [];
    tempArr = waiterCall;
    tempArr.splice(index, 1);
    await dispatch(updateWaiterStatus(tempArr));
    setWaiterLoading(false);
  };

  // useEffect(async () => {
  //   let wc = await dispatch(getUserInfo());
  //   setWaiterCall(wc && wc.waiterCall);
  // }, []);

  const updateOrderStatus = (id, taken2) => {
    const payload = {
      orderId: id,
      taken2,
    };
    dispatch(updateStatus(payload));
  };

  return (
    <LayoutDesktop active="liveOrders">
      <Title level={2}>Waiter Calls</Title>
      {/* {waiterCall && waiterCall.length > 0 ? "Waiter Calls" : ""} */}

      <Row gutter={[30, 30]} style={{ marginBottom: "20px" }}>
        {waiterCall && waiterCall.length > 0 ? (
          waiterCall.reverse().map((call, index) => {
            return (
              <Col xs={24} md={12} lg={8}>
                <div className="call-card">
                  <Typography.Title level={5}>
                    {call.table.name} calling for waiter
                  </Typography.Title>
                  <div className="call-time">
                    <Typography.Text type="secondary">
                      {moment(call.time).format("h:mm a")}
                    </Typography.Text>
                    <Button
                      type="primary"
                      onClick={() => updateWaiterCall(index)}
                      // loading={waiterLoading}
                    >
                      Serve
                    </Button>
                  </div>
                </div>
              </Col>
            );
          })
        ) : (
          <div
            style={{
              margin: "0 auto",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Empty description="No Waiter Calls" />
          </div>
        )}
      </Row>
      <Title level={2}>Live Orders</Title>
      <div className="live-orders">
        <div>
          <Text type="danger" className="printer-text">
            Printer Module Is {printerStatus && printerStatus ? "On" : "Off"}
          </Text>
          {/* <Input
            placeholder="search"
            onChange={(e) => {
              setSearchValue(e.target.value);
              dispatch(searchTable(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                dispatch(searchTable(searchValue));
              }
            }}
          /> */}
        </div>
        {/* <Table
          columns={printerStatus ? columns2 : columns}
          // loading={orders && orders.length > 0 ? false : true}
          dataSource={
            orders &&
            orders.length > 0 &&
            orders.map((order, index) => {
              let date = new Date(order.createdAt);
              date = date.toString();
              date = date.split("GMT");
              date = date[0];

              return {
                key: order._id,
                name: order.user.name,
                table: order.table.name,
                time: date,
                taken: (
                  <Checkbox
                    onClick={() => updateOrderStatus(order._id, true)}
                    checked={order.status === "taken2"}
                  />
                ),
                delivery: (
                  <Checkbox
                    onClick={() => updateOrderStatus(order._id)}
                    checked={order.status === "serving"}
                  />
                ),
                details: <LiveOrderModal orders={order} />,
                delete: (
                  <Button
                    type="primary"
                    onClick={() => {
                      swal({
                        text: "This Order Is going to be cancelled,Do you want to print it?",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          swal("Order Declined", {
                            icon: "success",
                          });
                        }
                      });
                    }}
                  >
                    delete
                  </Button>
                ),
              };
            })
          }
          // onChange={onCustomerTableChange}
          scroll={{ x: 600 }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
        /> */}
        <MDBDataTableV5
          hover
          fullPagination
          entriesOptions={[5, 20, 25, 50]}
          entries={5}
          pagesAmount={4}
          data={dataTable}
          pagingTop
          searchTop
          // paging={false}
          searchBottom={false}
          barReverse
          striped
          bordered
          responsive
        />
      </div>
    </LayoutDesktop>
  );
};

export default LiveOrders;
