import * as MenuTypes from "../Types/MenuTypes";

const initialState = {
  categories: null,
  products: null,
  extraProducts: [],
  productImages: [],
};

var productsCopy;
var extraCopy;

const menuReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MenuTypes.GET_PRODUCTS:
      productsCopy = payload;
      return {
        ...state,
        products: payload,
      };
    case MenuTypes.GET_EXTRA_PRODUCTS:
      extraCopy = payload;
      return {
        ...state,
        extraProducts: payload,
      };
    case MenuTypes.SEARCH_PRODUCT:
      let searchProduct = payload.toLowerCase();
      const newProduct = productsCopy.filter((obj) => {
        return obj.name.toLowerCase().includes(searchProduct);
      });
      return {
        ...state,
        products: newProduct,
      };
    case MenuTypes.SEARCH_EXTRA_PRODUCT:
      let searchExtraProduct = payload.toLowerCase();
      const newExtraProduct = extraCopy.filter((obj) => {
        return obj.name.toLowerCase().includes(searchExtraProduct);
      });
      return {
        ...state,
        extraProducts: newExtraProduct,
      };
    case MenuTypes.GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    // case MenuTypes.ADD_PRODUCT_IMAGES:
    //   return {
    //     ...state,
    //     productImages: [...productImages, payload],
    //   };

    default:
      return state;
  }
};

export default menuReducer;
