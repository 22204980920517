import { Layout, Typography, Menu, Dropdown, Avatar } from "antd";
import { Link, useHistory } from "react-router-dom";
//import { useDispatch } from "react-redux";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import DashboardMenuDrawer from "./DashboardMenuDrawer";
// import {  } from "../Redux";

const { Content } = Layout;

const MobileLayout = ({ active, children }) => {
  const history = useHistory();
  //const dispatch = useDispatch();

  const menu = (
    <Menu>
      <Menu.Item key="0" icon={<SettingOutlined />}>
        <Link to="/user/settings">Account Settings</Link>
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          localStorage.clear();
          history.push("/login");
        }}
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="dashboard-mobile">
      <div className="mobile-header">
        <DashboardMenuDrawer active={active} />
        <Typography.Title level={4}>QR Treat</Typography.Title>
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <Avatar
              style={{ backgroundColor: "#f0a7d3" }}
              icon={<UserOutlined />}
            />
          </a>
        </Dropdown>
      </div>
      <Content className="dashboard-children">{children}</Content>
    </Layout>
  );
};

export default MobileLayout;
