import { useState } from "react";
import { Button, Typography, Modal, Row, Col, Tag } from "antd";

const CustomerOrdersModal = ({ details, name }) => {
  console.log("details", details);
  console.log("name", name);

  //const { Option } = Select;
  const { Title, Text } = Typography;
  const [modalVisible, setModalVisible] = useState(false);
  const modalChange = (modalVisible) => {
    setModalVisible(modalVisible);
  };
  let date = new Date(details.createdAt);
  date = date.toString();
  date = date.split("GMT");
  date = date[0];

  return (
    <div>
      <Button type="primary" onClick={() => modalChange(true)}>
        View
      </Button>
      <Modal
        title="Order Details"
        centered
        visible={modalVisible}
        onOk={() => modalChange(false)}
        onCancel={() => modalChange(false)}
        width={700}
      >
        <div>
          <Row>
            <Col span={12}>
              <Title level={5}>
                Customer Name : <Text type="secondary">{name}</Text>
              </Title>
            </Col>
            <Col span={12}>
              <Title level={5}>
                Customer Contact :{" "}
                <Text type="secondary">{details.user.mobile}</Text>
              </Title>
            </Col>
            <Col span={12}>
              <Title level={5}>
                Total Bill : <Text type="secondary">{details.amount}</Text>
              </Title>
            </Col>
            <Col span={12}>
              <Title level={5}>
                Order Time: <Text type="secondary">{date}</Text>
              </Title>
            </Col>
          </Row>
          {details.products.map(
            ({ quantity, product, extraProducts, allergicNames }) => {
              return (
                <div className="white-bg-inside-modal">
                  <Row>
                    <Col span={8} xs={24}>
                      <Title level={5}>
                        Product Name :{" "}
                        <Text type="secondary">{product.name}</Text>
                      </Title>
                    </Col>
                    <Col span={8} xs={12}>
                      <Title level={5}>
                        Quantity: <Text type="secondary">{quantity}</Text>
                      </Title>
                    </Col>
                    <Col span={8} xs={12}>
                      <Title level={5}>
                        Per Price: <Text type="secondary">{product.price}</Text>
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Title level={5}>
                        Extra Products :{" "}
                        {extraProducts.map((pn) => {
                          return (
                            <Tag
                              style={{ padding: "3px 7px", fontSize: "0.8em" }}
                              color="#F0A9D4"
                            >
                              {pn.productName}{" "}
                            </Tag>
                          );
                        })}
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Title level={5}>
                        Allergies :
                        {allergicNames.map((allergy) => {
                          return (
                            <Tag
                              color="#061629"
                              style={{ padding: "3px 7px", fontSize: "0.8em" }}
                            >
                              {allergy.allergicName}
                            </Tag>
                          );
                        })}
                      </Title>
                    </Col>
                  </Row>
                </div>
              );
            }
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CustomerOrdersModal;
