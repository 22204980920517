import axios from "axios";
import swal from "sweetalert";
import { url } from "../../BaseUrl";
import * as AccountSettingsTypes from "../Types/AccountSettingsTypes";
import { getUserInfo } from "./AccountSettingsAction";

export const vendorLogin = (email, password, history) => async (dispatch) => {
  try {
    const payload = {
      email,
      password,
    };
    const res = await axios.post(`${url}/admin/login`, payload);
    console.log(res.data);
    if (res) {
      const info = await dispatch(getUserInfo(res.data.userId));
      console.log(info.admin);
      if (info.admin.active) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", JSON.stringify(res.data.userId));
        localStorage.setItem("vendorEmail", JSON.stringify(email));
        localStorage.setItem("role", "ADMIN");
        localStorage.setItem("verified", false);
        swal("Login Success", "", "success").then(() => {
          history.push("/liveorders");
          window.location.reload();
        });
      } else {
        swal(
          "You are Blocked",
          "Please connect to QR Treat management.",
          "error"
        );
      }
    }
  } catch (err) {
    swal("Wrong email or Password!", "", "error");
  }
};

export const forgetPassword = (payload, history) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/forget-password`, payload);
    if (
      res &&
      res.data.message === "Recovery Code has been sent to your Email"
    ) {
      console.log(res);
      swal("", res.data.message, "success");
      localStorage.setItem("userType", payload.userType);
      localStorage.setItem("email", payload.email);
      history.push("/verify-code");
    } else {
      swal("", res.data.message, "info");
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};

export const verifyCode = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/verify-code`, payload);
    if (res && res.data.message === "Code Verified") {
      console.log(res);
      swal("", `${res.data.message},Now you can set new password`, "success");
      localStorage.setItem("recoveryToken", res.data.recoveryToken);

      return { isVerified: true };
    } else {
      swal("", `${res.data.message}`, "error");
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};
export const changePassword = (payload, history) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/change-password-forget`, payload);
    if (res) {
      console.log(res);
      swal("", `${res.data.message}`, "success").then(() => {
        history.replace("/login");
        localStorage.removeItem("recoveryToken");
        localStorage.removeItem("email");
        localStorage.removeItem("userType");
      });

      return { isVerified: true };
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};
export const verifyVendorLogin =
  (email, password, history) => async (dispatch) => {
    try {
      const payload = {
        email,
        password,
      };
      const res = await axios.post(`${url}/admin/login`, payload);
      if (res) {
        // localStorage.setItem("verified", true);
        dispatch({
          type: AccountSettingsTypes.VERIFY,
          payload: true,
        });
        history.replace("/account-setting");
      }
    } catch (err) {
      swal("Wrong email or Password!", "", "error");
    }
  };
export const toggleVerify = () => async (dispatch) => {
  dispatch({
    type: AccountSettingsTypes.VERIFY,
    payload: false,
  });
};
