import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import LayoutDesktop from "../layout/LayoutDesktop";
import { getCustomerDetails } from "../Redux/Actions/CustomerActions";
import { Button, Typography, Table } from "antd";
//import OrdersModals from "../components/Modals/TableSettings/OrdersModals";
import CustomerOrdersModal from "../components/Modals/CustomerDetails/CustomerOrdersModal";
import ProductComments from "../components/Customers/ProductComments";
import ProductRatings from "../components/Customers/ProductRatings";
import ProfileRating from "../components/Customers/ProfileRatings";
import ProfileComments from "../components/Customers/ProfileComments";

const CustomerDetails = () => {
  const { id } = useParams();
  const { Title, Text } = Typography;
  const dispatch = useDispatch();
  const customerDetails = useSelector(
    (state) => state.CustomerReducer.customerDetails
  );
  console.log(customerDetails);
  const history = useHistory();
  // table order Column
  const ordersColumns = [
    {
      title: "Table Name",
      dataIndex: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 3,
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: {
        compare: (a, b) => a.createdAt - b.createdAt,
        multiple: 2,
      },
    },
    {
      title: "View Details",
      dataIndex: "details",
    },
  ];

  useEffect(() => {
    dispatch(getCustomerDetails(id));
  }, []);

  return (
    <LayoutDesktop>
      <Button type="primary" onClick={() => history.goBack()}>
        Back
      </Button>
      <div className="customer-details">
        <div className="table-card">
          <Title level={4}>
            Name :{" "}
            <Text type="success">
              {customerDetails && customerDetails.user.name}
            </Text>{" "}
          </Title>
          <Title level={4}>
            City :{" "}
            <Text type="success">
              {customerDetails && customerDetails.user.city}
            </Text>
          </Title>
        </div>
        {/* table Orders  */}
        <div className="table-card">
          <Title level={2}>Orders</Title>
          <Table
            columns={ordersColumns}
            loading={
              customerDetails && customerDetails.ordersArray.length > 0
                ? false
                : true
            }
            dataSource={
              customerDetails &&
              customerDetails.ordersArray.length > 0 &&
              customerDetails.ordersArray.map((order) => {
                let date = new Date(order.createdAt);
                date = date.toString();
                date = date.split("GMT");
                date = date[0];

                return {
                  key: order._id,
                  name: order.table ? order.table.name : "",
                  amount: order.amount,
                  createdAt: date,
                  details: (
                    <CustomerOrdersModal
                      details={order}
                      name={customerDetails.user.name}
                    />
                  ),
                };
              })
            }
            // onChange={onCustomerTableChange}
            scroll={{ x: 600 }}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
            }}
          />
        </div>
        <div className="table-card">
          <ProductComments
            comments={customerDetails && customerDetails.productComments}
          />
        </div>
        <div className="table-card">
          <ProductRatings
            productRatings={customerDetails && customerDetails.productRatings}
          />
        </div>
        <div className="table-card">
          <ProfileComments
            comments={customerDetails && customerDetails.profileComments}
          />
        </div>
        <div className="table-card">
          <ProfileRating
            ratings={customerDetails && customerDetails.profileRatings}
          />
        </div>
      </div>
    </LayoutDesktop>
  );
};

export default CustomerDetails;
