import {
  Checkbox,
  Row,
  Col,
  Typography,
  DatePicker,
  Radio,
  Table,
  Button,
} from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTableHistory } from "../../Redux/Actions/TableSettingsActions";
import OrdersModals from "../Modals/TableSettings/OrdersModals";
//import Orders from "./Orders";

const TableHistory = ({ tableId }) => {
  const { Title } = Typography;
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "Order Name",
      dataIndex: "order",
      key: "order",
      sorter: (a, b) => a.order - b.order,
      //   sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      ellipsis: true,
    },
    {
      title: "Unit Price",
      dataIndex: "unit",
      key: "unit",

      sorter: (a, b) => a.unit - b.unit,
      ellipsis: true,
    },
    {
      title: "Total Price",
      dataIndex: "total",
      key: "total",

      sorter: (a, b) => a.total - b.total,
      //   sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",

      sorter: (a, b) => a.customer - b.customer,
      //   sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      //   filteredValue: filteredInfo.address || null,
    },
  ];
  const [date, setDateRange] = useState(false);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const [range, setRange] = useState([]);
  const history = useSelector(
    (state) => state.TableSettingsReducer.tableHistory
  );

  const onChange = (e) => {
    setType(e.target.value);
    if (e.target.value === "today") {
      const payload = {
        tableId,
        type: e.target.value,
      };
      dispatch(getTableHistory(payload));
    } else if (e.target.value === "allTime") {
      const payload = {
        tableId,
      };
      dispatch(getTableHistory(payload));
    }
  };

  let orders = [];

  history &&
    history.map((details) => {
      details.products.map((item) => {
        // console.log(item);
        orders.push({
          key: item._id,
          order: item.product ? item.product.name : "-",
          quantity: item.quantity,
          unit: item.product ? item.product.price : "-",
          total: item.product
            ? parseInt(item.product.price)
            : "-" * item.quantity,
          customer: details.userName ? details.userName : details.user.name,
          action: <OrdersModals details={details} />,
        });
      });
    });
  const getRangeHistory = () => {
    const payload = {
      tableId,
      type: "range",
      from: range[0],
      to: range[1],
    };
    dispatch(getTableHistory(payload));
  };
  return (
    <div>
      <Title level={1}>Table History</Title>
      <div className="table-history-cb">
        <Row>
          <Col xs={24} md={16}>
            <Radio.Group
              style={{ width: "100%" }}
              onChange={onChange}
              // defaultValue={}
            >
              <Radio value="today">
                <Title level={4}>Today</Title>
              </Radio>
              <Radio value="allTime">
                <Title level={4}>All Time</Title>
              </Radio>
            </Radio.Group>
          </Col>
          <Col xs={24} md={8}>
            <Checkbox
              // value="date"
              checked={date}
              key={3}
              // checked={type === "date"}
              onChange={(e) => {
                if (e.target.checked) {
                  setDateRange(true);
                } else {
                  setDateRange(false);
                }
              }}
            >
              <Title level={4}>Date Range</Title>
            </Checkbox>
          </Col>
        </Row>
        <br />
        <br />
        {date && (
          <div className="date-picker">
            <RangePicker
              onChange={(values, dateString) => {
                setRange(dateString);
              }}
            />
            <Button type="primary" onClick={getRangeHistory}>
              Apply Filter
            </Button>
          </div>
        )}
      </div>
      <div>
        <Table
          columns={columns}
          // loading={history && history.length > 0 ? false : true}
          dataSource={orders}
          // onChange={handleChange}
          scroll={{ x: 600 }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
        />
      </div>
    </div>
  );
};

export default TableHistory;
