import * as liveOrderTypes from "../Types/LiveOrderTypes";

const initialState = {
  orders: [],
};

const LiverOrderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case liveOrderTypes.GET_LIVE_ORDERS:
      return {
        ...state,
        orders: payload,
      };

    case liveOrderTypes.SEARCH:
      const searchCustomer = payload.toLowerCase();
      //let prevOrders = state.orders;
      const newCustomer = state.orders.filter((obj) => {
        //let userName = obj.user.name.toLowerCase();
        //let tableName = obj.user.name.toLowerCase();
        return (
          obj.user.name.toLowerCase().includes(searchCustomer) ||
          obj.table.name.toLowerCase().includes(searchCustomer)
        );
        // return (
        //   obj.user.name.toLowerCase().includes(searchCustomer) ||
        //   obj.table.name.toLowerCase().includes(searchCustomer)
        // );
      });
      return {
        ...state,
        orders: newCustomer,
      };

    default:
      return state;
  }
};

export default LiverOrderReducer;
