import { Typography, Input, Table, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { searchAllCustomersInTables } from "../../../Redux";
const CustomerSearch = ({ setCustomerId }) => {
  const { Title } = Typography;

  const [data, setData] = useState([]);
  const [name, setName] = useState("");

  const dispatch = useDispatch();
  const columns = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",

      sorter: (a, b) => a.name.length - b.name.length,
      //   sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Table Name",
      dataIndex: "tableName",
      key: "tableName",
      sorter: (a, b) => a.tableName - b.tableName,
      //   sortOrder: sortedInfo.columnKey === "age" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Total Bill",
      dataIndex: "totalBill",
      key: "totalBill",
      sorter: (a, b) => a.totalBill - b.totalBill,
      //   sortOrder: sortedInfo.columnKey === "age" && sortedInfo.order,
      ellipsis: true,
    },
    // {
    //   title: "Pay",
    //   dataIndex: "pay",
    //   key: "pay",
    // },
    // {
    //   title: "Shift Order",
    //   dataIndex: "shift",
    //   key: "shift",
    // },
  ];

  const onSearchCustomer = async (e) => {
    setName(e);
    if (e !== "") {
      let result = await dispatch(searchAllCustomersInTables(e));
      console.log(result);
      setData(result && result);
    } else {
      setData([]);
    }
    let result2 = await data?.filter((item, i) => {
      return item.name === e;
    });
    setCustomerId(result2 && result2[0] && result2[0]._id);
  };
  return (
    <div>
      <div className="customers-list">
        <div className="customer-search">
          <Title level={2}>Search Customers</Title>
          <Input
            value={name}
            onChange={(e) => {
              if (e.target.value === "") {
                onSearchCustomer(e.target.value);
              } else {
                setName(e.target.value);
              }
            }}
            placeholder="search by name"
            className="search-bar"
            suffix={
              <Button
                icon={<SearchOutlined />}
                shape="circle"
                type="primary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  onSearchCustomer(name);
                }}
              />
            }
            // allowClear={() => onSearchCustomer("")}
          />
        </div>
        <div className="table-container">
          <Table
            columns={columns}
            scroll={{ x: 600 }}
            // loading={customerList && customerList.length > 0 ? false : true}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
            }}
            dataSource={
              // customerList && customerList.length > 0
              //   ? customerList.map((details) => {
              //       return {
              //         key: details._id,
              //         name: details.userName
              //           ? details.userName
              //           : details.user.name,
              //         totalBill: details.amount,
              //         pay: (
              //           <CustomerPayModal
              //             details={details}
              //             customerList={customerList}
              //           />
              //         ),
              //         shift: (
              //           <Button type="primary" onClick={() => showModal(details)}>
              //             Shift Order
              //           </Button>
              //         ),
              //       };
              //     })
              //   : null
              data?.map((item, i) => {
                return {
                  key: item._id,
                  name: item.user.name,
                  tableName: (
                    <span
                      onClick={() => {
                        setCustomerId(item.table._id);
                        setData([]);
                        setName("");
                      }}
                      style={{ color: "dodgerblue", cursor: "pointer" }}
                    >
                      {item.table ? item.table.name : ""}
                    </span>
                  ),
                  totalBill: item.amount,
                };
              })
            }
            //   onChange={handleChange}
          />
        </div>

        {/* <Modal
        title="Shift Order"
        centered
        visible={modalVisible}
        onOk={() => modalChange(false)}
        onCancel={() => modalChange(false)}
        width={300}
        footer={false}
      >
        {tabNames.map((item) => {
          return (
            <Button
              type="primary"
              block
              style={{ margin: "5px 0" }}
              onClick={() => shiftOrder(item)}
              disabled={item._id === tableId || !item.active ? "true" : false}
            >
              {item.name}
            </Button>
          );
        })}
      </Modal> */}
      </div>
    </div>
  );
};

export default CustomerSearch;
