import axios from "axios";
import swal from "sweetalert";
import { url } from "../../BaseUrl";
import * as StatisticsTypes from "../Types/StatisticsTypes";
const adminId = JSON.parse(localStorage.getItem("userId"));

export const getMyProducts = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/admin/get-products/${adminId}`);
    if (res) {
      dispatch({
        type: StatisticsTypes.GET_MY_PRODUCTS,
        payload: res.data.products,
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};
export const searchProducts = (payload) => async (dispatch) => {
  if (payload === "") {
    dispatch(getMyProducts());
  } else {
    dispatch({
      type: StatisticsTypes.SEARCH_MY_PRODUCTS,
      payload: payload,
    });
  }
};
export const showProductStats = (productId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${url}/admin/get-populated-product/${productId}`
    );
    if (res) {
      console.log(res);
      dispatch({
        type: StatisticsTypes.GET_PRODUCT_ID,
        payload: res.data.product,
      });
      return res.data.product;
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};
export const getWorkers = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/get-workers`, { adminId });
    if (res) {
      dispatch({
        type: StatisticsTypes.GET_WORKERS,
        payload: res.data.workers,
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};

export const searchWorkers = (payload) => async (dispatch) => {
  if (payload === "") {
    dispatch(getWorkers());
  } else {
    dispatch({
      type: StatisticsTypes.SEARCH_WORKER,
      payload: payload,
    });
  }
};

export const updateWorkerStatus = (workerId) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/update-worker-status`, {
      workerId,
      adminId,
    });
    if (res) {
      dispatch(getWorkers());
      dispatch({
        type: StatisticsTypes.UPDATE_WORKER,
        payload: res.data.workers,
      });
      swal("Status Updated", "", "success");
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};
