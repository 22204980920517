import axios from "axios";
import swal from "sweetalert";
import { getTableOrders } from "..";
import { headers, url } from "../../BaseUrl";
import * as liveOrderTypes from "../Types/LiveOrderTypes";
//import { getUserInfo } from "./AccountSettingsAction";
const adminId = JSON.parse(localStorage.getItem("userId"));

export const getLiveOrders = () => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/get-active-orders`, {
      adminId,
    });
    if (res) {
      console.log(res.data);
      dispatch({
        type: liveOrderTypes.GET_LIVE_ORDERS,
        payload: res.data.orders,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
export const getPrinterStatus = () => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/get-print-status`, {
      adminId,
    });
    if (res) {
      // console.log(res.data);
      return res.data.admin.printerModule;
    }
  } catch (err) {
    console.log(err);
  }
};
export const updateStatus = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/update-order-status`, payload);
    if (res) {
      swal(res.data.message, "", "success");
      dispatch(getLiveOrders());
    }
    // console.log(payload);
  } catch (err) {
    console.log(err);
  }
};
export const updateWaiterStatus = (waiterCall) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/user/update-call-waiter`, {
      adminId,
      waiterCall,
    });
    if (res) {
      console.log(res.data);
      swal("Waiter Called", "", "success");
      // dispatch(getUserInfo());
    }
  } catch (err) {
    console.log(err);
  }
};
export const searchTable = (payload) => async (dispatch) => {
  console.log(payload);
  if (payload === "") {
    dispatch(getLiveOrders());
  } else {
    dispatch({
      type: liveOrderTypes.SEARCH,
      payload: payload,
    });
  }
};

export const declineOrder = (orderId, type) => async (dispatch) => {
  console.log(orderId);
  try {
    const res = await axios.post(
      `${url}/admin/cancel-order`,
      { orderId },
      {
        headers: headers,
      }
    );
    if (res) {
      // console.log(res.data);
      {
        type === "only"
          ? swal("", `${res.data.message}`, "success")
          : swal("", `${res.data.message} and Printed`, "success");
      }
      dispatch(getLiveOrders());
    }
  } catch (err) {
    console.log(err);
  }
};
export const deleteLiveOrderProduct =
  (payload, tableId) => async (dispatch) => {
    console.log(payload);
    try {
      const res = await axios.post(
        `${url}/admin/delete-product-from-order`,
        payload,
        {
          headers: headers,
        }
      );
      if (res) {
        // console.log(res.data);
        dispatch(getLiveOrders());
        dispatch(getTableOrders(tableId));
        swal("", `${res.data.message}`, "success");
      }
    } catch (err) {
      console.log(err);
    }
  };

export const printCancelledOrder = (orderId) => async (dispatch) => {
  console.log(orderId);
  console.log("printing..");
  try {
    const res = await axios.post(
      `${url}/admin/print-cancelled-order`,
      { orderId },
      {
        headers: headers,
      }
    );
    if (res) {
      // console.log(res.data);
      dispatch(getLiveOrders());
      swal("", `${res.data.message}`, "success");
    }
  } catch (err) {
    swal("", err?.response?.data?.message || "Server Error", "error");
    console.log(err);
  }
};
