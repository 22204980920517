import * as TableSettingsTypes from "../Types/TableSettingsTypes";

const initialState = {
  tableTabNames: [],
  tableOrders: [],
  tableHistory: [],
};

var tableOrderCopy;

const TableSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TableSettingsTypes.GET_TABLE_NAMES:
      return {
        ...state,
        tableTabNames: payload,
      };
    case TableSettingsTypes.GET_TABLE_ORDERS:
      tableOrderCopy = payload;
      return {
        ...state,
        tableOrders: payload,
      };
    case TableSettingsTypes.GET_TABLE_HISTORY:
      return {
        ...state,
        tableHistory: payload,
      };
    case TableSettingsTypes.SEARCH_TABLE_CUSTOMER:
      const searchCustomer = payload.toLowerCase();
      const newCustomer = tableOrderCopy.filter((obj, i) => {
        return obj.user.name.toLowerCase().includes(searchCustomer);
      });
      return {
        ...state,
        tableOrders: newCustomer,
      };
    default:
      return state;
  }
};
export default TableSettingsReducer;
