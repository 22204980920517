import {
  Col,
  Row,
  Typography,
  Radio,
  Select,
  Input,
  DatePicker,
  Button,
  Checkbox,
  Table,
  Upload,
} from "antd";
import LayoutDesktop from "../layout/DashboardLayout";
import { DropzoneArea } from "material-ui-dropzone";
import { PercentageOutlined, PlusOutlined } from "@ant-design/icons";

// import {
//   CountryDropdown,
//   RegionDropdown,
//   CountryRegionData,
// } from "react-country-region-selector";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getAllProducts,
  getAllUsers,
  getPromotions,
  deletePromotion,
  searchPromotion,
  customerPromotion,
  createAllProductPromotion,
  createSingleProductPromotion,
  createFreeProductPromotion,
} from "../Redux";
import moment from "moment";
import swal from "sweetalert";
import { uploadSingleProductImage } from "../Redux/Actions/MenuActions";

const Promotions = () => {
  const columns = [
    {
      title: "Promotion type",
      dataIndex: "type",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: "Customer Name",
      dataIndex: "cName",
      sorter: {
        compare: (a, b) => a.cName - b.cName,
        multiple: 2,
      },
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      sorter: {
        compare: (a, b) => a.percentage - b.percentage,
        multiple: 2,
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      sorter: {
        compare: (a, b) => a.startDate - b.startDate,
        multiple: 1,
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      sorter: {
        compare: (a, b) => a.endDate - b.endDate,
        multiple: 1,
      },
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const allCountries = useSelector(
    (state) => state.AccountSettingsReducer.countries
  );
  const allCities = useSelector((state) => state.AccountSettingsReducer.cities);
  const allProducts = useSelector((state) => state.PromotionReducer.products);
  const allUsers = useSelector((state) => state.PromotionReducer.users);
  const allPromotions = useSelector(
    (state) => state.PromotionReducer.promotions
  );

  // console.log(allCountries);
  const dispatch = useDispatch();
  const { Option } = Select;
  const { Title } = Typography;
  const { RangePicker } = DatePicker;
  const [country, setCountry] = useState("");
  //const [region, setRegion] = useState("");
  const [discountType, setDiscountType] = useState("single");
  const [logo, setLogo] = useState([]);
  const [freeLogo, setFreeLogo] = useState([]);
  const [products, setProducts] = useState("");
  const [dateFilter, setDateFilter] = useState(["", ""]);
  const [
    ,
    //customer
    setCustomer,
  ] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [selectUser, setSelectUser] = useState("");
  const [city, setCity] = useState("");
  const [buyQuantity, setBuyQuantity] = useState();
  const [freeQuantity, setFreeQuantity] = useState();
  const [discountLoading, setDiscountLoading] = useState(false);
  const [freeLoading, setFreeLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [delId, setDelId] = useState("");

  const [preview, setPreviews] = useState(true);
  const [freeCountry, setFreeCountry] = useState("");
  const [defaultList, setDefaultList] = useState([]);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getAllProducts());
    dispatch(getAllUsers());
    dispatch(getPromotions());
  }, []);
  const onPromotionChange = (e) => {
    setDiscountType(e.target.value);
    setCity();
    setPercentage("");
    setCountry();
    setDateFilter("");
  };
  // const handleDropZone = (files) => {
  //   setFile(files);
  // };

  const makeFieldsEmpty = () => {
    console.log("hello");
    setCity();
    setFreeQuantity();
    setBuyQuantity();
    setProducts();
    setCity();
    setDateFilter(null);
    setCountry();
    setPercentage("");
    setLogo([]);
    setFreeLogo([]);
    setPreviews(false);
    setFreeCountry("");
  };
  const handleProductChange = (e) => {
    console.log(e);
    setProducts(e);
  };

  // console.log(allPromotions);

  const onPickerChange = (e, string) => {
    // console.log(string);
    console.log(string);
    setDateFilter(string);
  };
  // console.log(allPromotions);
  //   console.log(dateFilter[1]._d);
  const onCustomerSearch = (value) => {
    setCustomer(value);
  };
  // console.log(customer);
  const onCheckChange = (e) => {
    // console.log(e.target.checked);
    if (e.target.checked) {
      setPercentage(100);
    } else {
      setPercentage(percentage);
    }
  };
  const searchTable = (e) => {
    dispatch(searchPromotion(e.target.value));
  };
  function onTableChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  const OnCountryChange = (value) => {
    setCountry(value);
    dispatch(getCities(value));
  };
  const onFreeCountryChange = (value) => {
    setFreeCountry(value);
    dispatch(getCities(value));
  };
  const customerPromotionChange = (e) => {
    if (e.target.value >= 100) {
      setPercentage(100);
    } else {
      setPercentage(e.target.value);
    }
  };

  console.log(logo);
  // console.log(products);\
  // console.log(city);
  const createPromotion = async () => {
    setDiscountLoading(true);
    if (discountType === "single") {
      let logoLength = logo.length;
      let logoArr = [];
      if (logoLength > 0) {
        logo.map(async (item) => {
          const formData = new FormData();
          formData.append("image", item.originFileObj);
          const link = await dispatch(uploadSingleProductImage(formData));
          logoArr.push(link);
          console.log("link ", link);
          if (logoArr.length === logoLength) {
            // if (city !== "" && city !== undefined) {
            if (
              products !== "" &&
              percentage !== "" &&
              dateFilter !== null &&
              city !== ""
            ) {
              const payload = {
                productId: products,
                percentage,
                logo: logoArr,
                startDate: dateFilter[0],
                endDate: dateFilter[1],
                city,
                // area,
                // payment
              };
              await dispatch(createSingleProductPromotion(payload));
              setDiscountLoading(false);
              makeFieldsEmpty();
              // setPreviews(true);

              // setProducts();
              // setLogo();
              // setDateFilter(null);
              // // setCity();
            } else if (
              // (dateFilter[0] === null || dateFilter[1] === null) &&
              dateFilter === null
            ) {
              swal("Fields Validation Error", "", "error");
              setDiscountLoading(false);
            }
          } else {
            swal("Fields Validation Error", "", "error");
            setDiscountLoading(false);
          }
        });
      } else {
        // if (city !== "" && city !== undefined) {
        if (
          products !== "" &&
          percentage !== "" &&
          dateFilter[0] !== "" &&
          dateFilter[1] !== "" &&
          city !== ""
        ) {
          const payload = {
            productId: products,
            percentage,
            logo: logoArr,
            startDate: dateFilter[0],
            endDate: dateFilter[1],
            city,
            // area,
            // payment
          };
          await dispatch(createSingleProductPromotion(payload));
          setDiscountLoading(false);
          makeFieldsEmpty();
          // setPreviews(true);
        } else {
          swal("Fields Validation Error", "", "error");
          setDiscountLoading(false);
        }
        // }
      }
    } else if (discountType === "all") {
      if (city !== "" && city !== undefined) {
        if (percentage && dateFilter[0] && dateFilter[1]) {
          const payload = {
            percentage,
            startDate: dateFilter[0],
            endDate: dateFilter[1],
            // payment,
            city,
          };
          await dispatch(createAllProductPromotion(payload));
          setDiscountLoading(false);
          makeFieldsEmpty();
          // setPreviews(true);
        } else {
          swal({
            icon: "error",
            title: "All fields required",
          });
          setDiscountLoading(false);
        }
      }
    }
  };
  const handleFreePromotion = async () => {
    setFreeLoading(true);
    let logoLength = freeLogo.length;
    let logoArr = [];
    if (logoLength > 0) {
      logo.map(async (item) => {
        const formData = new FormData();
        formData.append("image", item.originFileObj);
        const link = await dispatch(uploadSingleProductImage(formData));
        logoArr.push(link);
        if (logoArr.length === logoLength) {
          if (
            city !== "" &&
            freeQuantity !== "" &&
            buyQuantity !== "" &&
            products !== "" &&
            dateFilter[0] !== "" &&
            dateFilter[1] !== ""
          ) {
            const payload = {
              freeQuantity,
              buyQuantity,
              productId: products,
              logo: logoArr,
              startDate: dateFilter[0],
              endDate: dateFilter[1],
              city,
            };
            // console.log(payload);
            await dispatch(createFreeProductPromotion(payload));
            setFreeLoading(false);
            makeFieldsEmpty();
          } else {
            swal("Fields Validation Error", "", "error");
            setFreeLoading(false);
          }
        } else {
          swal("Fields Validation Error", "", "error");
          setFreeLoading(false);
        }
      });
    } else {
      swal("Fields Validation Error", "", "error");
      setFreeLoading(false);
    }
  };

  const handleChange = (info) => {
    setLogo(info.fileList);
  };
  const handleFreeChange = (info) => {
    setFreeLogo(info.fileList);
  };

  // console.log(dateFilter);
  return (
    <LayoutDesktop active="promotions">
      <Title level={2}>Promotions</Title>
      <div className="promotions">
        <Row>
          <Col xs={24} lg={11}>
            <div className="discount-promotion">
              <Title level={4}>Discount Promotion</Title>
              <Radio.Group
                name="radiogroup"
                defaultValue="single"
                size="large"
                onChange={onPromotionChange}
              >
                <Radio value="single">Discount Promotion for a Product</Radio>
                <Radio value="all">Discount Promotion for all Product</Radio>
              </Radio.Group>
              <br />
              <br />
              <Title level={4}>Choose Product %Off</Title>
              <Title level={5}>Country</Title>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Country"
                optionFilterProp="children"
                onChange={OnCountryChange}
                value={country}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allCountries &&
                  allCountries.map((country, index) => (
                    <Option value={country} key={index}>
                      {country}
                    </Option>
                  ))}
              </Select>

              <br />
              <br />
              <Title level={5}>Region</Title>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select City"
                optionFilterProp="children"
                value={city}
                onChange={(e) => setCity(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allCities &&
                  allCities.map((cityObj) => (
                    <Option value={cityObj.city} key={cityObj._id}>
                      {cityObj.city}
                    </Option>
                  ))}
              </Select>

              <br />
              <br />
              <Title level={5}>Select Product:</Title>
              {discountType === "single" || discountType === "" ? (
                <div className="select-product">
                  <div>
                    <Select
                      style={{ width: "70%" }}
                      onChange={handleProductChange}
                      value={products}
                    >
                      {allProducts &&
                        allProducts.map((product) => {
                          return (
                            <Option value={product._id}>{product.name}</Option>
                          );
                        })}
                    </Select>{" "}
                    <br />
                    <br />
                    <Title level={5}>Set Discount Percentage</Title>
                    <Input
                      type="number"
                      onChange={(e) => setPercentage(e.target.value)}
                      value={percentage}
                    />
                  </div>
                  <br />
                  <Title level={5}>Upload Logo</Title>
                  {/* <DropzoneArea
                    onChange={(files) => {
                      setPreviews(true);
                      setLogo(files);
                    }}
                    filesLimit={1}
                    acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                    showPreviewsInDropzone={preview}
                  /> */}
                  <Upload.Dragger
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={true}
                    maxCount={1}
                    accept="image/*"
                    onChange={handleChange}
                    fileList={logo}
                  >
                    {
                      <div>
                        {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
                        {<PlusOutlined />}
                        <div style={{ marginTop: 8 }}>
                          Upload Promotion Logo
                        </div>
                      </div>
                    }
                    {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                  </Upload.Dragger>
                </div>
              ) : (
                <div>
                  <div>
                    <Title level={5} type="secondary">
                      All Products
                    </Title>
                    <Title level={5}>Set Discount Percentage</Title>

                    <Input
                      type="number"
                      min={1}
                      style={{ width: "50%" }}
                      onChange={(e) => setPercentage(e.target.value)}
                      value={percentage}
                    />
                  </div>{" "}
                </div>
              )}
              <br />
              <br />
              <Title level={5}>Select Discount Period</Title>
              <RangePicker
                defaultValue={dateFilter}
                size="large"
                onChange={onPickerChange}
              />
              <Button
                type="primary"
                className="save-btn"
                onClick={createPromotion}
                loading={discountLoading}
                // onClick={makeFieldsEmpty}
              >
                Save
              </Button>
            </div>
          </Col>
          <Col xs={24} lg={13}>
            <div className="free-product">
              <Title level={4}>Free Product Promotion</Title>
              <Title level={5}>Country</Title>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Country"
                optionFilterProp="children"
                value={freeCountry}
                onChange={onFreeCountryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allCountries &&
                  allCountries.map((country, index) => (
                    <Option value={country} key={index}>
                      {country}
                    </Option>
                  ))}
              </Select>

              <br />
              <br />
              <Title level={5}>Region</Title>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select City"
                value={city}
                optionFilterProp="children"
                onChange={(e) => setCity(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allCities &&
                  allCities.map((cityObj) => (
                    <Option value={cityObj.city} key={cityObj._id}>
                      {cityObj.city}
                    </Option>
                  ))}
              </Select>
              <div>
                <br />
                <br />
                <Row>
                  <Col xs={24} md={8}>
                    <Title level={5}>Choose Products</Title>
                    <Select
                      style={{ width: "100%" }}
                      onChange={handleProductChange}
                      value={products}
                    >
                      {allProducts &&
                        allProducts.map((product) => {
                          // console.log(products);
                          return (
                            <Option value={product._id}>{product.name}</Option>
                          );
                        })}
                    </Select>{" "}
                  </Col>
                  <Col xs={24} md={8}>
                    <Title level={5}>Buy Quantity</Title>
                    <Input
                      type="number"
                      onChange={(e) => setBuyQuantity(e.target.value)}
                      value={buyQuantity}
                    />
                  </Col>
                  <Col xs={24} md={8}>
                    <Title level={5}>Free Quantity</Title>
                    <Input
                      type="number"
                      onChange={(e) => setFreeQuantity(e.target.value)}
                      value={freeQuantity}
                    />
                  </Col>
                </Row>

                <br />
                <Title level={5}>Upload Logo</Title>
                {/* <DropzoneArea
                  onChange={(files) => {
                    setPreviews(true);
                    setLogo(files);
                  }}
                  filesLimit={1}
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                  showPreviewsInDropzone={preview}
                /> */}
                <Upload.Dragger
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={true}
                  maxCount={1}
                  accept="image/*"
                  onChange={handleFreeChange}
                  fileList={freeLogo}
                >
                  {
                    <div>
                      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
                      {<PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Upload Promotion Logo</div>
                    </div>
                  }
                  {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                </Upload.Dragger>
                <br />

                <Title level={5}>Select Promotion Period</Title>

                <RangePicker size="large" onChange={onPickerChange} />
                <br />
                <Button
                  type="primary"
                  className="save-btn-2"
                  onClick={handleFreePromotion}
                  loading={freeLoading}
                >
                  save
                </Button>
              </div>
            </div>

            <div className="customer-promotion">
              <Title level={4}>Choose Customer Promotion</Title>
              <br />
              <Row gutter={[0, 10]}>
                <Col xs={24} md={10}>
                  <Select
                    showSearch
                    className="select-person"
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onSearch={onCustomerSearch}
                    onChange={(value) => setSelectUser(value)}
                    allowClear
                    value={selectUser}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {allUsers &&
                      allUsers.map((user) => {
                        return (
                          <Option key={user._id} value={user._id}>
                            {user.email}
                          </Option>
                        );
                      })}
                  </Select>
                </Col>
                <Col xs={24} md={7}>
                  <Input
                    type="number"
                    onChange={customerPromotionChange}
                    value={percentage}
                    min={1}
                    max={100}
                    className="percentage-inp"
                    suffix={<PercentageOutlined />}
                  />
                </Col>
                <Col xs={24} md={7}>
                  <Checkbox onChange={onCheckChange} className="free-check">
                    Free
                  </Checkbox>
                </Col>

                <Col xs={24} md={24}>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={async () => {
                      if (selectUser !== "" && percentage !== "") {
                        const payload = {
                          userId: selectUser,
                          percentage: percentage,
                          // productId
                        };
                        setCustomerLoading(true);
                        await dispatch(customerPromotion(payload));
                        setCustomerLoading(false);
                        setSelectUser("");
                        setPercentage("");
                      } else {
                        swal("Fields Validation Error", "", "error");
                        setCustomerLoading(false);
                      }
                    }}
                    loading={customerLoading}
                  >
                    save
                  </Button>
                </Col>
              </Row>

              <br />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div className="promotion-table">
              <Title level={4}>Current Promotions</Title>
              <Input
                placeholder="search by name"
                className="search-bar"
                allowClear
                onChange={(e) => searchTable(e)}
              />
              <Table
                columns={columns}
                dataSource={
                  allPromotions &&
                  allPromotions.map((promotion) => {
                    // console.log(promotion);
                    return {
                      key: promotion._id,
                      type: promotion.promotionType,
                      name: promotion.product ? promotion.product.name : "-",
                      cName: promotion.user ? promotion.user.name : "",
                      percentage: promotion.percentage
                        ? promotion.percentage
                        : "-",
                      startDate: promotion.startDate
                        ? moment(promotion.startDate).format("Do MMM YY")
                        : "-",
                      endDate: promotion.endDate
                        ? moment(promotion.endDate).format("Do MMM YY")
                        : "-",
                      action: (
                        <div>
                          <Button
                            type="primary"
                            onClick={() => {
                              setDelId(promotion._id);
                              swal({
                                title: "Are you sure?",
                                text: "Are you Sure? you will not be able to recover this Promotion!",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  if (
                                    promotion.promotionType ===
                                      "all-products" ||
                                    promotion.promotionType ===
                                      "customer-percentage"
                                  ) {
                                    setDeleteLoading(true);
                                    await dispatch(
                                      deletePromotion(promotion._id)
                                    );
                                    setDeleteLoading(false);
                                  } else {
                                    setDeleteLoading(true);

                                    await dispatch(
                                      deletePromotion(
                                        promotion._id,
                                        promotion.product._id
                                      )
                                    );
                                    setDeleteLoading(false);
                                  }
                                }
                              });
                            }}
                            loading={delId === promotion._id && deleteLoading}
                          >
                            Delete
                          </Button>{" "}
                        </div>
                      ),
                    };
                  })
                }
                onChange={onTableChange}
                scroll={{ x: 600 }}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </LayoutDesktop>
  );
};

export default Promotions;
