// const url = `https://qrun-server.herokuapp.com`;
const url = `https://scaneaterybackend.herokuapp.com`;
//hello
// const url = `http://localhost:8080`;

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export { url, headers };
