import Layout from "../layout/DashboardLayout";
import { Row, Col } from "antd";
import {
  Form,
  Input,
  Button,
  Radio,
  Space,
  Select,
  Upload,
  MessageArgsProps,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  BankOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { DropzoneArea } from "material-ui-dropzone";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import {
  MdRestaurant,
  MdSupervisorAccount,
  MdDialpad,
  MdPhone,
} from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import Maps from "../components/Maps";

import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  updatePassword,
  updateVendorInfo,
  uploadLogo,
  updatePrinter,
  getUserInfo,
  updateUserRegistrationStatus,
} from "../Redux";
import swal from "sweetalert";
import { GrCurrency } from "react-icons/gr";

const AccountSettings = () => {
  const { Title } = Typography;
  const { Option } = Select;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const vendorEmail = JSON.parse(localStorage.getItem("vendorEmail"));

  // Dropzone state
  const admin = useSelector((state) => state.AccountSettingsReducer.userInfo);
  console.log("41", admin);
  // const adminOrderStatus = useSelector(
  //   (state) => state.AccountSettingsReducer.adminOrderStatus
  // );
  const [logo, setLogo] = useState(null);
  const [userRegistration, setUserRegistration] = useState(null);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [printerLoading, setPrinterLoading] = useState(false);
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [preview, setPreview] = useState(true);
  const [coordinates, setCoordinates] = useState(null);
  const [defaultList, setDeafultList] = useState([]);

  // console.log(userRegistration);

  //   Printer module state

  // console.log(admin);
  // console.log(admin);

  const [printerModule, setPrinterModule] = useState(null);
  // DROPZONE HANDLE CHANGE
  //   Dropdown select countries
  const onChange = (value) => {};

  // vendor details
  const adminId = JSON.parse(localStorage.getItem("userId"));
  const userDetails = async (values) => {
    setInfoLoading(true);
    const {
      accountNumber,
      address,
      bankName,
      contactNumber,
      country,
      district,
      ownerName,
      restaurantName,
    } = values;

    if (
      accountNumber !== undefined &&
      address !== undefined &&
      bankName !== undefined &&
      contactNumber !== undefined &&
      country !== undefined &&
      district !== undefined &&
      ownerName !== undefined &&
      restaurantName !== undefined
    ) {
      const payload = {
        name: restaurantName,
        bankDetails: {
          ownerName: ownerName,
          bankName: bankName,
          accountNumber: accountNumber,
        },
        address: address,
        contactNumber: contactNumber,
        id: adminId,
        country,
        city: district,
      };
      dispatch(updateVendorInfo(payload));
      values = "";
      setInfoLoading(false);
    } else {
      swal({
        icon: "error",
        title: "Error",
        text: "Complete All fields",
      });
      setInfoLoading(false);
    }
  };

  const OnCountryChange = (value) => {
    dispatch(getCities(value));
  };

  // Form Action onfinish

  const onPasswordFinish = async (values) => {
    const { confirmPassword, newPassword, oldPassword } = values;
    setPasswordLoading(true);
    if (oldPassword === newPassword) {
      swal("", "Old and New Password can not be same", "error");
      setPasswordLoading(false);
    } else {
      if (newPassword === confirmPassword) {
        await dispatch(
          updatePassword(confirmPassword, newPassword, oldPassword)
        );
        setPasswordLoading(false);
      } else {
        swal("", "New and Confirm Password doesn't Match", "error");
        setPasswordLoading(false);
      }
    }
    form.resetFields();
  };

  const allCountries = useSelector(
    (state) => state.AccountSettingsReducer.countries
  );
  const allCities = useSelector((state) => state.AccountSettingsReducer.cities);
  //  gte countries
  useEffect(async () => {
    dispatch(getCountries());
    const module = await dispatch(getUserInfo());
    console.log(module.printerModule);
    setPrinterModule(module && module.printerModule ? 1 : 2);
    setCoordinates(module?.admin?.location);
    setUserRegistration(module && module.adminOrderStatus);
    // setUserRegistration(admin && admin.orderStatus ? admin.orderStatus : null);
  }, []);

  const SetLogoFunc = (file) => {
    setLogo(file[0]);
  };

  const uploadBanner = async () => {
    setLogoLoading(true);
    if (logo) {
      const formData = new FormData();
      formData.append("images", logo);
      await dispatch(uploadLogo(formData));
      setLogoLoading(false);
      setDeafultList([]);
      setPreview(false);
      setLogo(null);
    } else {
      swal("Please select logo first", "", "error");
      setLogoLoading(false);
    }
  };
  //   Printer radio value
  const updatePrinterModule = async () => {
    setPrinterLoading(true);
    const payload = {
      printerModule: printerModule === 1 ? true : false,
      id: adminId,
    };
    await dispatch(updatePrinter(payload));
    setPrinterLoading(false);
    // printerModule;
  };
  const handleUserRegistration = async () => {
    setRegistrationLoading(true);

    const payload = {
      status: userRegistration,
      adminId,
    };
    await dispatch(updateUserRegistrationStatus(payload));
    setRegistrationLoading(false);
  };

  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = (info) => {
    if (info.file.status === "removed") {
      setLogo(null);
      setDeafultList([]);
    } else {
      console.log(info);
      setLogo(info.file.originFileObj);
      setDeafultList(info.fileList);
    }
  };

  return (
    <Layout active="account settings">
      <div className="account-settings">
        <Row>
          <Col xs={24} lg={8}>
            {/* FORM UPDATE PASSWORD */}

            <div className="section">
              <Title level={4}>Change Password</Title>
              <Form
                name="change password"
                form={form}
                layout="vertical"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onPasswordFinish}
              >
                <Form.Item name="email" label="Email">
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    disabled
                    placeholder={vendorEmail}
                  />
                </Form.Item>
                <Form.Item
                  name="oldPassword"
                  label="Old Password"
                  preserve={false}
                  rules={[
                    {
                      required: true,
                      message: "Please input your old Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input new Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>

                <Form.Item className="btn-float-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={passwordLoading}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
              {/* Dropzone area */}
              <div className="dropzone-area">
                <Title level={5}>Upload Logo</Title>
                {/* <DropzoneArea
                  onChange={(files) => {
                    setPreview(true);
                    SetLogoFunc(files);
                  }}
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                  filesLimit={1}
                  showPreviewsInDropzone={preview}
                /> */}

                <Upload.Dragger
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={true}
                  maxCount={1}
                  accept="image/*"
                  onChange={handleChange}
                  fileList={defaultList}
                >
                  {
                    <div>
                      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
                      {<PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Upload Restaurant Logo</div>
                    </div>
                  }
                  {/* {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
                </Upload.Dragger>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="upload-btn"
                  onClick={uploadBanner}
                  loading={logoLoading}
                >
                  Upload
                </Button>
              </div>
            </div>

            {/* UPDATE INFO */}
            <div className="section">
              <Title level={4}>Update Info</Title>
              <Form
                name="update info"
                layout="vertical"
                className="login-form"
                fields={[
                  {
                    name: ["restaurantName"],
                    value: admin && admin.name,
                  },
                  {
                    name: ["country"],
                    value: admin && admin.country,
                  },
                  {
                    name: ["district"],
                    value: admin && admin.city,
                  },
                  {
                    name: ["currency"],
                    value: admin?.currency,
                  },
                  {
                    name: ["ownerName"],
                    value: admin?.bankDetails?.ownerName,
                  },
                  {
                    name: ["bankName"],
                    value: admin?.bankDetails?.bankName,
                  },
                  {
                    name: ["accountNumber"],
                    value: admin?.bankDetails?.accountNumber,
                  },
                  {
                    name: ["address"],
                    value: admin && admin.address,
                  },
                  {
                    name: ["contactNumber"],
                    value: admin && admin.contactNumber,
                  },
                ]}
                onFinish={userDetails}
              >
                <Form.Item name="restaurantName" label="Admin">
                  <Input
                    prefix={<MdRestaurant className="site-form-item-icon" />}
                    placeholder="Restaurant Name"
                  />
                </Form.Item>
                <Form.Item name="country" label="Country">
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    onChange={OnCountryChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allCountries &&
                      allCountries.map((country, index) => (
                        <Option value={country} key={index}>
                          {country}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="district" label="District">
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select City"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allCities &&
                      allCities.map((cityObj) => (
                        <Option value={cityObj.city} key={cityObj._id}>
                          {cityObj.city}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="currency" label="Currency">
                  <Input
                    prefix={<GrCurrency className="site-form-item-icon" />}
                    placeholder="your currency"
                    disabled
                  />
                </Form.Item>
                <Form.Item name="ownerName" label="Account Owner Name">
                  <Input
                    prefix={
                      <MdSupervisorAccount className="site-form-item-icon" />
                    }
                    placeholder="Owner"
                  />
                </Form.Item>
                <Form.Item name="bankName" label="Bank Name">
                  <Input
                    prefix={<BankOutlined className="site-form-item-icon" />}
                    placeholder="Bank ABC"
                  />
                </Form.Item>
                <Form.Item name="accountNumber" label="Account Number">
                  <Input
                    prefix={<MdDialpad className="site-form-item-icon" />}
                    placeholder="Acc Number"
                  />
                </Form.Item>
                <Form.Item name="address" label="Address">
                  <Input
                    prefix={
                      <FaRegAddressCard className="site-form-item-icon" />
                    }
                    placeholder="Address"
                  />
                </Form.Item>
                <Form.Item name="contactNumber" label="Contact Number">
                  <Input
                    prefix={<MdPhone className="site-form-item-icon" />}
                    placeholder="xxxx-xxxxxx"
                  />
                </Form.Item>

                <Form.Item className="btn-float-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={infoLoading}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
              {/* Printer Module */}
              <div>
                <Title level={2}>Printer Module</Title>
                <Radio.Group
                  onChange={(e) => setPrinterModule(e.target.value)}
                  value={printerModule}
                >
                  <Space direction="vertical">
                    <Radio value={1}>
                      <Title level={5}>Installed</Title>
                    </Radio>
                    <Radio value={2}>
                      {" "}
                      <Title level={5}>Not Installed</Title>
                    </Radio>
                  </Space>
                </Radio.Group>
                <div className="btn-float-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={updatePrinterModule}
                    loading={printerLoading}
                  >
                    Update
                  </Button>
                </div>
              </div>
              {/* user status */}
              <div>
                <Title level={2}>User Registration</Title>
                <Radio.Group
                  onChange={(e) => setUserRegistration(e.target.value)}
                  // defaultValue={admin && admin.orderStatus}
                  value={userRegistration}
                >
                  <Space direction="vertical">
                    <Radio value={"registered"}>
                      <Title level={5}>Only Registered Users</Title>
                    </Radio>
                    <Radio value={"no register"}>
                      {" "}
                      <Title level={5}>Non Registered Users</Title>
                    </Radio>
                  </Space>
                </Radio.Group>
                <div className="btn-float-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleUserRegistration}
                    loading={registrationLoading}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={16}>
            <div className=".leaflet-container">
              <Maps location={coordinates && coordinates} />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default AccountSettings;
