import {
  Col,
  Row,
  Typography,
  DatePicker,
  Input,
  Radio,
  Button,
  Table,
  Select,
} from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import LayoutDesktop from "../layout/DashboardLayout";
import {
  createExpenseAction,
  createProductExpenseAction,
  getAllProducts,
  getExpenseByDate,
  getExpenses,
  searchExpenses,
  getDailyOrders,
} from "../Redux";
import { getAllExtraProducts } from "../Redux/Actions/MenuActions";

const Accounting = () => {
  const { RangePicker } = DatePicker;
  const expenses = useSelector((state) => state.AccountingReducer.expenses);
  const allProducts = useSelector((state) => state.PromotionReducer.products);
  const allExtraProducts = useSelector(
    (state) => state.MenuReducer.extraProducts
  );

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => a.cost - b.cost,
        multiple: 3,
      },
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      sorter: {
        compare: (a, b) => a.cost - b.cost,
        multiple: 3,
      },
    },
    {
      title: "Stock",
      dataIndex: "quantity",
      sorter: {
        compare: (a, b) => a.quantity - b.quantity,
        multiple: 3,
      },
    },

    {
      title: "Monthly",
      dataIndex: "monthly",
    },
  ];

  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [cost, setCost] = useState("");
  const [productCost, setProductCost] = useState("");

  const [expenseFilter, setExpenseFilter] = useState(null);
  const dispatch = useDispatch();
  const [monthly, setMonthly] = useState(true);
  const adminId = JSON.parse(localStorage.getItem("userId"));
  // const [isData, setIsData] = useState(true);
  // const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [filteredExpenses, setFilteredExpenses] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [product, setProduct] = useState("");
  const [totalProducts, setTotalProducts] = useState();
  const [quantity, setQuantity] = useState("");

  const [dailyDates, setDailyDates] = useState(null);
  const [dailyOrders, setDailyOrders] = useState(null);
  const [orderLoading, setOrderLoading] = useState(false);

  let totalCost = 0;
  let totalRevenue = 0;

  const { Title } = Typography;

  const onDateChange = (e) => {
    setDate(e.target.value);
  };

  const searchTable = (e) => {
    dispatch(searchExpenses(e.target.value));
  };

  const onExpenseFilterChange = (e, string) => {
    setIsFiltered(false);
    console.log(string);
    setExpenseFilter(string);
  };
  const DailyFilterOnChange = (e, string) => {
    // setIsFiltered(false);
    if (string[0] !== "" && string[1] !== "") {
      console.log(string);
      let from = new Date(string[0]).toISOString();
      let to = new Date(string[1]).toISOString();
      setDailyDates([from, to]);
      // console.log("from ", from, "\nto ", to);
    } else {
      setDailyDates(null);
      setDailyOrders(null);
    }
  };

  const createExpense = async () => {
    setLoading(true);
    if (name !== "" && cost !== "" && date !== "") {
      const payload = {
        name,
        cost,
        monthly,
        date,
        adminId,
      };
      await dispatch(createExpenseAction(payload));
      setName("");
      setCost("");
      setLoading(false);
    } else {
      swal({
        icon: "error",
        title: "",
        text: "Name, Cost & Date is Required",
      });
      setLoading(false);
    }
  };

  const createProductExpense = async () => {
    setLoading1(true);
    if (
      product !== "" &&
      productCost !== "" &&
      date !== "" &&
      quantity !== ""
    ) {
      const payload = {
        name: product,
        cost: Number(productCost),
        monthly: false,
        firstTime: true,
        quantity: Number(quantity),
        date,
        adminId,
      };
      console.log(payload);
      await dispatch(createProductExpenseAction(payload));
      setName("");
      setCost("");
      setQuantity("");
      setLoading1(false);
    } else {
      swal({
        icon: "error",
        title: "",
        text: "input field must be correct!",
      });
      setLoading1(false);
    }
  };

  const handleGetExpenseByFilter = async () => {
    // console.log(expenseFilter);
    if (
      expenseFilter !== null &&
      expenseFilter[0] !== "" &&
      expenseFilter[1] !== ""
    ) {
      const payload = {
        adminId,
        start: expenseFilter[0],
        end: expenseFilter[1],
      };
      let filteredData = await dispatch(getExpenseByDate(payload));
      await setFilteredExpenses(filteredData?.expenses);
      await setFilteredOrders(filteredData?.orders);
      setIsFiltered(true);
    } else {
      swal("Please select date", "", "error");
    }
  };
  const handleProductChange = (product) => {
    setProduct(product);
  };
  const getDailyStats = async () => {
    let totalAmount = 0;
    let orderedQuantity = 0;
    let products = [];
    let extra = [];
    setOrderLoading(true);
    const orders = await dispatch(
      getDailyOrders({ adminId, start: dailyDates[0], end: dailyDates[1] })
    );
    if (orders.length > 0) {
      orders.map((order) => {
        totalAmount += order.amount;
        order.products.map((product) => {
          orderedQuantity += product.quantity;
          products.push({
            name: product.product.name,
            count: product.quantity,
          });
          product.extraProducts.map((ex) => {
            if (ex.active) {
              extra.push({
                name: ex.productName,
                count: product.quantity,
              });
            }
          });
        });
      });

      let tempName = [];
      let productResult = [];
      products.map((product) => {
        let index = tempName.indexOf(product.name);
        if (index === -1) {
          tempName.push(product.name);
          productResult.push({
            name: product.name,
            count: product.count,
          });
        } else {
          productResult[index].count += product.count;
        }
      });

      let tempNameExtra = [];
      let extraResult = [];
      extra.map((product) => {
        let index = tempNameExtra.indexOf(product.name);
        if (index === -1) {
          tempNameExtra.push(product.name);
          extraResult.push({
            name: product.name,
            count: product.count,
          });
        } else {
          extraResult[index].count += product.count;
        }
      });

      const result = {
        totalOrders: orders.length,
        totalAmount,
        totalQuantity: orderedQuantity,
        products: productResult,
        extra: extraResult,
      };
      // console.log(result);
      setOrderLoading(false);
      setDailyOrders(result);
    } else {
      setOrderLoading(false);
      swal("", "No Orders Found", "error");
    }
  };

  // console.log(dailyOrders);

  useEffect(() => {
    dispatch(getExpenses());
    dispatch(getAllProducts());
    dispatch(getAllExtraProducts());
  }, []);
  useEffect(() => {
    setTotalProducts([...allProducts, ...allExtraProducts]);
  }, [allProducts, allExtraProducts]);

  return (
    <LayoutDesktop active="accounting">
      <div className="accounting">
        <Title level={2}>Accounting</Title>
        <Row>
          <Col xs={24}>
            <div className="accounting-1st-section daily-stats">
              <Typography.Title level={4}>Daily Statistics</Typography.Title>
              <RangePicker
                showTime
                format="YYYY/MM/DD HH:mm:ss"
                size="large"
                onChange={DailyFilterOnChange}
              />
              <br />
              <Button
                type="primary"
                disabled={dailyDates ? false : true}
                onClick={getDailyStats}
                loading={orderLoading}
              >
                Get Stats
              </Button>
              {dailyOrders && (
                <div className="stat-section">
                  <p>Total Orders : {dailyOrders.totalOrders}</p>
                  <p>Total Products Ordered : {dailyOrders.totalQuantity}</p>
                  <p>Total Amount Earned : TL {dailyOrders.totalAmount}</p>
                  <p style={{ fontSize: "20px", margin: "10px 0" }}>
                    Products Ordered
                  </p>
                  {dailyOrders.products.map((product) => (
                    <>
                      <span>
                        {product.name} ({product.count} times)
                      </span>
                      <br />
                    </>
                  ))}
                  <p style={{ fontSize: "20px", margin: "10px 0" }}>
                    Extra Products Ordered
                  </p>
                  {dailyOrders.extra.map((product) => (
                    <>
                      <span>
                        {product.name} ({product.count} times)
                      </span>
                      <br />
                    </>
                  ))}
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} md={24} lg={7}>
            <div className="accounting-1st-section">
              <Title level={5}>Choose Date</Title>
              <Input
                onChange={onDateChange}
                size="large"
                type="date"
                className="date-picker-accounting"
              />
              <br />
              <br />
              <div className="expense-cost">
                <Title level={5}>Product Expense</Title>
                <Select
                  style={{ width: "100%" }}
                  onChange={handleProductChange}
                  value={product}
                >
                  {totalProducts &&
                    totalProducts.length > 0 &&
                    totalProducts.map((product) => {
                      return (
                        <Option value={product.name}>{product.name}</Option>
                      );
                    })}
                </Select>
                <Input
                  placeholder="Cost"
                  onChange={(e) => setProductCost(e.target.value)}
                  value={productCost}
                  type="number"
                />
                <Input
                  placeholder="Quantity"
                  onChange={(e) => setQuantity(e.target.value)}
                  value={quantity}
                  type="number"
                />
                <Title level={2}> </Title>
                <Button
                  className="expense-btn"
                  type="primary"
                  onClick={createProductExpense}
                  loading={loading1}
                >
                  Create Expense
                </Button>
                <br />

                <Title level={5}>Other Expense</Title>
                <Input
                  placeholder="Expense"
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  placeholder="Cost"
                  onChange={(e) => setCost(e.target.value)}
                />
              </div>
              <Title level={2}>
                {" "}
                <Radio onClick={() => setMonthly(!monthly)} checked={monthly}>
                  Monthly
                </Radio>
              </Title>
              <Button
                className="expense-btn"
                type="primary"
                onClick={createExpense}
                loading={loading}
              >
                Create Expense
              </Button>
            </div>
          </Col>
          <Col xs={24} md={24} lg={17}>
            <div className="accounting-2nd-section">
              <Title level={5}>Expense Table</Title>
              <div>
                <Input
                  placeholder="search by name"
                  className="search-bar"
                  allowClear
                  onChange={(e) => searchTable(e)}
                />
                <Table
                  columns={columns}
                  loading={expenses === null ? true : false}
                  dataSource={
                    expenses &&
                    expenses.map((item) => {
                      return {
                        key: item._id,
                        name: item.name,
                        cost: item.cost,
                        quantity: item.quantity ? item.quantity : "-",
                        date: moment(item.createdAt).format("Do MMM YY"),
                        monthly: item.quantity
                          ? "-"
                          : item.monthly
                          ? "true"
                          : "false",
                      };
                    })
                  }
                  // onChange={onTableChange}
                  scroll={{ x: 600 }}
                  pagination={{
                    defaultPageSize: 7,
                    showSizeChanger: true,
                    pageSizeOptions: ["7", "20", "30"],
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="accounting-3rd-section">
              <Title level={3}>Find Expenses By Date</Title>

              <RangePicker size="large" onChange={onExpenseFilterChange} />
              <br />
              <br />
              <Button type="primary" onClick={handleGetExpenseByFilter}>
                Apply Filter
              </Button>
            </div>
          </Col>
        </Row>
        {isFiltered ? (
          <div className="total-accounting">
            <Title level={4}>Orders</Title>
            {filteredOrders?.map((order) => {
              totalCost += order.cost;
              totalRevenue = +order.revenue;
              return (
                <div key={order._id}>
                  <p className="filter-text">
                    Total Number Of Orders: {order.totalOrders}
                  </p>
                  <p className="filter-text">
                    total Revenue generated: {order.revenue}
                  </p>
                  <p className="filter-text">Total orders Cost: {order.cost}</p>
                </div>
              );
            })}
            <Title level={4}>Expenses</Title>
            {filteredExpenses?.map((expense) => {
              totalCost += expense.cost;
              return (
                <div key={expense._id}>
                  <p className="filter-text">
                    total running expenses Cost: {expense.cost}
                  </p>
                  <p className="filter-text">
                    Total Number Of Expenses: {expense.totalNoExpense}
                  </p>
                </div>
              );
            })}
            <Title level={4}>Total Expenses</Title>
            <p className="filter-text">Total Expenses: {totalCost}</p>

            <Title level={4}>Total Profit</Title>
            <p className="filter-text">
              Total Profit: {totalRevenue - totalCost}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </LayoutDesktop>
  );
};

export default Accounting;
