import { useState } from "react";
import { Button, Select, Typography, Modal, Row, Col, Tag } from "antd";
import { FaTrash } from "react-icons/fa";
import moment from "moment";
import { deleteLiveOrderProduct } from "../../Redux";
import { useDispatch } from "react-redux";

const LiveOrderModal = ({ orders }) => {
  const { 
    //Option 
  } = Select;
  const { Title, Text } = Typography;
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();

  const modalChange = (modalVisible) => {
    setModalVisible(modalVisible);
  };
  const filterOrders = orders.products.filter((order) => {
    return !order.served;
  });

  return (
    <div>
      <Button type="primary" onClick={() => modalChange(true)}>
        View
      </Button>
      <Modal
        title="Order Details"
        centered
        visible={modalVisible}
        onOk={() => modalChange(false)}
        onCancel={() => modalChange(false)}
        width={700}
      >
        <div>
          <Row>
            <Col span={12}>
              <Title level={5}>
                Customer Name :{" "}
                <Text type="secondary">
                  {orders.userName ? orders.userName : orders.user.name}
                </Text>
              </Title>
            </Col>
            <Col span={12}>
              <Title level={5}>
                Customer Contact :{" "}
                <Text type="secondary">
                  {orders.user ? orders.user.mobile : "-"}
                </Text>
              </Title>
            </Col>
            <Col span={12}>
              <Title level={5}>
                Total Bill :{" "}
                <Text type="secondary">
                  {/* {orders.user ? orders.user.amount : ""} */}
                  {orders.amount}
                </Text>
              </Title>
            </Col>
            <Col span={12}>
              <Title level={5}>
                Order Time:{" "}
                <Text type="secondary">
                  {moment(orders.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                </Text>
              </Title>
            </Col>
          </Row>
          {filterOrders && filterOrders.length > 0
            ? filterOrders.map(
                (
                  order,
                  i
                  //   { quantity, product, extraProducts, allergicNames }
                ) => {
                  return (
                    <div
                      className="white-bg-inside-modal live-titles"
                      key={order._id}
                    >
                      <Row style={{ position: "relative" }}>
                        <Button
                          shape="circle"
                          type="primary"
                          style={{
                            position: "absolute",
                            right: -30,
                            top: -30,
                          }}
                          onClick={() => {
                            const payload = {
                              orderId: orders._id,
                              index: i,
                            };
                            dispatch(deleteLiveOrderProduct(payload));
                          }}
                        >
                          <FaTrash />
                        </Button>
                        <Col span={24}>
                          <Title level={5}>
                            Product Name :{" "}
                            <Text type="secondary">{order.product.name}</Text>
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Title level={5}>
                            Quantity:{" "}
                            <Text type="secondary">{order.quantity}</Text>
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Title level={5}>
                            Per Price:{" "}
                            <Text type="secondary">{order.product.price}</Text>
                          </Title>
                        </Col>
                        {order.exclude && order.exclude.length > 0 ? (
                          <Col span={24}>
                            <Title level={5}>
                              Excluded :{" "}
                              {order.exclude.map((e, i) => {
                                return (
                                  <Tag
                                    style={{
                                      padding: "3px 7px",
                                      fontSize: "0.8em",
                                    }}
                                    key={i}
                                    color="#F0A9D4"
                                  >
                                    {e}{" "}
                                  </Tag>
                                );
                              })}
                            </Title>
                          </Col>
                        ) : (
                          ""
                        )}
                        {order.include && order.include.length > 0 ? (
                          <Col span={24}>
                            <Title level={5}>
                              Included :{" "}
                              {order.include.map((e, i) => {
                                return (
                                  <Tag
                                    style={{
                                      padding: "3px 7px",
                                      fontSize: "0.8em",
                                    }}
                                    key={i}
                                    color="#F0A9D4"
                                  >
                                    {e}{" "}
                                  </Tag>
                                );
                              })}
                            </Title>
                          </Col>
                        ) : (
                          ""
                        )}
                        {order.extraProducts &&
                        order.extraProducts.length > 0 ? (
                          <Col span={24}>
                            <Title level={5}>
                              Extra Products :{" "}
                              {order.extraProducts.map((ep, i) => {
                                return ep.active ? (
                                  <Tag
                                    style={{
                                      padding: "3px 7px",
                                      fontSize: "0.8em",
                                    }}
                                    key={i}
                                    color="#F0A9D4"
                                  >
                                    {ep.details.name}{" "}
                                  </Tag>
                                ) : (
                                  "-"
                                );
                              })}
                            </Title>
                          </Col>
                        ) : (
                          ""
                        )}
                        {order.allergicNames &&
                        order.allergicNames.length > 0 ? (
                          <Col span={24}>
                            <Title level={5}>
                              Allergies :{" "}
                              {order.allergicNames.map((allergy, i) => {
                                return allergy.active ? (
                                  <Tag
                                    style={{
                                      padding: "3px 7px",
                                      fontSize: "0.8em",
                                    }}
                                    key={i}
                                    color="#F0A9D4"
                                  >
                                    {allergy.allergicName}{" "}
                                  </Tag>
                                ) : (
                                  "-"
                                );
                              })}
                            </Title>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  );
                }
              )
            : ""}
        </div>
      </Modal>
    </div>
  );
};

export default LiveOrderModal;
