import { useState } from "react";
import { Layout, Dropdown, Avatar, Menu } from "antd";
import LayoutMenu from "./LayoutMenu";
import qrlogo from "../assets/qrlogo.png";
import {
  UserOutlined,
 // SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Header, Content, Sider } = Layout;

const CollapseLayout = ({ active, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("vendorEmail");
    localStorage.removeItem("role");
    localStorage.removeItem("verified");
    history.replace("/login");
  };

  const menu = (
    <Menu>
      {/* <Menu.Item key="0" icon={<SettingOutlined />}>
        <Link to="/user/settings">Account Settings</Link>
      </Menu.Item> */}
      <Menu.Item key="1" icon={<LogoutOutlined />} onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="layoutDesktop" style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        // style={{
        //   overflow: "auto",
        //   height: "100vh",
        //   position: "fixed",
        //   left: 0,
        // }}
      >
        <div className="logo">
          <img
            src={qrlogo}
            alt="qrlogo"
            height="55px"
            width="auto"
            className="logoImg"
          />
        </div>
        <LayoutMenu active={active} />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background">
          <p>QR TREAT</p>
          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Avatar
                style={{ backgroundColor: "#f0a7d3" }}
                icon={<UserOutlined />}
              />
            </a>
          </Dropdown>
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <div style={{ padding: 24, minHeight: 360 }}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CollapseLayout;
