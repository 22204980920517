import * as CustomerTypes from "../Types/CustomerTypes";

const initialState = {
  customers: null,
  customerDetails: null,
};

var customerCopy;

const customersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CustomerTypes.GET_CUSTOMERS:
      customerCopy = payload;
      return {
        ...state,
        customers: payload,
      };
    case CustomerTypes.GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: payload,
      };
    case CustomerTypes.SEARCH_CUSTOMER:
      const searchCustomer = payload.toLowerCase();
      const newCustomer = customerCopy.filter((obj) => {
        return obj.name.toLowerCase().includes(searchCustomer);
      });
      return {
        ...state,
        customers: newCustomer,
      };
    case CustomerTypes.FILTER_CUSTOMER:
      const regCustomer = state.customers.filter((obj) => {
        return obj.email;
      });
      return {
        ...state,
        customers: regCustomer,
      };
    case CustomerTypes.DEFAULT_CUSTOMER:
      return {
        ...state,
        customers: customerCopy,
      };
    default:
      return state;
  }
};

export default customersReducer;
