import axios from "axios";
import { url, headers } from "../../BaseUrl";
import * as MenuTypes from "../Types/MenuTypes";
import swal from "sweetalert";
//import { Menu } from "antd";
// import sharp from "sharp";

const adminId = JSON.parse(localStorage.getItem("userId"));

// incomplete cors policy error
export const addCategory = (formData, name) => async (dispatch) => {
  try {
    await axios
      .post(
        `https://us-central1-qrun-server.cloudfunctions.net/uploadBanner`,
        formData,
        {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
        }
      )
      .then((res) => {
        let imageUrl = res.data.imageUrl;
        dispatch(getAllCategories());

        axios({
          url: `${url}/admin/add-category`,
          method: "POST",
          data: {
            name: name,
            adminId,
            img: imageUrl,
          },
          headers: headers,
        });
      });

    dispatch(getAllCategories());

    swal({
      icon: "success",
      title: "success",
      text: "Category Created Successfully!",
    });
  } catch (err) {
    swal({
      icon: "error",
      title: "Error",
      text: "Error updating details",
    });
  }
};

export const getAllCategories = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/admin/get-categories/${adminId}`, {
      headers: headers,
    });
    if (res) {
      console.log(res.data);
      dispatch({
        type: MenuTypes.GET_CATEGORIES,
        payload: res.data.categories,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllProducts = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/admin/get-products/${adminId}`, {
      headers: headers,
    });
    if (res) {
      dispatch({
        type: MenuTypes.GET_PRODUCTS,
        payload: res.data.products,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAllExtraProducts = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/admin/get-extra-product/${adminId}`, {
      headers: headers,
    });
    if (res) {
      dispatch({
        type: MenuTypes.GET_EXTRA_PRODUCTS,
        payload: res.data.extraProducts,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const searchProduct = (payload, type) => async (dispatch) => {
  if (payload === "") {
    dispatch(getAllProducts());
    dispatch(getAllExtraProducts());
  } else {
    if (type === "product") {
      dispatch({
        type: MenuTypes.SEARCH_PRODUCT,
        payload: payload,
      });
    }
    if (type === "extraproduct") {
      dispatch({
        type: MenuTypes.SEARCH_EXTRA_PRODUCT,
        payload: payload,
      });
    }
  }
};

export const uploadSingleProductImage = (formData) => async (dispatch) => {
  try {
    // sharp(image)
    //   .resize(255, 150)
    //   .toFile("output.png")
    //   .then((outputImage) => {
    //     console.log(outputImage);
    //     // output.png is a 200 pixels wide and 300 pixels high image
    //     // containing a nearest-neighbour scaled version
    //     // contained within the north-east corner of a semi-transparent white canvas
    //   });
    const res = await axios.post(
      "https://us-central1-qrun-server.cloudfunctions.net/uploadBanner",
      formData,
      {
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
        },
      }
    );
    return res.data.imageUrl;
  } catch (error) {
    console.log(error);
  }
};

export const addProduct = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/admin/add-product`, payload, {
      headers: headers,
    });
    if (res) {
      dispatch(getAllProducts());
      dispatch(getAllCategories());
    }
    swal({
      icon: "success",
      title: "Created",
      text: "Product Created Successfully",
    });
  } catch (error) {
    console.log(error);
    swal({
      icon: "error",
    });
  }
};
export const addExtraProduct = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/admin/add-extra-product`, payload, {
      headers: headers,
    });
    if (res) {
      await dispatch(getAllExtraProducts());
      swal({
        icon: "success",
        title: "Created",
        text: "Extra Product Created Successfully",
      });
    }
  } catch (error) {
    swal({
      icon: "error",
    });
  }
};

export const updateProduct = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/edit-product`, payload, {
      headers: headers,
    });
    if (res) {
      dispatch(getAllProducts());
    }
    swal({
      icon: "success",
      title: "Updated",
      text: "Product updated Successfully",
    });
  } catch (error) {
    console.log(error);
    swal({
      icon: "error",
      title: error?.response?.data?.message || "Server Error",
    });
  }
};
export const updateExtraProduct = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/admin/update-extra-product`, payload, {
      headers: headers,
    });
    if (res) {
      console.log(res.data);
      dispatch(getAllExtraProducts());
    }
    swal({
      icon: "success",
      title: "Updated",
      text: "Extra Product updated Successfully",
    });
  } catch (error) {
    swal({
      icon: "error",
      title: "please try again late",
    });
  }
};
export const deleteProduct = (productId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/admin/delete-product`,
      { productId },
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getAllProducts());
    }
    swal({
      icon: "success",
      title: "Deleted",
      text: "Product Deleted",
    });
  } catch (error) {
    swal({
      icon: "error",
      title: "please try again late",
    });
  }
};
export const updateProductPosition = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/admin/set-product-order`, payload, {
      headers: headers,
    });
    if (res) {
      console.log(res.data);
      dispatch(getAllProducts());
    }
    swal({
      icon: "success",
      title: res.data.message,
    });
  } catch (error) {
    swal({
      icon: "error",
      title: "please try again late",
    });
  }
};
export const updateExtraProductPosition = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/admin/set-extraproduct-order`,
      payload,
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getAllExtraProducts());
    }
    swal({
      icon: "success",
      title: res.data.message,
    });
  } catch (error) {
    swal({
      icon: "error",
      title: "please try again late",
    });
  }
};
export const updateCategoryPosition = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/set-category-order`, payload, {
      headers: headers,
    });
    if (res) {
      dispatch(getAllCategories());
    }
    swal({
      icon: "success",
      title: res.data.message,
    });
  } catch (error) {
    swal({
      icon: "error",
      title: "please try again late",
    });
  }
};
export const deleteExtraProduct = (productId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/admin/delete-extra-product-new`,
      { productId },
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getAllExtraProducts());
    }
    swal({
      icon: "success",
      title: "Deleted",
      text: "Product Deleted",
    });
  } catch (error) {
    swal({
      icon: "error",
      title: "please try again late",
    });
  }
};

export const updateCategory = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/admin/edit-category/`, payload, {
      headers: headers,
    });
    if (res) {
      console.log(res.data);
      dispatch(getAllCategories());
      swal(res.data.message, "", "success");
    }
  } catch (error) {
    swal(error?.response?.data?.message, "", "error");
  }
};
