import { Table, Select, Typography, Button } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  declineOrder,
  deleteLiveOrderProduct,
  printCancelledOrder,
} from "../../Redux";
import swal from "sweetalert";
import { getTableOrders } from "../../Redux/Actions/TableSettingsActions";
import OrdersModals from "../Modals/TableSettings/OrdersModals";

const Orders = ({ tableId }) => {
  // console.log(tableId);
  const columns = [
    {
      title: "Order Name",
      dataIndex: "order",
      key: "order",
      sorter: (a, b) => a.order - b.order,
      //   sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      ellipsis: true,
    },
    {
      title: "Unit Price",
      dataIndex: "unit",
      key: "unit",

      sorter: (a, b) => a.unit - b.unit,
      ellipsis: true,
    },
    {
      title: "Total Price",
      dataIndex: "total",
      key: "total",

      sorter: (a, b) => a.total - b.total,
      //   sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",

      sorter: (a, b) => a.customer - b.customer,
      //   sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",

      //   filteredValue: filteredInfo.address || null,
    },
    {
      title: "Cancel",
      dataIndex: "cancel",
      key: "cancel",

      //   filteredValue: filteredInfo.address || null,
    },
  ];
  const {
    //Option
  } = Select;
  const { Title } = Typography;
  const dispatch = useDispatch();

  // Show Entries
  function handleChange(value) {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  }

  // Table Data
  useEffect(() => {
    dispatch(getTableOrders(tableId));
  }, []);

  const ordersList = useSelector(
    (state) => state.TableSettingsReducer.tableOrders
  );
  console.log(ordersList);
  let orders = [];

  ordersList &&
    ordersList.map((details) => {
      details.products.map((item, i) => {
        orders.push({
          key: item._id,
          order: item.product.name,
          quantity: item.quantity,
          unit: item.product.price,
          total: parseInt(item.product.price) * item.quantity,
          customer: details.userName ? details.userName : details.user.name,
          action: <OrdersModals details={details} />,
          cancel: (
            <Button
              type="primary"
              onClick={() => {
                swal({
                  title: "This Order Is going to be cancelled",
                  text: "Do you want to print it?",
                  icon: "warning",
                  buttons: {
                    Cancel: "Cancel",
                    Only: "Only Delete",
                    Print: "Print & Delete",
                  },
                }).then(async (value) => {
                  const payload = {
                    orderId: details._id,
                    index: i,
                  };
                  if (value === "Only") {
                    dispatch(deleteLiveOrderProduct(payload, tableId));
                    dispatch(declineOrder(details._id, "only"));
                  } else if (value === "Print") {
                    dispatch(deleteLiveOrderProduct(payload, tableId));
                    dispatch(declineOrder(details._id, "print"));
                    dispatch(printCancelledOrder(details._id));
                  }
                });
              }}
            >
              Cancel
            </Button>
          ),
        });
      });
    });

  // orders = data;

  return (
    <div className="customers-list">
      <Title level={2}>Orders</Title>

      <div className="table-container">
        <Table
          columns={columns}
          // loading={orders && orders.length > 0 ? false : true}
          dataSource={orders}
          onChange={handleChange}
          scroll={{ x: 600 }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
        />
      </div>
    </div>
  );
};

export default Orders;
