import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  //  Circle,
  Tooltip,
} from "react-leaflet";
import { useMemo, useState, useRef } from "react";
import { Button } from "antd";
import axios from "axios";

import { getUserInfo } from "../Redux";
import { useEffect } from "react";
import swal from "sweetalert";
import { url } from "../BaseUrl";
import { useDispatch } from "react-redux";

// import logo from "../assets/logo.png";

const Maps = (props) => {
  const dispatch = useDispatch();
  // var greenIcon = new Icon({
  //   iconUrl: logo,
  //   iconSize: [60, 60], // size of the icon
  //   // shadowSize: [50, 64], // size of the shadow
  //   // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  //   // shadowAnchor: [4, 62], // the same for the shadow
  //   // popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  // });
  // L.marker([51.5, -0.09], { icon: greenIcon }).addTo(map);

  // // const position =;
  // const [position, setPosition] = useState([0, 0]);
  // const refmarker = useRef(null);

  // const [acc, setAcc] = useState(0);

  // const [showMyLocation, setShowMyLocation] = useState(false);
  // const [liveLocation, setLiveLocation] = useState([]);
  // useEffect(() => {
  //   if (!props.location) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setAcc(position.coords.accuracy);

  //         setPosition([position.coords.latitude, position.coords.longitude]);
  //       },
  //       (e) => {},
  //       {
  //         enableHighAccuracy: true,
  //         timeout: 5000,
  //         maximumAge: 0,
  //       }
  //     );
  //   } else {
  //     setPosition(props.location);
  //   }
  // }, []);

  // const getLiveLocation = () => {
  //   if (!showMyLocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLiveLocation([
  //           position.coords.latitude,
  //           position.coords.longitude,
  //         ]);
  //         setShowMyLocation(true);
  //       },
  //       (e) => {},
  //       {
  //         enableHighAccuracy: true,
  //         timeout: 5000,
  //         maximumAge: 0,
  //       }
  //     );
  //   } else {
  //     setShowMyLocation(false);
  //   }
  // };

  // const eventHandlers = useMemo(
  //   () => ({
  //     dragend() {
  //       const marker = refmarker.current;
  //       if (marker != null) {
  //         //   setPosition()
  //         let lat = marker.getLatLng().lat;
  //         let lng = marker.getLatLng().lng;
  //         setPosition([lat, lng]);
  //       }
  //     },
  //   }),
  //   []
  // );

  // const updateLoction = () => {
  //   let adminId = JSON.parse(localStorage.getItem("userId"));

  //   axios({
  //     url: `${url}/admin/update-location`,
  //     method: "POST",
  //     data: {
  //       adminId,
  //       lat: showMyLocation ? liveLocation[0] : position[0],
  //       lng: showMyLocation ? liveLocation[1] : position[1],
  //     },
  //   })
  //     .then((res) => {
  //       swal({
  //         icon: "success",
  //         title: "Successfull",
  //         text: "Location Updated",
  //       });
  //     })
  //     .catch((err) => {
  //       swal({
  //         icon: "error",
  //         title: "Error",
  //         text: "Error Updating Location",
  //       });
  //     });
  // };

  // return (
  //   // <div>
  //   //   {/* <link
  //   //     rel="stylesheet"
  //   //     href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css"
  //   //   /> */}

  //   //   <MapContainer center={[24.860735, 67.001137]} zoom={13}>
  //   //     <TileLayer
  //   //       attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  //   //       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  //   //     />
  //   //     <Marker position={[24.860735, 67.001137]}>
  //   //       <Popup>
  //   //         A pretty CSS3 popup. <br /> Easily customizable.
  //   //       </Popup>
  //   //     </Marker>
  //   //   </MapContainer>
  //   // </div>
  //   <div>
  //     <MapContainer
  //       dragging={true}
  //       key={Math.random() * 100 + "as9di214b"}
  //       center={position}
  //       zoom={30}
  //       scrollWheelZoom={true}
  //     >
  //       <TileLayer
  //         attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  //       />
  //       <Marker
  //         draggable
  //         ref={refmarker}
  //         riseOnHover={true}
  //         eventHandlers={eventHandlers}
  //         position={position}
  //       >
  //         <Popup>Drag Onto your Resturant</Popup>
  //       </Marker>

  //       {showMyLocation && (
  //         <Marker position={liveLocation}>
  //           <Tooltip direction="bottom" offset={[0, 20]} opacity={1} permanent>
  //             YOUR LIVE LOCATION
  //           </Tooltip>
  //         </Marker>
  //       )}
  //     </MapContainer>

  //     <Button
  //       style={{
  //         float: "right",
  //         margin: 20,
  //         backgroundColor: "#1F2836",
  //         color: "white",
  //         textTransform: "capitalize",
  //       }}
  //       onClick={updateLoction}
  //     >
  //       Update Location
  //     </Button>

  //     <Button
  //       style={{
  //         float: "right",
  //         margin: 20,
  //         backgroundColor: "#1F2836",
  //         color: "white",
  //         textTransform: "capitalize",
  //       }}
  //       onClick={() => {
  //         getLiveLocation();
  //       }}
  //     >
  //       {!showMyLocation ? "Live Location" : "Disable Live Location"}
  //     </Button>
  //   </div>
  // );
  const [position, setPosition] = useState(null);
  const refmarker = useRef(null);

  // const [acc, setAcc] = useState(0);

  const [showMyLocation, setShowMyLocation] = useState(false);
  const [liveLocation, setLiveLocation] = useState([]);

  console.log("Default ", position);
  console.log("Props ", props.location);
  useEffect(() => {
    // if (!props.location) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       console.log(position.coords);
    //       // setAcc(position.coords.accuracy);

    //       setPosition([position.coords.latitude, position.coords.longitude]);
    //     },
    //     (e) => {
    //       console.log(e);
    //     },
    //     {
    //       enableHighAccuracy: true,
    //       timeout: 5000,
    //       maximumAge: 0,
    //     }
    //   );
    // } else {
    //   console.log(props.location);
    //   // setPosition(props.location);
    //   setPosition([props?.location?.lat, props?.location?.lng]);
    // }

    if (props?.location) {
      setPosition([props?.location?.lat, props?.location?.lng]);
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords);
          // setAcc(position.coords.accuracy);

          setPosition([position.coords.latitude, position.coords.longitude]);
        },
        (e) => {
          console.log(e);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }, []);

  const getLiveLocation = () => {
    if (!showMyLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLiveLocation([
            position.coords.latitude,
            position.coords.longitude,
          ]);
          setShowMyLocation(true);
        },
        (e) => {
          console.log(e);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setShowMyLocation(false);
    }
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = refmarker.current;
        if (marker != null) {
          //   setPosition()
          console.log(marker.getLatLng());
          let lat = marker.getLatLng().lat;
          let lng = marker.getLatLng().lng;
          setPosition([lat, lng]);
        }
      },
    }),
    []
  );

  const updateLoction = () => {
    let adminId = JSON.parse(localStorage.getItem("userId"));
    console.log("274", showMyLocation, position);

    let payload = {
      adminId,
      lat: showMyLocation ? liveLocation[0] : position[0],
      lng: showMyLocation ? liveLocation[1] : position[1],
    };
    console.log("sending payload ", payload);
    axios({
      url: `${url}/admin/update-location`,
      method: "POST",
      data: payload,
    })
      .then(async (res) => {
        console.log(res);
        const admin = await dispatch(getUserInfo());
        setPosition([admin?.admin?.location?.lat, admin?.admin?.location?.lng]);
        swal("Location Updated", "", "success");
      })
      .catch((err) => {
        console.log(err);
        swal("Error Updating Location", "", "error");
      });
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "70%", width: "100%" }}>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css"
        />
        <MapContainer
          dragging={true}
          key={Math.random() * 100 + "as9di214b"}
          center={position && position}
          zoom={30}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {position && (
            <Marker
              draggable={true}
              ref={refmarker}
              riseOnHover={true}
              eventHandlers={eventHandlers}
              position={position && position}
              // icon={greenIcon}
            >
              <Popup>Drag Onto your Resturant</Popup>
            </Marker>
          )}

          {showMyLocation && (
            <Marker position={liveLocation}>
              <Tooltip
                direction="bottom"
                offset={[0, 20]}
                opacity={1}
                permanent
              >
                YOUR LIVE LOCATION
              </Tooltip>
            </Marker>
          )}
        </MapContainer>

        <Button
          style={{
            float: "right",
            margin: 20,
            backgroundColor: "#1F2836",
            color: "white",
            textTransform: "capitalize",
          }}
          onClick={updateLoction}
        >
          Update Location
        </Button>

        <Button
          style={{
            float: "right",
            margin: 20,
            backgroundColor: "#1F2836",
            color: "white",
            textTransform: "capitalize",
          }}
          onClick={() => {
            getLiveLocation();
          }}
        >
          {!showMyLocation ? "Live Location" : "Disable Live Location"}
        </Button>
      </div>
    </div>
  );
};

export default Maps;
