import { Modal, Tag, Button, Typography, Input } from "antd";
import { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { FieldTimeOutlined } from "@ant-design/icons";

const MenuModal = ({ product }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [gallery, setGallery] = useState(null);

  const showModal = () => {
    setIsModalVisible(true);
    const logos = [];
    if (product.logo && product.logos.length > 0) {
      logos.push(product.logo, ...product.logos);
    } else {
      logos.push(product.logo);
    }
    const logoGallery = logos.map((logo) => {
      return {
        original: `${logo}`,
        thumbnail: `${logo}`,
        // originalHeight: 300,
      };
    });
    setGallery(logoGallery);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { Title } = Typography;
  const { TextArea } = Input;
  return (
    <div>
      <Button type="primary" onClick={showModal}>
        View
      </Button>
      <Modal
        title="Product Overview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        // centered
        // width={700}
        footer={false}
      >
        <div>
          {gallery && <ImageGallery items={gallery} />}
          <br />
          <br />
          <Title level={4}>Name: {product.name}</Title>

          <Title level={4}>
            Price:{" "}
            <span style={{ fontWeight: "bold", color: "#17b978" }}>
              TL {product.price}
            </span>
          </Title>

          <Title level={4}>Description: </Title>
          <Title level={5}>{product.description}</Title>
          {/* <TextArea
            placeholder={product.description}
            disabled
            style={{ background: "white", fontSize: "1.2em" }}
          />
          <br /> */}
          {product?.ingrediants?.length > 0 && (
            <Title level={4}>Ingredients:</Title>
          )}

          {product.ingrediants.map((ingredient) => {
            return (
              <Tag
                style={{
                  fontSize: "1.2em",
                  padding: "8px",
                  borderRadius: "10px",
                }}
                color="gold"
              >
                ${ingredient}
              </Tag>
            );
          })}
          <br />
          <br />
          {product?.allergic?.length > 0 && <Title level={4}>Allergies:</Title>}
          {product.allergic.map((allergy) => {
            return (
              <Tag
                style={{
                  fontSize: "1.2em",
                  padding: "8px",
                  borderRadius: "10px",
                }}
                color="magenta"
              >
                ${allergy}
              </Tag>
            );
          })}
          <br />
          <br />
          {product?.waitTime && (
            <Title
              level={4}
              style={{
                marginBottom: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FieldTimeOutlined style={{ marginRight: "5px" }} /> Wait Time:{" "}
              {product.waitTime}
            </Title>
          )}
          {product?.calories && (
            <Title level={4}>Calories: {product.calories}</Title>
          )}

          {product?.extraProducts?.length > 0 && (
            <Title level={4}>Extra Products:</Title>
          )}
          {product.extraProducts.map((ep) => {
            return (
              <Tag
                style={{
                  fontSize: "1.2em",
                  padding: "8px",
                  borderRadius: "10px",
                }}
                color="success"
              >
                ${ep.name}
              </Tag>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default MenuModal;
