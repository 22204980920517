import * as PromotionTypes from "../Types/PromotionTypes";

const initialState = {
  products: [],
  users: [],
  promotions: [],
};

var promotionCopy;
const promotionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PromotionTypes.GET_PROMOTION_PRODUCTS:
      return {
        ...state,
        products: payload,
      };
    case PromotionTypes.GET_USERS:
      return {
        ...state,
        users: payload,
      };
    case PromotionTypes.GET_PROMOTIONS:
      promotionCopy = payload;
      return {
        ...state,
        promotions: payload,
      };
    case PromotionTypes.SEARCH_PROMOTION:
      const searchPromotion = payload.toLowerCase();
      const newPromotion = promotionCopy.filter((obj) => {
        return obj.promotionType.toLowerCase().includes(searchPromotion);
      });
      return {
        ...state,
        promotions: newPromotion,
      };

    default:
      return state;
  }
};

export default promotionReducer;
