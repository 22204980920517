import { Typography, Form, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { forgetPassword } from "../Redux/Actions/VendorActions";
import { useDispatch } from "react-redux";
import { useState } from "react";

const ForgetPassword = () => {
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = async (values) => {
    setLoading(true);

    const payload = {
      userType: "admin",
      email: values.email,
    };
    await dispatch(forgetPassword(payload, history));
    setLoading(false);
  };
  return (
    <div className="login">
      <div className="login-container">
        <Title level={4} className="title">
          Forget Password
        </Title>
        <Form
          name="normal_login"
          className="login-form"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              type="email"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
            >
              Send Code
            </Button>
          </Form.Item>
          <Form.Item>
            <Link to="/login" className="back-to-login">
              Back to login
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgetPassword;
