import axios from "axios";
import swal from "sweetalert";
import { url, headers } from "../../BaseUrl";
import * as TableSettingsTypes from "../Types/TableSettingsTypes";

const adminId = JSON.parse(localStorage.getItem("userId"));

export const getTableTabs = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/admin/table-names/${adminId}`, {
      headers: headers,
    });
    if (res) {
      dispatch({
        type: TableSettingsTypes.GET_TABLE_NAMES,
        payload: res.data.tables,
      });
    }
  } catch (err) {}
};
export const setTableName = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/change-table-name`, payload, {
      headers: headers,
    });
    swal({
      icon: "success",
      title: "success",
      text: "Table Name Changed!",
    });
    if (res) {
      dispatch(getTableTabs());
      //   dispatch({
      //     type: TableSettingsTypes.GET_TABLE_NAMES,
      //     payload: res.data.tables,
      //   });
    }
  } catch (err) {
    console.log(" table action", err);
  }
};
export const updateTableStatus = (tableId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/admin/update-table-status`,
      { tableId },
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getTableTabs());
      dispatch(getTableOrders(tableId));
      localStorage.setItem("tableId", tableId);

      swal({
        icon: "success",
        title: "success",
        text: "Status Updated",
      });
      //   dispatch({
      //     type: TableSettingsTypes.GET_TABLE_NAMES,
      //     payload: res.data.tables,
      //   });
    }
  } catch (err) {
    swal({
      icon: "error",
      text: "please try again later",
    });
    console.log(" table action", err);
  }
};

export const getTableOrders = (tableId) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/table-order/`, { tableId });
    if (res) {
      dispatch({
        type: TableSettingsTypes.GET_TABLE_ORDERS,
        payload: res.data.orders,
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};
export const getTableHistory = (payload) => async (dispatch) => {
  // console.log(payload);
  try {
    const res = await axios.post(`${url}/admin/get-table-history`, payload);
    if (res) {
      dispatch({
        type: TableSettingsTypes.GET_TABLE_HISTORY,
        payload: res.data.orders,
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};
export const addTable = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/admin/create-table`,
      {
        name: `Table ${payload}`,
        active: true,
        adminId,
      },
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getTableTabs());
      swal({
        icon: "success",
        title: "Table Created",
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};

export const payOrder = (payload, tableId) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/pay-order`, payload);
    if (res) {
      dispatch(getTableTabs());
      dispatch(getTableOrders(tableId));
      swal({
        icon: "success",
        title: "success",
        text: "Order Payed",
      });
    }
  } catch (err) {
    console.log(" table action", err);
  }
};
export const searchTableCustomer = (payload, tabId) => async (dispatch) => {
  console.log(payload);
  if (payload === "") {
    dispatch(getTableOrders(tabId));
  } else {
    dispatch({
      type: TableSettingsTypes.SEARCH_TABLE_CUSTOMER,
      payload: payload,
    });
  }
};

export const deleteTable = (tableId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/admin/delete-table`,
      { tableId },
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getTableTabs());
      swal({
        icon: "success",
        title: "success",
        text: "Table Deleted",
      });
      //   dispatch({
      //     type: TableSettingsTypes.GET_TABLE_NAMES,
      //     payload: res.data.tables,
      //   });
    }
  } catch (err) {
    swal({
      icon: "error",
      text: "please try again later",
    });
    console.log(" table action", err);
  }
};
export const setTableOrder = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/set-table-order`, payload, {
      headers: headers,
    });
    if (res) {
      console.log(res.data);
      dispatch(getTableTabs());
      swal({
        icon: "success",
        title: "success",
        text: "Status Updated",
      });
      //   dispatch({
      //     type: TableSettingsTypes.GET_TABLE_NAMES,
      //     payload: res.data.tables,
      //   });
    }
  } catch (err) {
    swal({
      icon: "error",
      text: "please try again later",
    });
    console.log(" table action", err);
  }
};

export const shiftTabletOrder = (payload, tabId) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/admin/shift-order`, payload);
    if (res) {
      dispatch(getTableOrders(tabId));

      swal(res.data.message, "", "success");
    }
  } catch (err) {
    console.log(err?.response?.data?.message, "", "error");
  }
};

export const searchAllCustomersInTables = (payload) => async (dispatch) => {
  console.log(payload);

  console.log(payload);
  try {
    const res = await axios.post(
      `${url}/admin/search-customer-from-all-tables`,
      {
        value: payload,
        adminId,
      }
    );
    if (res) {
      // dispatch(getTableOrders(tabId));
      console.log(res.data);
      // swal(res.data.message, "", "success");
      return res.data.order;
    }
  } catch (err) {
    console.log(err?.response?.data?.message, "", "error");
  }
};

export const printTableOrders = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/admin/print-table-orders`, {
      orderArr: payload,
    });
    if (res) {
      swal("", res.data.message, "success");

      // return res.data.order;
    }
  } catch (err) {
    swal("", err?.response?.data?.message || "Server Error", "error");
  }
};
