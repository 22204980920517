import * as AccountTypes from "../Types/AccountSettingsTypes";

const initialState = {
  countries: [],
  cities: [],
  userInfo: null,
  verified: false,
  // adminOrderStatus: null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AccountTypes.GET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };
    case AccountTypes.GET_CITIES:
      return {
        ...state,
        cities: payload,
      };

    case AccountTypes.GET_ADMIN:
      return {
        ...state,
        userInfo: payload,
        // adminOrderStatus: payload.orderStatus,
      };
    case AccountTypes.VERIFY:
      return {
        ...state,
        verified: payload,
      };

    default:
      return state;
  }
};

export default authReducer;
