import { Input, Table, Typography, Button, Checkbox } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutDesktop from "../layout/DashboardLayout";
import {
  getCustomers,
  searchCustomer,
  filterCustomer,
  defaultCustomer,
} from "../Redux/Actions/CustomerActions";
import { useHistory } from "react-router-dom";

const Customers = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const history = useHistory();

  const customerColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Spending",
      dataIndex: "spending",
      sorter: {
        compare: (a, b) => a.spending - b.spending,
        multiple: 3,
      },
    },
    {
      title: "User Registration",
      dataIndex: "userRegistration",
      sorter: {
        compare: (a, b) => a.userRegistration - b.userRegistration,
        multiple: 2,
      },
    },
    {
      title: "View Details",
      dataIndex: "details",
    },
  ];

  const customers = useSelector((state) => state.CustomerReducer.customers);

  const onCustomerSearch = (e) => {
    dispatch(searchCustomer(e.target.value));
  };
  function onCustomerTableChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  console.log(customers);

  const registeredCustomers = (value) => {
    if (value) {
      dispatch(filterCustomer());
    } else {
      dispatch(defaultCustomer());
    }
  };

  return (
    <LayoutDesktop active="customer">
      <Title level={2}>Customers</Title>
      <div className="customers">
        <div className="top">
          <Checkbox
            onChange={(e) => registeredCustomers(e.target.checked)}
            style={{ fontWeight: "bold" }}
          >
            Show Registered Customers
          </Checkbox>
          <Input
            placeholder="search by name"
            className="search-bar"
            allowClear
            onChange={(e) => onCustomerSearch(e)}
          />
        </div>
        <Table
          columns={customerColumns}
          loading={customers === null ? true : false}
          dataSource={
            customers &&
            customers.length > 0 &&
            customers.map((customer) => {
              let date = new Date(customer.firstOrder);
              date = date.toString();
              date = date.split("GMT");
              date = date[0];

              return {
                key: customer._id,
                name: customer.name,
                spending: customer.spending,
                userRegistration: date,
                details: (
                  <Button
                    onClick={() =>
                      history.push(`/customer-details/${customer._id}`)
                    }
                    disabled={customer._id ? false : true}
                    type="primary"
                  >
                    View Details
                  </Button>
                ),
              };
            })
          }
          onChange={onCustomerTableChange}
          scroll={{ x: 600 }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
        />
      </div>
    </LayoutDesktop>
  );
};

export default Customers;
