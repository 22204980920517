import { useEffect, useState } from "react";
import { Button, Input, Typography } from "antd";
import { useDispatch } from "react-redux";
import {
  setTableName,
  //deactivateTable,
  updateTableStatus,
  deleteTable,
  setTableOrder,
} from "../../Redux/Actions/TableSettingsActions";
import swal from "sweetalert";

const SetTableName = ({ tableId, tableName, isActive, tabNames }) => {
  // console.log(tabNames);
  console.log(tableName, isActive, tableName);
  //const onSearch = (value) => console.log(value);
  //const adminId = localStorage.getItem("userId");
  const [tabOrder, setTabOrder] = useState();

  const [name, setname] = useState("");
  const dispatch = useDispatch();

  const setTabName = () => {
    let alreadyExists = tabNames.filter((table) => {
      return table.name.toLowerCase().trim() === name.toLowerCase().trim();
    });
    console.log(alreadyExists);
    if (alreadyExists[0]) {
      swal("Error", "table with same name already exists", "error");
    } else {
      const payload = {
        tableId: tableId,
        tableName: name,
      };
      dispatch(setTableName(payload));
      setname(name);
    }
  };

  // const inActiveTable = () => {
  //   dispatch(updateTableStatus(tableId));
  // };
  const updateTabOrder = () => {
    if (tabOrder > 0 && tabOrder <= tabNames?.length) {
      const payload = {
        tableId,
        order: tabOrder,
      };
      dispatch(setTableOrder(payload));
    } else {
      swal(
        "",
        `product order must be equal to 1 Or ${tabNames?.length} `,
        "error"
      );
    }
  };

  useEffect(() => {
    setname(tableName && tableName);
  }, [tableName, tableId]);
  // console.log(tabOrder);
  return (
    <div className="table-name">
      <div style={{ margin: "40px 0" }}>
        <Typography.Title level={4}>Set Table Order</Typography.Title>
        <Input
          onChange={(e) => setTabOrder(Number(e.target.value))}
          style={{ width: 50 }}
          type="number"
          style={{ width: "80px" }}
          min={1}
          max={tabNames?.length}
        />
        <Button onClick={updateTabOrder} type="primary">
          Set
        </Button>
      </div>

      <Typography.Title level={4}>Update Table</Typography.Title>
      <div className="set-table-container">
        <div>
          <Input
            // placeholder={tableName}

            className="set-table-name-inp"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
          <Button className="set-btn" onClick={setTabName}>
            SET TABLE NAME
          </Button>
        </div>
        {/* <div className="red"> */}
        <Button
          className="inactive-btn"
          onClick={() => dispatch(updateTableStatus(tableId))}
        >
          INACTIVE TABLE
        </Button>
        <Button
          className="inactive-btn"
          onClick={() => dispatch(deleteTable(tableId))}
        >
          DELETE TABLE
        </Button>
        {/* </div> */}
        {/* {isActive ? (
        ) : (
          <Button className="inactive-btn">ACTIVE TABLE</Button>
        )} */}
      </div>
    </div>
  );
};

export default SetTableName;
