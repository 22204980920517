import { Menu } from "antd";
import {
  TableOutlined,
  SettingOutlined,
  DropboxOutlined,
} from "@ant-design/icons";
import { FaOpencart } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleVerify } from "../Redux";

const LayoutMenu = ({ active }) => {
  const history = useHistory();

  const adminId = JSON.parse(localStorage.getItem("userId"));

  const verified = useSelector(
    (state) => state.AccountSettingsReducer.verified
  );
  const dispatch = useDispatch();

  const menuClick = (e) => {
    if (e.key === "tables" || e.key === "liveOrders") {
      dispatch(toggleVerify());
    }
  };

  const { SubMenu } = Menu;
  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={active}
      defaultOpenKeys={verified ? ["settings"] : []}
      mode="inline"
      onClick={menuClick}
      className="layout-menu"
    >
      {verified ? (
        <SubMenu
          key="settings"
          icon={<SettingOutlined />}
          title="Settings"
          // onTitleClick={(key) => {
          //   history.push("/verify-login");
          //   setIsAdmin(true);
          // }}
        >
          <Menu.Item key="account settings">
            <Link to="/account-setting">Account Settings</Link>
          </Menu.Item>
          <Menu.Item key="table settings">
            <Link to="/table-setting">Table Settings</Link>
          </Menu.Item>
          <Menu.Item key="menu">
            <Link to="/menu">Menu</Link>
          </Menu.Item>
          <Menu.Item key="accounting">
            <Link to="/accounting">Accounting</Link>
          </Menu.Item>
          <Menu.Item key="statistics">
            <Link to="/statistics">Statistics</Link>
          </Menu.Item>
          <Menu.Item key="customer">
            <Link to="/customers">Customers</Link>
          </Menu.Item>
          <Menu.Item key="workers">
            <Link to="/workers">Workers</Link>
          </Menu.Item>
          <Menu.Item key="promotions">
            <Link to="/promotions">Promotions</Link>
          </Menu.Item>
        </SubMenu>
      ) : (
        <Menu.Item
          key="settings"
          icon={<SettingOutlined />}
          onClick={() => history.replace("/verify-login")}
          title="Settings"
        >
          Settings
        </Menu.Item>
      )}

      <Menu.Item key="tables" icon={<TableOutlined />}>
        <Link to="/tables" style={{ color: "white" }}>
          Tables
        </Link>
      </Menu.Item>

      <Menu.Item key="liveOrders" icon={<DropboxOutlined />}>
        <Link to="/liveorders" style={{ color: "white" }}>
          Live Orders
        </Link>
      </Menu.Item>
      <Menu.Item icon={<FaOpencart />} style={{ background: "transparent" }}>
        <a
          href={`https://qrtreat.com/admin/${adminId}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: "white" }}
        >
          Place Order
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default LayoutMenu;
