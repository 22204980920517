import { combineReducers } from "redux";

import AccountSettingsReducer from "./Reducers/AccountSettingsReducer";
import TableSettingsReducer from "./Reducers/TableSettingsReducer";
import AccountingReducer from "./Reducers/AccountingReducer";
import StatisticsReducer from "./Reducers/StatisticsReducer";
import WorkersReducer from "./Reducers/WorkersReducer";
import CustomerReducer from "./Reducers/CustomersReducer";
import MenuReducer from "./Reducers/MenuReducer";
import PromotionReducer from "./Reducers/PromotionReducer";
import LiverOrderReducer from "./Reducers/LiveOrderReducer";

const rootReducer = combineReducers({
  AccountSettingsReducer,
  TableSettingsReducer,
  AccountingReducer,
  StatisticsReducer,
  WorkersReducer,
  CustomerReducer,
  MenuReducer,
  PromotionReducer,
  LiverOrderReducer,
});

export default rootReducer;
