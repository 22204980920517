import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
//import { url } from "../BaseUrl";
import Layout from "../layout/DashboardLayout";
import { verifyVendorLogin } from "../Redux";
import { Form, Button, Typography, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const VerifiyLogin = () => {
  //const [password, setPassword] = useState();
  const { Title } = Typography;
  const email = JSON.parse(localStorage.getItem("vendorEmail"));
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(verifyVendorLogin(email, values.password, history));
    setLoading(false);
  };

  if (!localStorage.hasOwnProperty("userId")) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Layout active="verify login">
        <div className="login verify-login">
          <div className="login-container">
            <Title level={4} className="title">
              Admin Login
            </Title>
            <Form
              name="normal_login"
              className="login-form"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item name="email" label="Email">
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={email}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  autoFocus
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    );
  }
};

export default VerifiyLogin;
