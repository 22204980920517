import * as AccountingTypes from "../Types/AccountingTypes";

const initialState = {
  expenses: null,
};

var ExpenseCopy;

const expenseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AccountingTypes.GET_EXPENSES:
      ExpenseCopy = payload;
      return {
        ...state,
        expenses: payload,
      };
    case AccountingTypes.SEARCH_EXPENSES:
      const searchName = payload.toLowerCase();

      const newTable = ExpenseCopy.filter((obj) => {
        return obj.name.toLowerCase().includes(searchName);
      });

      return {
        ...state,
        expenses: newTable,
      };

    default:
      return state;
  }
};

export default expenseReducer;
