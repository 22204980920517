import { Tabs, Row, Col, Button, Tag, Divider } from "antd";
import { Typography } from "antd";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomersList from "../components/TableSettings/CustomersList";
import Orders from "../components/TableSettings/Orders";
import SetTableName from "../components/TableSettings/SetTableName";
import TableHistory from "../components/TableSettings/TableHistory";
import LayoutDesktop from "../layout/DashboardLayout";
import {
  addTable,
  getTableTabs,
  getTableOrders,
  updateTableStatus,
} from "../Redux/Actions/TableSettingsActions";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import React from "react";
import CustomerSearch from "../components/Modals/TableSettings/CustomerSearch";

const TableSettings = () => {
  const dispatch = useDispatch();
  const link = window.location.pathname;
  // console.log(link.includes("liveorder-table"));
  const {
    //  TabPane
  } = Tabs;
  const { Title } = Typography;
  const { CheckableTag } = Tag;

  //const [mode, setMode] = useState("left");
  const [qrCode, setQrCode] = useState("");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [activeTable, setActiveTable] = useState(null);
  const [selectedTags, setSelectedTags] = useState("");

  console.log(activeTable, selectedTags);

  const tabNames = useSelector(
    (state) => state.TableSettingsReducer.tableTabNames
  );

  useEffect(() => {
    dispatch(getTableTabs());
    // if (id) {
    //   tagChange(id);
    //   filterId(id);
    // } else {
    //   setSelectedTags(tabNames && tabNames.length > 0 && tabNames[0]._id);
    //   setActiveTable(tabNames && tabNames.length > 0 && tabNames[0]);
    //   setQrCode(tabNames && tabNames[0] && tabNames[0].qrcode);
    // }
  }, []);

  useEffect(() => {
    if (id) {
      tagChange(id);
      filterId(id);
    } else {
      const table = localStorage.getItem("tableId");
      if (table) {
        setSelectedTags(table);
        let filteredTable = tabNames?.filter((tab) => {
          return tab._id === table;
        });
        setActiveTable(filteredTable && filteredTable[0]);
        setQrCode(filteredTable && filteredTable[0].qrcode);
      } else {
        setSelectedTags(tabNames && tabNames.length > 0 && tabNames[0]._id);
        setActiveTable(tabNames && tabNames.length > 0 && tabNames[0]);
        setQrCode(tabNames && tabNames[0] && tabNames[0].qrcode);
      }
    }
  }, [tabNames]);

  useEffect(() => {
    return () => {
      console.log("unmounted");
      localStorage.removeItem("tableId");
    };
  }, []);

  // console.log(customerId);

  const tagChange = (tag, checked) => {
    console.log(tag);
    filterId(tag);
    // const nextSelectedTags = checked
    //   ? // ? [...selectedTags, tag]
    //     tag
    //   : selectedTags.filter((t) => t !== tag);
    // setSelectedTags(nextSelectedTags);
    setSelectedTags(tag);

    const selectedTab = tabNames?.filter((tabName) => {
      return tabName._id === tag;
    });
    // console.log(activeTable);

    setActiveTable(selectedTab[0]);
  };

  console.log(window.location.pathname);
  console.log(activeTable && activeTable[0] && activeTable[0].active);

  const filterId = (id) => {
    dispatch(getTableOrders(id));
    const filterQr = tabNames.filter((obj) => {
      return obj._id === id;
    });
    setQrCode(filterQr && filterQr[0] && filterQr[0].qrcode);
  };
  return (
    <LayoutDesktop
      active={
        window.location.pathname === "/tables" ? "tables" : "table settings"
      }
    >
      <div className="table-settings">
        <CustomerSearch
          setCustomerId={(setCustomerId) => {
            console.log(setCustomerId);
            setSelectedTags(setCustomerId);
            const selectedTab = tabNames?.filter((tabName) => {
              return tabName._id === setCustomerId;
            });
            setActiveTable(selectedTab[0]);
          }}
        />
        <hr />
        <Row>
          <Col xs={24} lg={link === "/tables" || id ? 24 : 18}>
            <div className="main-table">
              {link === "/tables" || link.includes("liveorder-table") ? (
                <Title level={2} className="heading-only">
                  Tables
                </Title>
              ) : (
                <div className="table-header">
                  <Title level={2}>Table Settings</Title>

                  <Button
                    type="primary"
                    loading={loading}
                    onClick={async () => {
                      setLoading(true);
                      await dispatch(addTable(tabNames && tabNames.length + 1));
                      setLoading(false);
                    }}
                  >
                    Add new Table
                  </Button>
                </div>
              )}
              {/* {tabNames && tabNames.length > 0 ? (
                <Tabs
                  // activeKey={id && id}
                  defaultActiveKey={id && id}
                  // tabPosition={mode}
                  // activeKey

                  onTabClick={(e) => filterId(e)}
                >
                  {tabNames &&
                    tabNames.length > 0 &&
                    tabNames.map((tabName, i) => (
                      <TabPane
                        tab={tabName.name}
                        key={tabName._id}
                        id={tabName._id}
                      >
                        {tabName.active ? (
                          <div>
                            {link === "/tables" || id ? null : (
                              <SetTableName
                                tableId={tabName._id}
                                tableName={tabName.name}
                                isActive={tabName.active}
                                tabNames={tabNames}
                              />
                            )}
                            <CustomersList tableId={tabName._id} />
                            <Orders tableId={tabName._id} />
                            <TableHistory tableId={tabName._id} />
                          </div>
                        ) : (
                          <div className="table-name inactive-cont">
                            <Title level={1}>Table Deactivated</Title>
                            <Button
                              type="primary"
                              onClick={() =>
                                dispatch(updateTableStatus(tabName._id))
                              }
                            >
                              Activate Table
                            </Button>
                          </div>
                        )}
                      </TabPane>
                    ))}
                </Tabs>
               
              ) : (
                <Empty description="No Tables Found." />
              )} */}
              <Row gutter={[10, 10]}>
                {tabNames &&
                  tabNames.length > 0 &&
                  tabNames.map((tabName, i) => {
                    return (
                      <Col xs={3}>
                        <CheckableTag
                          className="tabs-checked"
                          key={tabName._id}
                          checked={
                            selectedTags &&
                            selectedTags.length > 0 &&
                            selectedTags.indexOf(tabName._id) > -1
                          }
                          // checked={selectedTags === tabName._id}
                          // checked={selectedTags && selectedTags}
                          onChange={(checked) =>
                            tagChange(tabName._id, checked)
                          }
                        >
                          {tabName.name}
                        </CheckableTag>
                      </Col>
                    );
                  })}
              </Row>
              <Divider />
              {/* <Title level={3}>Table Details</Title> */}
              {activeTable && activeTable.active ? (
                <div>
                  {link === "/tables" || id ? null : (
                    <SetTableName
                      tableId={selectedTags && selectedTags}
                      tableName={activeTable && activeTable.name}
                      isActive={activeTable && activeTable.active}
                      tabNames={tabNames && tabNames}
                    />
                  )}
                  <br />

                  <CustomersList tableId={selectedTags && selectedTags} />
                  <Orders tableId={selectedTags && selectedTags} />
                  <TableHistory tableId={selectedTags && selectedTags} />
                </div>
              ) : (
                <div className="table-name inactive-cont">
                  <Title level={1}>Table Deactivated</Title>
                  <Button
                    type="primary"
                    onClick={() =>
                      dispatch(updateTableStatus(selectedTags && selectedTags))
                    }
                  >
                    Activate Table
                  </Button>
                </div>
              )}
            </div>
          </Col>
          {tabNames && tabNames.length > 0 ? (
            link === "/tables" || id ? null : (
              <Col xs={24} lg={6}>
                <div className="qrContainer">
                  <div id={"qr-code"}>
                    <QRCode
                      value={`https://qrtreat.com/${qrCode}`}
                      size={200}
                      className="qrC"
                    />
                    {/* {QrCodeComponent()} */}
                  </div>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      html2canvas(document.querySelector("#qr-code")).then(
                        (canvas) => {
                          const pdf = new jsPDF();
                          pdf.addImage(canvas, "PNG", 0, 0);
                          pdf.save("download.pdf");
                        }
                      );
                    }}
                  >
                    Download PDF
                  </Button>
                </div>
              </Col>
            )
          ) : (
            ""
          )}
        </Row>
      </div>
    </LayoutDesktop>
  );
};

export default TableSettings;
