import axios from "axios";
import { url, headers } from "../../BaseUrl";
import * as PromotionTypes from "../Types/PromotionTypes";
import swal from "sweetalert";
const adminId = JSON.parse(localStorage.getItem("userId"));

export const getAllProducts = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/user/get-all-products/${adminId}`, {
      headers: headers,
    });
    if (res) {
      dispatch({
        type: PromotionTypes.GET_PROMOTION_PRODUCTS,
        payload: res.data.products,
      });
    }
    // console.log(res);
  } catch (error) {
    console.log(error);
  }
};
export const getAllUsers = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/user/get-users/`, {
      headers: headers,
    });
    if (res) {
      dispatch({
        type: PromotionTypes.GET_USERS,
        payload: res.data.users,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getPromotions = (payload) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/promotions/get-promotions/${adminId}`, {
      headers: headers,
    });
    if (res) {
      console.log(res.data);
      dispatch({
        type: PromotionTypes.GET_PROMOTIONS,
        payload: res.data.promotions,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const deletePromotion = (promotionId, productId) => async (dispatch) => {
  let payload;
  if (productId) {
    payload = {
      promotionId,
      productId,
    };
  } else {
    payload = {
      promotionId,
    };
  }

  try {
    const res = await axios.post(
      `${url}/promotions/delete-promotion/`,
      payload,
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getPromotions());
      swal({
        icon: "success",
        title: "Promotion Deleted",
      });
    }
  } catch (error) {
    swal({
      icon: "error",
      title: "Error Deleting Promotion. Please try again later",
    });
  }
};
export const customerPromotion = (payload) => async (dispatch) => {
  console.log("hello");
  const payload2 = {
    adminId,
    ...payload,
  };
  try {
    console.log("try");
    const res = await axios.post(
      `${url}/promotions/post-customer-promotion/`,
      payload2,
      {
        headers: headers,
      }
    );
    console.log(res);
    if (res) {
      console.log(res);
      dispatch(getPromotions());
      swal({
        icon: "info",
        title: res.data.message,
      });
    }
  } catch (error) {
    console.log("error");

    swal({
      icon: "error",
      title: error?.response?.data?.message || "server error",
    });
  }
};

export const searchPromotion = (payload) => async (dispatch) => {
  if (payload === "") {
    dispatch(getPromotions());
  } else {
    dispatch({
      type: PromotionTypes.SEARCH_PROMOTION,
      payload: payload,
    });
  }
};

export const createAllProductPromotion = (payload) => async (dispatch) => {
  const payload2 = {
    adminId,
    ...payload,
  };
  try {
    const res = await axios.post(
      `${url}/promotions/post-promotion-all/`,
      payload2,
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getPromotions());
      swal({
        icon: "success",
        title: res.data.message,
      });
    }
  } catch (error) {
    swal({
      icon: "error",
      title: "Error Creating Promotion. Please try again later",
    });
  }
};
export const createSingleProductPromotion = (payload) => async (dispatch) => {
  const payload2 = {
    adminId,
    ...payload,
  };
  try {
    const res = await axios.post(
      `${url}/promotions/post-promotion-single`,
      payload2,
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getPromotions());
      swal({
        icon: "success",
        title: res.data.message,
      });
    }
  } catch (error) {
    console.log(error);
    swal({
      icon: "error",
      title: error?.response?.data?.message,
    });
  }
};
export const createFreeProductPromotion = (payload) => async (dispatch) => {
  const payload2 = {
    adminId,
    ...payload,
  };
  console.log(payload2);
  try {
    const res = await axios.post(
      `${url}/promotions/free-product-promotion`,
      payload2,
      {
        headers: headers,
      }
    );
    if (res) {
      dispatch(getPromotions());
      swal({
        icon: "success",
        title: res.data.message,
      });
    }
  } catch (error) {
    swal({
      icon: "error",
      title: error?.response?.data?.message,
    });
  }
};
