import { Typography, Table, Rate } from "antd";
import moment from "moment";

const ProfileRating = ({ ratings }) => {
  const commentsColumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "Ratings",
      dataIndex: "star",
      sorter: {
        compare: (a, b) => a.star - b.star,
        multiple: 2,
      },
    },
  ];

  const { Title } = Typography;
  return (
    <div>
      <Title level={2}>Profile Ratings</Title>
      <Table
        columns={commentsColumns}
        // loading={comments && comments.comments.length > 0 ? false : true}
        dataSource={
          ratings &&
          ratings.length > 0 &&
          ratings.map((rating) => ({
            key: rating._id,
            date: moment(rating.time).format("Do MMMM YYYY"),
            star: (
              <div>
                <Rate disabled defaultValue={rating.rate} /> ( {rating.rate} )
              </div>
            ),
          }))
        }
        scroll={{ x: 600 }}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
        }}
      />
    </div>
  );
};

export default ProfileRating;
