import { Typography, Table, Rate } from "antd";
import moment from "moment";

const ProductRatings = ({ productRatings }) => {
  const commentsColumns = [
    {
      title: "Product Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => a.date - b.date,
        multiple: 3,
      },
    },
    {
      title: "Stars",
      dataIndex: "star",
      sorter: {
        compare: (a, b) => a.star - b.star,
        multiple: 2,
      },
    },
  ];

  let Ratings = [];

  productRatings &&
    productRatings.map((product) => {
      product.rating &&
        product.rating.length > 0 &&
        product.rating.map((rating) => {
          Ratings.push({
            date: moment(rating.time).format("Do MMMM YYYY"),
            star: (
              <div>
                <Rate disabled defaultValue={rating.rate} /> ( {rating.rate} )
              </div>
            ),
            name: product.name,
          });
        });
    });

  console.log(productRatings);

  const { Title } = Typography;
  return (
    <div>
      <Title level={2}>Products Ratings</Title>
      <Table
        columns={commentsColumns}
        // loading={comments && comments.comments.length > 0 ? false : true}
        dataSource={Ratings}
        scroll={{ x: 600 }}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
        }}
      />
    </div>
  );
};

export default ProductRatings;
