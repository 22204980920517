// import logo from "./logo.svg";
// import Home from "./pages/Home";
import Routes from "./Routes";
import "./styles/style.css";

function App() {
  return <Routes />;
}

export default App;
