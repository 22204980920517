import axios from "axios";
import { url } from "../../BaseUrl";
import * as AccountingTypes from "../Types/AccountingTypes";
import swal from "sweetalert";
//const adminId = JSON.parse(localStorage.getItem("userId"));

export const createExpenseAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/create-expense`, payload);
    if (res) {
      // dispatch(getExpenses());
      dispatch({
        type: AccountingTypes.GET_EXPENSES,
        payload: res.data.expenses,
      });
      swal({
        icon: "success",
        title: "",
        text: "Expense Created!",
      });
    }
  } catch (error) {}
};
export const createProductExpenseAction = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/create-expense`, payload);
    if (res) {
      dispatch(getExpenses());
      dispatch({
        type: AccountingTypes.GET_EXPENSES,
        payload: res.data.expenses,
      });
      swal({
        icon: "success",
        title: "",
        text: "Expense Created!",
      });
    }
  } catch (error) {}
};

export const getExpenses = () => async (dispatch) => {
  const adminId = JSON.parse(localStorage.getItem("userId"));

  try {
    const res = await axios.post(`${url}/admin/get-expenses`, { adminId });
    if (res) {
      //   dispatch(getExpenses());
      dispatch({
        type: AccountingTypes.GET_EXPENSES,
        payload: res.data.expenses,
      });
    }
  } catch (err) {
    // swal({
    //   icon: "error",
    //   title: "Error",
    //   text: "Error updating details",
    // });
    console.log(err);
  }
};
export const getExpenseByDate = (payload) => async (dispatch) => {
  console.log(payload);

  try {
    const res = await axios.post(`${url}/admin/get-accounting`, payload);
    if (res) {
      console.log(res.data);
      return {
        expenses: res.data.expenses,
        orders: res.data.orders,
      };
    }
  } catch (err) {
    // swal({
    //   icon: "error",
    //   title: "Error",
    //   text: "Error updating details",
    // });
    console.log(err);
  }
};

export const getDailyOrders = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/get-daily-accounting`, payload);
    if (res) {
      return res.data.orders;
    }
  } catch (err) {
    console.log(err);
  }
};

export const searchExpenses = (payload) => async (dispatch) => {
  if (payload === "") {
    dispatch(getExpenses());
  } else {
    dispatch({
      type: AccountingTypes.SEARCH_EXPENSES,
      payload: payload,
    });
  }
};
