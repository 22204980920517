//import { configConsumerProps } from "antd/lib/config-provider";
import axios from "axios";
import swal from "sweetalert";
import { url } from "../../BaseUrl";
import * as WorkersTypes from "../Types/WorkersTypes";
import { getWorkers } from "./StatisticsActions";
const adminId = JSON.parse(localStorage.getItem("userId"));

export const getWorkersCategory = (payload) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/get-workers-categories`, {
      adminId,
    });
    if (res) {
      dispatch({
        type: WorkersTypes.GET_WORKERS_CATEGORIES,
        payload: res.data.workerscaterories,
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};

export const createCategory = (name) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/admin/post-workers-category`, {
      name,
      adminId,
    });
    if (res) {
      dispatch(getWorkersCategory());
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};
export const createWorker = (payload2) => async (dispatch) => {
  // console.log(payload2);
  let payload = {
    adminId,
    ...payload2,
  };

  try {
    const res = await axios.post(`${url}/admin/create-worker`, payload);
    if (res) {
      dispatch(getWorkers());
      console.log(res.data);
      swal({
        icon: "success",
        title: "worker added successfully",
      });
    }
  } catch (err) {
    console.log("table settings 19", err);

    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};
export const updateWorkerInfo = (payload2) => async (dispatch) => {
  let payload = {
    adminId,
    ...payload2,
  };

  try {
    const res = await axios.post(`${url}/admin/update-worker`, payload);
    if (res) {
      dispatch(getWorkers());
      console.log(res.data);
      swal({
        icon: "success",
        title: "Worker Updated",
      });
    }
  } catch (err) {
    console.log("table settings 19", err);
  }
};
