import React from "react";
// import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const SubMenuPrivateRoute = ({ component: Component, ...rest }) => {
  var userId = JSON.parse(localStorage.getItem("userId"));
  return (
    <Route
      {...rest}
      render={(props) =>
        userId && localStorage.hasOwnProperty("userId") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default SubMenuPrivateRoute;
