import {
  Row,
  Typography,
  Col,
  Input,
  Button,
  Radio,
  Select,
  Table,
} from "antd";
import LayoutDesktop from "../layout/DashboardLayout";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getWorkers,
  searchWorkers,
  updateWorkerStatus,
} from "../Redux/Actions/StatisticsActions";
import {
  createCategory,
  createWorker,
  getWorkersCategory,
  updateWorkerInfo,
} from "../Redux/Actions/WorkersActions";
import swal from "sweetalert";

const Workers = () => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "ID",
      dataIndex: "uniqueId",
    },
    {
      title: "Wage",
      dataIndex: "wage",
      sorter: {
        compare: (a, b) => a.wage - b.wage,
        multiple: 3,
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => a.type - b.type,
        multiple: 2,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 1,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 1,
      },
    },
    {
      title: "Password",
      dataIndex: "password",
      sorter: {
        compare: (a, b) => a.password - b.password,
        multiple: 1,
      },
    },
    {
      title: "Action",
      dataIndex: "action",
    },
    {
      title: "Update",
      dataIndex: "edit",
    },
  ];

  const { Title } = Typography;
  const { Option } = Select;
  const workers = useSelector((state) => state.StatisticsReducer.workers);
  console.log(workers);
  const workersCategories = useSelector(
    (state) => state.WorkersReducer.workersCategories
  );
  const dispatch = useDispatch();
  //const [isModalVisible, setModalVisible] = useState(false);
  const [name, setName] = useState("");
  const [wage, setWage] = useState("");
  const [status, setStatus] = useState(true);
  const [categoryInp, setCategoryInp] = useState("");
  const [workerCategory, setWorkerCategory] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [workerId, setWorkerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [uniqueId, setUniqueId] = useState("");

  const onStatusChange = (e) => {
    setStatus(e.target.value);
  };
  // const modalChange = (modalVisible) => {
  //   setModalVisible(modalVisible);
  // };
  const handleCategoryChange = (e) => {
    setWorkerCategory(e);
  };
  const searchTable = (e) => {
    dispatch(searchWorkers(e.target.value));
  };
  function onTableChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  const addCategory = () => {
    if (categoryInp !== "") {
      dispatch(createCategory(categoryInp));
      swal({
        icon: "success",
        title: "Category Added",
      });
      setCategoryInp("");
    } else {
      swal("Please Add category", "", "error");
    }
  };
  const addWorker = async () => {
    setLoading(true);
    if (
      name !== "" &&
      workerCategory !== "" &&
      wage !== "" &&
      uniqueId !== ""
    ) {
      if (uniqueId.includes("-")) {
        const payload = {
          type: workerCategory,
          name,
          wage,
          status: status,
          email,
          password,
          uniqueId,
        };
        await dispatch(createWorker(payload));
        setLoading(false);
      } else {
        swal('Unique id must include "-"', "", "error");
        setLoading(false);
      }
    } else {
      swal("Fields Validation Error", "", "error");
    }
    // setName("");
    // setWage("");
    // setWorkerCategory("");
    // setEmail("");
    // setPassword("");
    // setLoading(false);
    setLoading(false);
  };

  const editWorker = (worker) => {
    console.log(worker);
    setName(worker.name);
    setWage(worker.wage);
    setEmail(worker.email);
    setPassword(worker.password);
    setWorkerCategory(worker.type._id);
    setStatus(worker.status);
    setIsUpdating(true);
    setWorkerId(worker._id);
    setUniqueId(worker.uniqueId);
  };

  const updateWorker = () => {
    if (name !== "" && workerCategory !== "" && wage !== "") {
      if (
        (email === "" && password !== "") ||
        (email !== "" && password === "")
      ) {
        swal(
          "",
          "please put email or password or leave both fields empty",
          "error"
        );
      } else {
        const payload = {
          type: workerCategory,
          name,
          wage,
          status: status,
          email,
          password,
          workerId,
        };
        console.log(payload);
        dispatch(updateWorkerInfo(payload));
        cancel();
      }
    }
    // setName("");
    // setWage("");
    // setWorkerCategory("");
  };

  const cancel = () => {
    setName();
    setWage();
    setEmail();
    setPassword();
    setWorkerCategory();
    setStatus();
    setIsUpdating(false);
  };

  useEffect(() => {
    dispatch(getWorkers());
    dispatch(getWorkersCategory());
  }, []);

  return (
    <LayoutDesktop active="workers">
      <div className="workers">
        <Title level={2}>Workers</Title>
        <Row>
          <Col xs={24} lg={8}>
            {/* create category */}
            <div className="create-category">
              <Title level={4}>Create Category</Title>
              <br />
              <Title level={5}>Category Name:</Title>
              <Input
                placeholder="Category Name"
                value={categoryInp}
                onChange={(e) => setCategoryInp(e.target.value)}
              />
              <Button
                type="primary"
                className="create-btn"
                icon={<PlusOutlined />}
                onClick={addCategory}
              >
                Create
              </Button>
            </div>
            {/* create worker */}
            <div className="create-worker">
              <Title level={4}>Create Worker</Title>
              <Title level={5}>Name:</Title>
              <Input
                placeholder="Worker Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />{" "}
              <br />
              <br />
              <Title level={5}>Unique ID:</Title>
              <Input
                placeholder="Unique Id for worker (name-123)"
                value={uniqueId}
                disabled={isUpdating}
                onChange={(e) => setUniqueId(e.target.value)}
              />{" "}
              <br />
              <br />
              <Title level={5}>Email:</Title>
              <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
              <br />
              <br />
              <Title level={5}>Password:</Title>
              <Input
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <br />
              <br />
              <Title level={5}>Wage:</Title>
              <Input
                placeholder="Wage"
                value={wage}
                onChange={(e) => setWage(e.target.value)}
                type="number"
              />
              <br />
              <br />
              <Title level={5}>Status</Title>
              <Radio.Group
                name="radiogroup"
                value={status}
                size="middle"
                onChange={onStatusChange}
              >
                <Radio value={true}>Active</Radio>
                <Radio value={false}>Inactive</Radio>
              </Radio.Group>
              <br />
              <br />
              <Title level={5}>Category:</Title>
              <Select
                style={{ width: "100%" }}
                onChange={handleCategoryChange}
                value={workerCategory}
              >
                {workersCategories &&
                  workersCategories.length > 0 &&
                  workersCategories.map((category, i) => {
                    return (
                      <Option value={category._id} key={category._id}>
                        {category.name}
                      </Option>
                    );
                  })}
              </Select>
              <br />
              <br />
              {isUpdating ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={updateWorker}
                    className="create-btn"
                  >
                    Update
                  </Button>
                  <br />
                  <Button type="primary" onClick={cancel}>
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="create-btn"
                  onClick={addWorker}
                  loading={loading}
                >
                  Create
                </Button>
              )}
            </div>
          </Col>
          <Col xs={24} lg={16}>
            <div className="workers-table">
              <Title level={4}>Workers</Title>
              <Input
                placeholder="search by name"
                className="search-bar"
                allowClear
                onChange={(e) => searchTable(e)}
              />
              <Table
                columns={columns}
                dataSource={
                  workers &&
                  workers.length > 0 &&
                  workers.map((worker) => {
                    return {
                      key: worker._id,
                      name: worker.name,
                      uniqueId: worker.uniqueId ? worker.uniqueId : "-",
                      wage: worker.wage,
                      type: worker.type.name,
                      status: worker.status ? "active" : "inactive",
                      email: worker.email ? worker.email : "-",
                      password: worker.password ? worker.password : "-",
                      action: (
                        <div>
                          <Button
                            type="primary"
                            onClick={() =>
                              dispatch(updateWorkerStatus(worker._id))
                            }
                          >
                            {worker.status ? "Set Inactive" : "Set Active"}
                          </Button>{" "}
                        </div>
                      ),
                      edit: (
                        <Button
                          type="primary"
                          onClick={() => editWorker(worker)}
                        >
                          Edit
                        </Button>
                      ),
                    };
                  })
                }
                loading={workers === null ? true : false}
                onChange={onTableChange}
                scroll={{ x: 600 }}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </LayoutDesktop>
  );
};

export default Workers;
