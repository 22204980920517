import { Table, Button, Select, Typography, Modal, Input, Divider } from "antd";
//import Checkbox from "antd/lib/checkbox/Checkbox";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { searchCustomer } from "../../Redux/Actions/CustomerActions";
import {
  getTableOrders,
  searchTableCustomer,
  shiftTabletOrder,
  printTableOrders,
} from "../../Redux/Actions/TableSettingsActions";
import swal from "sweetalert";
import CustomerPayModal from "../Modals/TableSettings/CustomerPayModal";

const CustomersList = ({ tableId }) => {
  const { Option } = Select;
  const { Title } = Typography;
  console.log(tableId);
  const [modalVisible, setModalVisible] = useState(false);
  const [load, setLoad] = useState(false);
  const [printLoad, setPrintLoad] = useState(false);

  const [customers, setCustomers] = useState([]);

  const [shift, setShift] = useState();
  const dispatch = useDispatch();

  const tabNames = useSelector(
    (state) => state.TableSettingsReducer.tableTabNames
  );

  // Show Entries
  function handleChange(value) {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  }
  function handlePrintChange(value) {
    setCustomers(value);
  }

  console.log(customers);
  // const modalChange = (modalVisible) => {
  //   setModalVisible(modalVisible);
  // };

  // Trigger Modal
  const modalChange = (modalVisible) => {
    setModalVisible(modalVisible);
  };

  const onSearchCustomer = (e) => {
    dispatch(searchTableCustomer(e.target.value, tableId));
  };
  // Table Data

  const columns = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",

      sorter: (a, b) => a.name.length - b.name.length,
      //   sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Total Bill",
      dataIndex: "totalBill",
      key: "totalBill",
      sorter: (a, b) => a.totalBill - b.totalBill,
      //   sortOrder: sortedInfo.columnKey === "age" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Pay",
      dataIndex: "pay",
      key: "pay",
    },
    {
      title: "Shift Order",
      dataIndex: "shift",
      key: "shift",
    },
  ];
  // console.log(tabNames);

  const customerList = useSelector(
    (state) => state.TableSettingsReducer.tableOrders
  );

  const showModal = (details) => {
    setShift(details);
    setModalVisible(true);
  };
  const shiftOrder = async (table) => {
    setModalVisible(true);
    // const { _id, table } = order;
    // console.log(_id, table);
    console.log(table);
    let tabId = shift.table;
    const payload = {
      orderId: shift._id,
      tableId: table._id,
    };
    await dispatch(shiftTabletOrder(payload, tabId));
    setModalVisible(false);
  };
  useEffect(() => {
    dispatch(getTableOrders(tableId));
  }, [tableId]);

  return (
    <div className="customers-list">
      <Title level={4}>Print Customer Order</Title>
      <Select
        mode="multiple"
        // size={"middle"}
        placeholder="Please select"
        maxTagCount={3}
        // defaultValue={["a10", "c12"]}
        onChange={handlePrintChange}
        style={{ width: "50%" }}
      >
        {customerList &&
          customerList.map((customer, i) => {
            return <Option key={customer._id}>{customer.user.name}</Option>;
          })}
      </Select>
      <Button
        type="primary"
        style={{ marginLeft: "5px" }}
        loading={printLoad}
        onClick={async () => {
          if (customers.length > 0) {
            setPrintLoad(true);
            await dispatch(printTableOrders(customers));
            setPrintLoad(false);
          } else {
            swal("", "Select At Least 1 Customer", "warning");
          }
        }}
      >
        Print Order
      </Button>
      <Divider />
      <div>
        <Button
          type="primary"
          loading={load}
          // style={{ marginTop: "20px" }}
          onClick={async () => {
            if (customerList?.length > 0) {
              setLoad(true);
              let customers = [];
              customerList?.map((customer) => {
                customers.push(customer._id);
              });
              await dispatch(printTableOrders(customers));
              setLoad(false);
            } else {
              swal("No Orders on Table", "", "warning");
            }
          }}
        >
          Print All Orders
        </Button>
      </div>
      <div className="customer-search">
        <Title level={2}>Customers List</Title>
        <Input
          onChange={(e) => onSearchCustomer(e)}
          placeholder="search by name"
          className="search-bar"
          allowClear={() => onSearchCustomer("")}
        />
      </div>
      <div className="table-container">
        <Table
          columns={columns}
          scroll={{ x: 600 }}
          // loading={customerList && customerList.length > 0 ? false : true}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
          dataSource={
            customerList && customerList.length > 0
              ? customerList.map((details) => {
                  return {
                    key: details._id,
                    name: details?.user?.name,
                    //   ? details.userName
                    //   : details.user.name,
                    totalBill: details.amount,
                    pay: (
                      <CustomerPayModal
                        details={details}
                        customerList={customerList}
                      />
                    ),
                    shift: (
                      <Button type="primary" onClick={() => showModal(details)}>
                        Shift Table
                      </Button>
                    ),
                  };
                })
              : null
          }
          onChange={handleChange}
        />
      </div>

      <Modal
        title="Shift Table"
        centered
        visible={modalVisible}
        onOk={() => modalChange(false)}
        onCancel={() => modalChange(false)}
        width={300}
        footer={false}
      >
        {tabNames.map((item) => {
          return (
            <Button
              type="primary"
              block
              style={{ margin: "5px 0" }}
              onClick={() => shiftOrder(item)}
              disabled={item._id === tableId || !item.active ? "true" : false}
            >
              {item.name}
            </Button>
          );
        })}
      </Modal>
    </div>
  );
};

export default CustomersList;
